import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';


import styles from './skeleton.module.scss';

const ENV = (import.meta as any).env;


export function CardSkeleton( props: any ) {

  const { id, icon } = props;
  const { t } = useTranslation() 


  return (
    <div className={ ENV?.VITE_APP_IS_WEB === 'true' ? styles.CardSkeleton : styles.CardSkeleton__Mobile }>
      <div className={styles.CardSkeleton__Title}>
        <IconWrapper iconName={icon} />
        <div className={styles.CardSkeleton__Title__Span}></div>
        <div className={styles.CardSkeleton__Title__Text}>
          { parse(t(`widgets.${id}.title`)) }
        </div>
      </div>
      <div className={styles.CardSkeleton__Content}>
        <div className={styles.CardSkeleton__Content__Bar}></div>
        <div className={styles.CardSkeleton__Content__Bar}></div>
      </div>
    </div>
  );
}