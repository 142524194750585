import styles from './skeleton.module.scss';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';

export function BatterySkeleton(props: any) {

  const { title, isNull = false } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.BatterySkeleton}>
      {
        isNull === false &&
        <>
          <div className={styles.BatterySkeleton__Title}>
            {t(title)}
          </div>
          <div className={styles.BatterySkeleton__Header}>
            <div className={styles.BatterySkeleton__Header__Label}></div>
            <div className={styles.BatterySkeleton__Header__Label}></div>
            <div className={styles.BatterySkeleton__Header__Label}></div>
          </div>
        </>
      }

      <div className={styles.BatterySkeleton__Content} style={isNull ? { marginTop: -76, paddingBottom: 16 } : {}}>
        <div className={styles.BatterySkeleton__Content__Unit}></div>
        <div className={styles.BatterySkeleton__Content__Axes}>
          <IconWrapper iconName={ isNull ? 'no_data_chart': "loading"} style={{ marginBottom: 8 }} />
          <div className={ styles.Skeletons__Label}>{ isNull ? 'Data unavailable, please check again later' : 'Loading...' }</div>
        </div>
        <div className={styles.BatterySkeleton__Content__TimeSets}>
          <div className={styles.BatterySkeleton__Content__TimeSets__TimeSet}></div>
          <div className={styles.BatterySkeleton__Content__TimeSets__TimeSet}></div>
          <div className={styles.BatterySkeleton__Content__TimeSets__TimeSet}></div>
          <div className={styles.BatterySkeleton__Content__TimeSets__TimeSet}></div>
          <div className={styles.BatterySkeleton__Content__TimeSets__TimeSet}></div>
        </div>
      </div>
    </div>
  );
}