import MultiProgress from 'react-multi-progress';
import styles from './stats-overview.module.scss';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  KpiApiContext,
  MetaDataApiContext,
  ThemeContext,
} from '@flexo/providers';
import { DonutGraph } from '@flexo/atoms';
import { capitalizeFirstLetter } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export function StatsOverview(props: any) {
  const {
    StatsOverview__PieContainer,
    StatsOverview__Stats,
    StatsOverview__Stats__Title,
    StatsOverview__Stats__Percentages,
    StatsOverview__Stats__Percentages__Labels,
    StatsOverview__Stats__Percentages__Labels__Label,
  } = styles;

  const { t } = useTranslation();
  const kpi = useSelector((state: any) => state.kpi);

  const { title, type, dataInTimeSpan = true } = props;
  const { ThemeStore } = useContext(ThemeContext);
  const holdTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [selected, setSelected] = useState<string | null>(null); // [1

  let overviewKpiApiData = kpi?.site?.['1day']?.[0]?.kpi as any;

  if (props?.view?.includes('community')) {
    overviewKpiApiData = kpi?.community?.['1day']?.[0]?.kpi as any;
  }

  if (props?.view?.includes('member')) {
    overviewKpiApiData = kpi?.member?.['1day']?.[0]?.kpi as any;
  }

  const findApiValue = (name) => {
    const item = overviewKpiApiData?.find((data) => data.name === name);
    return item ? item.value : 0;
  };
  const findApiUnit = (name) => {
    const item = overviewKpiApiData?.find((data) => data.name === name);
    return item ? item.unit : 0;
  };
  const values = props.values?.map((value) => ({
    label: value.label,
    totalPercentage: findApiValue(value.totalPercentage),
    totalEnergyValue: findApiValue(value.totalEnergyValue),
    id: value.id,
    progress: value?.progress?.map((progress) => ({
      value: findApiValue(progress.value),
      color: ThemeStore.colors[progress.color],
      className: styles.StatsOverview__MultiProgress__Element,
    })),
  }));

  const graphData = props.graphDataKeys.map((data, index) => ({
    id: data.key,
    label: data.label,
    value: findApiValue(data.key)
      ? findApiValue(data.key)
      : index === 0
      ? 100
      : 0,
    formattedValue: findApiValue(data.key),
  }));
  const startHold = (valueLabel) => {
    if (selected !== null) return;
    else {
      setSelected(valueLabel.id);
      // Set a timeout for the desired duration (e.g., 2 seconds)
      holdTimeout.current = setTimeout(() => {
        // Do nothing if held for the full duration (or perform another action if needed)
      }, 2000);
    }
  };

  const endHold = () => {
    clearTimeout(holdTimeout.current as any); // clear the timeout if user releases before the duration
    setSelected(null);
  };

  useEffect(() => {
    const handleContextMenu = (event) => event.preventDefault();
    const handleCopy = (event) => event.preventDefault();
    const handlePaste = (event) => event.preventDefault();

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleCopy);
    document.addEventListener('paste', handlePaste);

    // Cleanup listeners on unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('copy', handleCopy);
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  // if (!overviewKpiApiData) return <StatsOverviewSkeleton />;
  return (
    <div className={styles.StatsOverview}>
      <div className={StatsOverview__PieContainer}>
        <DonutGraph
          dataInTimeSpan={dataInTimeSpan}
          width={105}
          height={105}
          graphData={graphData}
          colors={
            kpi.isLoading || graphData[0].value === 100
              ? props?.graphColors?.map((color, index) =>
                  index === 0 ? '#F0F0F0' : '#FFFFFF'
                )
              : props?.graphColors?.map((color) => ThemeStore.colors[color])
          }
          totalAmount={findApiValue(props.totalValue)}
          keys={props.graphDataKeys}
          totalAmounttype={
            findApiUnit(props.totalValue) === 0
              ? 'kWh'
              : findApiUnit(props.totalValue)
          }
          selectedId={selected}
          setSelectedId={setSelected}
        />
      </div>{' '}
      <div
        className={StatsOverview__Stats}
        style={{
          marginTop: '.75em',
        }}
      >
        <span className={`${StatsOverview__Stats__Title} heading4`}>
          {capitalizeFirstLetter(t(title))}
        </span>
        {values.map(
          (value: any, index) => (
            (
              <div
                className={StatsOverview__Stats__Percentages}
                key={value.label}
                // onTouchStart={() => startHold(value.label)}
                // onTouchEnd={() => endHold()}
                style={{
                  marginTop: '.75em',
                  cursor: 'pointer',
                }}
                onTouchStart={() => startHold(value)}
                onTouchEnd={endHold}
                onMouseEnter={() => startHold(value)}
                onMouseLeave={endHold}
              >
                <div
                  className={`${StatsOverview__Stats__Percentages__Labels} paragraph ${
                    styles.noSelect
                  } ${selected === value.label ? styles.selected : ''}`}
                >
                  <span
                    className={`${StatsOverview__Stats__Percentages__Labels__Label} ${styles.noSelect}`}
                  >
                    {t(value.label)}
                  </span>
                  <span
                    className={`${StatsOverview__Stats__Percentages__Labels__Label} heading5L ${
                      selected === value.label ? styles.selected : ''
                    }`}
                  >
                    {value.totalPercentage}%
                  </span>
                </div>

                <div>
                  {' '}
                  <MultiProgress
                    transitionTime={0.5}
                    elements={value.progress}
                    backgroundColor="#F0F0F0"
                    className={styles.StatsOverview__MultiProgress}
                  />{' '}
                </div>
              </div>
            )
          )
        )}
      </div>
    </div>
  );
}

export default StatsOverview;
