//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import { useContext, useEffect } from 'react';

import {
  AlertContext,
  AlertTypes,
} from '@flexo/providers';
import {
  FirebaseService,
} from '@flexo/general';

import { useDispatch, useSelector } from 'react-redux';
import { KPI, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const KpiMemberResolver = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams()

  const pathSegment = location.pathname.split('/')[1]; 
  const metadata = useSelector((state: any) => state.metadata);
  const calendar = useSelector((state: any) => state.calendar);

  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet } = hiveCalendar;

  async function init() {

    if ( !pathSegment?.includes( 'member' )) { return }

    const idToken = await firebaseService.getIdToken();
    const MEMBER_ID = params?.id

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    if (!metadata?.entities) { return }
    if ( !MEMBER_ID) { return }

    const sites = (metadata?.entities as any)?.site
      ?.filter((site: any) => site?.memberId === MEMBER_ID)
      ?.map((site) => site?.siteId) || [];
    
    if (sites?.length === 0) { return }
    
    
    
    dispatch(KPI.ResetNamedKpiFields([
      'community',
      'communityRanking',
      'site',
    ] as any) )


    dispatch(KPI.SetKpiDataMemberAsync({ sites, idToken, selectedDate, selectedTimeSet }) as any);
  }

  useEffect(() => {
      init();
  }, []);

  useEffect(() => {
      init();
  }, [selectedDate, metadata?.entities, params?.id, pathSegment]);

  return <></>;
};
