import { useContext, useEffect } from 'react';

import {
  AlertContext,
  AlertTypes,
} from '@flexo/providers';


import {
  FirebaseService,
} from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { KPI, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';

const firebaseService = new FirebaseService();

export const CommunityRankingResolver = () => {
  
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const calendar = useSelector((state: any) => state.calendar);
  const metadata = useSelector((state: any) => state.metadata);
  const pathSegment = location.pathname.split('/')[1]; 

  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet } = hiveCalendar

  async function init() {

    if (!location.pathname.includes('community')) { return; }

    const COMMUNITY_ID = params?.id || metadata?.selectedEntity?.communityId || null;

    if ( !COMMUNITY_ID ) { return; }


    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      dispatch( USER.SetLogout())

      return;
    }

    
    dispatch(KPI.SetKpiDataCommunityRankingAsync({COMMUNITY_ID, idToken, selectedDate, selectedTimeSet} as any) as any )


  }

  useEffect(() => {
      init();
  }, [selectedDate, pathSegment, params?.id]);

  return <></>;
};
