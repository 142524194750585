import { useContext, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { httpService, IconWrapper, REGEX } from '@flexo/general';
import { _navigate, Button, Textfield } from '@flexo/atoms';
import { Player } from '@lottiefiles/react-lottie-player';
import { v4 as uuidv4 } from 'uuid';

import styles from './password-reset.module.scss';
import {
  _getQueryParams,
  AlertContext,
  AlertTypes,
  ModulesContext,
  ModulesTypes,
} from '@flexo/providers';
import { MODULES } from '@flexo/reducers';
import { useDispatch } from 'react-redux';

const ENV = (import.meta as any).env;
const loader = '/loaderLottie.json';
let _askError = false;
let ranOnce = false;

let _formdata = {
  password: '',
  password_confirm: '',
};

// let _paramsData = {
//   email: '',
//   recovery_code: '',
// }

export function PasswordResetWeb() {
  const formRef = useRef<any>(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setAlertStore } = useContext(AlertContext);

  const [passwordresetAsked, setPasswordResetAsked] = useState(false);
  const [askError, setAskError] = useState(false);
  const [paramsData, setParamsData] = useState({
    email: '',
    recovery_code: '',
  });

  const { title, subtitle, inputs, buttonLabel } = t(
    'pages.password_reset_web',
    { returnObjects: true, email: paramsData.email }
  ) as any;
  const [samePassword, setSamePassword] = useState(true);
  const [formData, setFormData] = useState(_formdata);

  const [checkList, setChecklist] = useState<any>(
    Object.values(inputs.checkList).reduce(
      (prev: any, next: any) => ({ ...prev, [next.name]: false }),
      {}
    )
  );

  const formRules = Object.values(inputs.checkList).map((checkItem: any) => ({
    name: checkItem.name,
    rule: checkItem.rule,
  }));

  const [toggleIcons, setToggleIcons] = useState({
    password: 'hide',
    password_confirm: 'hide',
  });

  const [toggleTypes, setToggleTypes] = useState({
    password: 'password',
    password_confirm: 'password',
  });

  const debounce = (fn, delay = 100) => {
    const handler = setTimeout(() => {
      fn();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  };

  async function handleInputChange(event: any) {
    const { name, value } = event.target;

    if (name === 'password_confirm') {
      setSamePassword(true);
    }

    _askError = false;
    setAskError(_askError);

    _formdata = { ..._formdata, [name]: value };
    setFormData(_formdata);

    if (name === 'password') {
      checkValidation(value);
    }
  }

  function togglePasswordVisibility(inputName: string) {
    setToggleIcons({
      ...toggleIcons,
      [inputName]: toggleIcons[inputName] === 'hide' ? 'show' : 'hide',
    });

    setToggleTypes({
      ...toggleTypes,
      [inputName]: toggleTypes[inputName] === 'password' ? 'text' : 'password',
    });
  }

  function checkValidation(value: string) {
    const temp_checklist = {};
    formRules.forEach((rule: any) => {
      try {
        const regex = new RegExp(rule.rule);
        temp_checklist[rule.name] = regex.test(value);
      } catch (err: any) {
        // console.error(err);
        temp_checklist[rule.name] = false;
      }
    });
    setChecklist(temp_checklist);
  }

  async function askForPasswordreset() {
    setPasswordResetAsked(true);

    const response = await httpService
      .post({
        url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/password-change-confirm`,
        data: {
          email: paramsData.email,
          reset_code: paramsData.recovery_code,
          new_password: formData.password,
        },
      })
      .then((resp: any) => ({
        status: resp.status,
        statusText: resp?.statusText || 'Unknown status text',
        message: resp?.data?.message || 'Unknown message',
      }))
      .catch((error) => {
        // console.warn(error);

        return {
          status: error?.response?.status || 500,
          statusText: error?.response?.statusText || 'Unknown error',
        };
      });

    setPasswordResetAsked(false);

    if (response.status > 200) {
      if (response.status === 422) {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_WRONG_REQUEST_DATA',
            },
          },
        });
      } else if (response.status === 403) {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_NOT_ALLOWED',
            },
          },
        });
      } else if (response.status === 400) {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_INVALID_CREDENTIALS',
            },
          },
        });
      } else {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            },
          },
        });
      }

      _askError = true;
      setAskError(_askError);
    } else {
      _askError = false;
      setAskError(_askError);

      _navigate('/login');

      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', newUrl);

      setAlertStore({
        type: AlertTypes.ResetAlert,
      });

      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'BANNER_PASSWORD_RESET_SUCCESS',
          },
        },
      });
    }
  }

  function checkConfirmationPassword(e: any) {
    if (e.key === 'Backspace') {
      setSamePassword(true);
    } else {
      debounce(() => {
        if (formData.password !== formData.password_confirm) {
          setSamePassword(false);
        } else {
          setSamePassword(true);
        }
      }, 500);
    }
  }

  function focusChild(event: any) {
    try {
      const parent = event.target;
      const child = parent.querySelector('input');
      child.focus();
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    if (ranOnce) return;

    const params: any = _getQueryParams();

    const keywords = ['email', 'recovery_code'];

    // eslint-disable-next-line
    if (keywords.every((key: string) => params.hasOwnProperty(key))) {
      setParamsData({
        email: params.email,
        recovery_code: params.recovery_code,
      });
    } else {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_WRONG_PARAMS',
          },
        },
      });

      setTimeout(() => {
        setAlertStore({
          type: AlertTypes.ResetAlert,
        });

        window.location.href = '/login';
      }, 4000);
    }

    ranOnce = true;
  }, []);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
  
        // Controlla se i dati sono validi prima di inviare il form
        const isPasswordValid = REGEX.password.test(formData.password);
        const isConfirmationValid = formData.password === formData.password_confirm;
  
        if (!isPasswordValid || !isConfirmationValid || passwordresetAsked) return;
  
        askForPasswordreset();
      }
    }
  
    // Aggiungi il listener
    document.addEventListener('keydown', handleKeyDown);
  
    // Cleanup: rimuovi il listener quando il componente si smonta
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formData, passwordresetAsked]); // Dipendenze per mantenere aggiornati i valori

  return (
    <div className={styles.PasswordResetWeb}>
      <div className={styles.PasswordResetWeb__Header}>
        <IconWrapper
          iconName={'logo_wide'}
          className={styles.PasswordResetWeb__Header__Logo}
        />
        <div className={styles.PasswordResetWeb__Header__Title}>{title}</div>
        <div className={styles.PasswordResetWeb__Header__Email}>{subtitle}</div>
      </div>

      <form
        id="password_reset_web_form"
        className={styles.PasswordResetWeb__Form}
        ref={formRef}
      >
        {/* password */}

        <div className={styles.PasswordResetWeb__Form__Input} onClick={(e) => focusChild(e)}>
          <Textfield
            label={inputs.password.label}
            name={inputs.password.name}
            type={toggleTypes[inputs.password.name]}
            placeholder={inputs.password.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            autoComplete="off"
            defaultValue={formData[inputs.password.name]}
            onChange={(ev: any) => handleInputChange(ev)}
            icon={toggleIcons[inputs.password.name]}
            onIconClick={() => togglePasswordVisibility(inputs.password.name)}
            underline={askError === true ? 'visible' : 'hidden'}
          />
        </div>

        {/* generalities */}

        <div className={styles.PasswordResetWeb__Form__Input}>
          {inputs.checkList.map((checkItem, _index: number) => (
            <div
              key={`${checkItem.name}-${_index}`}
              className={styles.PasswordResetWeb__Form__Checklist__Item}
            >
              <span
                className={
                  styles.PasswordResetWeb__Form__Checklist__Item__Checkbox
                }
              >
                {checkList[checkItem.name] === true ? (
                  <IconWrapper iconName={'chevron-encircled_outline'} />
                ) : (
                  <IconWrapper iconName={'ellipse'} />
                )}
              </span>
              <p
                className={styles.PasswordResetWeb__Form__Checklist__Item__Text}
              >
                {checkItem.label}
              </p>
            </div>
          ))}
        </div>

        {/* password_confirm */}

        <div className={styles.PasswordResetWeb__Form__Input} onClick={(e) => focusChild(e)}>
          <Textfield
            
            label={inputs.password_confirm.label}
            name={inputs.password_confirm.name}
            type={toggleTypes[inputs.password_confirm.name]}
            placeholder={inputs.password_confirm.placeholder}
            onPaste={(e) => false}
            onCopy={(e) => false}
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            defaultValue={formData[inputs.password_confirm.name]}
            onChange={handleInputChange}
            autoComplete="off"
            icon={toggleIcons[inputs.password_confirm.name]}
            onIconClick={() =>
              togglePasswordVisibility(inputs.password_confirm.name)
            }
            underline={
              askError === true || samePassword === false ? 'visible' : 'hidden'
            }
            onBlur={checkConfirmationPassword}
            webError={samePassword === false}
            descriptionFault={
              !samePassword ? inputs.password_confirm.descriptionFault : ''
            }
          />
        </div>
      </form>

      <div className={styles.PasswordResetWeb__Footer}>
        <Button
          className={styles.PasswordResetWeb__Footer__Button}
          label={
            passwordresetAsked ? (
              <Player
                autoplay
                loop
                src={loader}
                style={{ height: 20, transform: 'scale(0.7)' }}
              />
            ) : (
              buttonLabel
            )
          }
          variant="primary"
          color="secondary"
          disabled={
            !REGEX.password.test(formData.password) ||
            formData.password !== formData.password_confirm
          }
          onClick={() => askForPasswordreset()}
        />
      </div>
    </div>
  );
}
