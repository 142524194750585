import { useContext, useEffect } from 'react';

import { AlertContext, AlertTypes } from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { CALENDAR, KPI, METADATA, store, USER } from '@flexo/reducers';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { _navigate } from './helpers';

import * as luxon from 'luxon';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const CommunityResolver = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];

  const { setAlertStore } = useContext(AlertContext);

  async function CommunityInit() {
    if (!pathSegment?.includes('community')) {
      return;
    }

    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    const COMMUNITY_ID = params.id;

    if (!COMMUNITY_ID) {
      return;
    }

    const [community] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/community/${COMMUNITY_ID}`,
          // /hive/api/catalog/v1_3/community/{communityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_UNEXPECTED_ERROR',
              },
            },
          });
          return null;
        }),
    ]);

    dispatch(
      METADATA.SetSelectedEntity({
        selectedEntity: community,
        selectedEntityId: community?.communityId,
        entityType: 'community',
      } as any)
    );
  }

  useEffect(() => {
    CommunityInit();
  }, [params.id, pathSegment]);

  return <></>;
};

export const navigateToCommunity = (context: any, communityId: string) => {
  const queryParams = window?.location?.search || '';
  const dispatch = store.dispatch;

  const location = window.location;
  const pathSegment = location.pathname.split('/')[2];

  dispatch(METADATA.SetPrevSelectedEntityId(pathSegment as any));

  dispatch(KPI.ResetKpiFields());

  if (ENV.VITE_APP_NAME === 'admin' && context === 'community') {

    dispatch( METADATA.ResetAllFilters() )

    // queryParams = '';

    // const D = luxon.DateTime.now().startOf('day').toJSDate();
    // const D2 = luxon.DateTime.now().plus({ days: 1 }).startOf('day').toJSDate();

    // dispatch(
    //   CALENDAR.setHiveDate({
    //     date: [D, D2],
    //     timeSet: 'day',
    //     setQueryParams: false,
    //   } as any)
    // );
  }

  _navigate(`/${context}/${communityId}${queryParams}`);

  // window.location.href = `/${context}/${communityId}`;
};
