export default {
  primary: '#FCA311',
  secondary: '#FCA311',
  tertiary: '#FCA311',
  greyscale: '#838b93',
  consumption: '#2E74AD',
  production: '#39A29D',
  economics: '#056B69',
  'self-consumption': '#4E67C1',
  negative: '#DB2F16',
  success: '#99CC00',
  grey: '#838B93',
  pureWhite: '#ffffff',
  battery: '#8D73CE',
  savings: '#056B6960',
  revenues: '#056B6960',
  consumptionShared: '#6AA6D7',
  productionShared: '#73CEC9',
  shared: '#2E74AD',
  consumptionGrid: '#2E74AD',
  productionGrid: '#73CEC9',
  selfConsumption_battery: '#A892BD',
  battery_production: '#D1C8DC',
  soc: '#6E4EC1',
  sharedCommunity: "#73CE7E",
  sharedGrid: "#73CE7E",
};
