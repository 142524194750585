import { createAsyncThunk } from '@reduxjs/toolkit';
import { initState } from './init.state';
import { formatDate, getTimeGranularity, getTimeGranularityOverview, httpService } from '@flexo/general';


const ENV = (import.meta as any).env;
const LOCALE = navigator.language || ENV?.VITE_APP_LOCALE || 'en-EN';






export const SetKpiDataCommunityAsync = createAsyncThunk(
  'kpi/SetKpiDataCommunityAsync',
  async function (data: any) {

    const { COMMUNITY_ID, idToken, selectedDate, selectedTimeSet, metadata } = data;

    console.log(metadata?.entities, 'metadata?.entities');



    const [kpiCommunityOverview, kpiComparisonResponse, kpiCommunityDetail] =
      await Promise.all([

        httpService
          .get({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/community/${COMMUNITY_ID}`,
            params: {
              dateFrom: formatDate(selectedDate[0]),
              dateTo: formatDate(selectedDate[1]),
              //add null: null to test the error
              timeGranularity: getTimeGranularityOverview(
                selectedTimeSet
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            },
            // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
            idToken,
          })
          .then((res: any) => {

            if (res.status === 200) {
              return res.data;
            } else {
              return null;
            }
          })
          .catch((err: any) => {
            // console.warn(err);

            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),


        httpService
          .post({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/comparison/${COMMUNITY_ID}`,
            data: {
              dateFrom: formatDate(selectedDate[0]),
              dateTo: formatDate(selectedDate[1]),
              timeGranularity: getTimeGranularityOverview(
                selectedTimeSet
              ),
              locale: ENV?.VITE_APP_LOCALE || 'it-CH',
              siteId:
                (((metadata?.entities as any)?.site || [])?.filter(
                  (site: any) => site.communityId === COMMUNITY_ID
                ) || []).map((site: any) => site.siteId) || [],
            },
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              // console.warn({
              //   status: res.status,
              //   message: res.statusText,
              // });
              return null;
            }
          })
          .catch((err: any) => {
            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),

        // New endpoint request
        httpService
          .get({
            url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/community/${COMMUNITY_ID}`,
            params: {
              dateFrom: formatDate(selectedDate[0]),
              dateTo: formatDate(selectedDate[1]),
              timeGranularity: getTimeGranularity(
                selectedTimeSet,
                null
              ),
              locale: LOCALE,
            },
            idToken,
          })
          .then((res: any) => {
            if (res.status === 200) {
              return res.data;
            } else {
              // console.warn({
              //   status: res.status,
              //   message: res.statusText,
              // });
              return null;
            }
          })
          .catch((err: any) => {
            // setAlertStore({
            //   type: AlertTypes.SetAlert,
            //   payload: {
            //     value: {
            //       type: 'ALERT_UNEXPECTED_ERROR',
            //     },
            //   },
            // });

            return null;
          }),
      ]);

    return {
      '1day': kpiCommunityOverview,
      '1hour': kpiCommunityDetail,
      comparison: kpiComparisonResponse,
      isLoading: false,
    }
  }
);

export const SetKpiDataSiteAsync = createAsyncThunk(
  'kpi/SetKpiDataSiteAsync',
  async function (data: any) {

    const { SITE_ID, idToken, selectedDate, selectedTimeSet } = data;

    const [kpiSiteDataOverview, kpiSiteDataDetail] = await Promise.all([
      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/${SITE_ID}`,
          params: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            //add null: null to test the error
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),

      httpService
        .get({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/${SITE_ID}`,
          params: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularity(
              selectedTimeSet,
              null
            ),
            locale: LOCALE,
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),
    ]);

    return {
      '1day': kpiSiteDataOverview,
      '1hour': kpiSiteDataDetail,
    }

  }
)

export const SetKpiDataMemberAsync = createAsyncThunk(
  'kpi/SetKpiDataMemberAsync',
  async function (data: any) {

    const { sites, idToken, selectedDate, selectedTimeSet } = data;
    const [kpiMemberDataOverview, kpiMemberDataDetail] = await Promise.all([
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/grouped`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            //add null: null to test the error
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            siteId: sites,
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),

      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/site/grouped`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularity(
              selectedTimeSet,
              null
            ),
            siteId: sites,
            locale: LOCALE,
          },
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR',
          //     },
          //   },
          // });

          return null;
        }),
    ]);

    return {
      '1day': kpiMemberDataOverview,
      '1hour': kpiMemberDataDetail,
    }

  }
)

export const SetKpiDataCommunityRankingAsync = createAsyncThunk(
  'kpi/SetKpiDataCommunityRankingAsync',
  async function (data: any) {

    const { COMMUNITY_ID, idToken, selectedDate, selectedTimeSet } = data;

    const [consumptionRanking, productionRanking, economicsRanking] = await Promise.all([
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${COMMUNITY_ID}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            kpiList: [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            rankingKpi: 'consumption',
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${COMMUNITY_ID}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            kpiList: [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid',
            ],
            rankingKpi: 'production',
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteCommunity/ranking/${COMMUNITY_ID}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            rankingKpi: 'revenues',
            kpiList: ['revenues', 'savings']
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),

    ]);

    return {
      consumptionRanking: consumptionRanking || [],
      productionRanking: productionRanking || [],
      economicsRanking: economicsRanking || [],
    }

  }
)

export const SetKpiDataMemberRankingAsync = createAsyncThunk(
  'kpi/SetKpiDataMemberRankingAsync',
  async function (data: any) {

    const { MEMBER_ID, idToken, selectedDate, selectedTimeSet } = data;



    const [consumptionRanking, productionRanking, economicsRanking] = await Promise.all([
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteMember/ranking/${MEMBER_ID}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            kpiList: [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            rankingKpi: 'consumption',
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteMember/ranking/${MEMBER_ID}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            kpiList: [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid',
            ],
            rankingKpi: 'production',
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),
      httpService
        .post({
          url: `${ENV.VITE_APP_BASE_URL}/hive/api/kpi/v1_2/siteMember/ranking/${MEMBER_ID}`,
          data: {
            dateFrom: formatDate(selectedDate[0]),
            dateTo: formatDate(selectedDate[1]),
            timeGranularity: getTimeGranularityOverview(
              selectedTimeSet
            ),
            locale: ENV?.VITE_APP_LOCALE || 'it-CH',
            rankingKpi: 'revenues',
            kpiList: ['revenues', 'savings']
          },
          // ${ENV.VITE_APP_BASE_URL}//hive/api/kpi/v1_2/community/${metaDataApiStore.selectedEntityId}
          idToken,
        })
        .then((res: any) => {
          if (res.status === 200) {
            return res.data;
          } else {
            // console.warn({
            //   status: res.status,
            //   message: res.statusText,
            // });
            return null;
          }
        })
        .catch((err: any) => {
          // console.warn(err);

          // setAlertStore({
          //   type: AlertTypes.SetAlert,
          //   payload: {
          //     value: {
          //       type: 'ALERT_UNEXPECTED_ERROR'
          //     },
          //   }
          // })

          return null;
        }),

    ]);

    return {
      consumptionRanking: consumptionRanking || [],
      productionRanking: productionRanking || [],
      economicsRanking: economicsRanking || [],
    }

  }
)
