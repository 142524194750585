
import React from 'react';

import styles from './header-web.module.scss';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { BreadCrumbsDropDown, navigateToCommunity, ProfileButton, Search } from '@flexo/atoms';

import './header-web.module.scss';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
} = styles;

function MemberNavigation(props: any) {
  
  const { t } = useTranslation()
  const params = useParams();
  const navigate = useNavigate();
  const metadata = useSelector((state: any) => state.metadata);
  const queryParams = window?.location?.search || '';
  
  
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;

  const communities = (metadata?.entities as any)?.community || [];

  function getMemberCommunity() {
    
    const memberCommunityId = (metadata?.entities as any)?.member?.find( (member: any) => member?.memberId === params.id )?.communityId || null
    const foundCommunity = communities.find((community: any) => (community?.communityId === memberCommunityId ));

    if ( !foundCommunity) {
      return communities?.[0]?.communityId;
    } else {
      return foundCommunity;
    }

  }

  function getMemberCommunityName() {
    const foundCommunity = getMemberCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>
        
        <div className={Navigation}>
          <label className={ `${styles.clickable}`} onClick={() => navigate(`/communities${queryParams}`)}>{t(`widgets.header.communities`)}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right'/></span>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity( 'community', getMemberCommunity()?.communityId  )} >{ getMemberCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right'/></span>

          
      {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          }
      
    </div>


      </div>
      <div className={Header__Web__Right}>
        {
          download && <>
            <IconWrapper iconName={'chevron-encircled'} />
          </>
        }
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(MemberNavigation);
