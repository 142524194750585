import { ThemeContext } from '@flexo/providers';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

export default function HorizontalChart(props: any) {
  const ref: any = useRef(null);
  const { t } = useTranslation();
  const { ThemeStore } = useContext(ThemeContext);
  const [data, setData] = useState<any>(null);
  const [hoveredBarKey, setHoveredBarKey] = useState<any>(null);

  const activeTooltip = props?.data?.activeTooltip;
const setActiveTooltip = props?.data?.setActiveTooltip;


  // Funzione per il tooltip personalizzato
  const CustomTooltip: any = ({ active, payload }) => {

    if (activeTooltip !== hoveredBarKey+props?.data?.key) { return; }

    if (active && payload && payload.length) {
      const activePayload = payload.find(
        (item) => item.dataKey === hoveredBarKey
      );
      const unit =
        props?.data?.kpi?.find((datum: any) => datum.name === hoveredBarKey)
          ?.unit || '';



      if (activePayload) {

          const colorIndex = props?.data?.graphKeys.indexOf(hoveredBarKey);


        if (props?.data?.combinedKeys[0].includes(activePayload?.dataKey) || props?.data?.combinedKeys[1].includes(activePayload?.dataKey)) {

          

          return [props?.data?.combinedKeys[0].includes(activePayload?.dataKey), props?.data?.combinedKeys[1].includes(activePayload?.dataKey)]
            .map((x, i) => {
              if (x) {

                const firstActivePayload = payload.find( item => item.dataKey === props?.data?.combinedKeys[i][0]);
                const secondActivePayload = payload.find(item => item.dataKey === props?.data?.combinedKeys[i][1]);

                if ( firstActivePayload === undefined || secondActivePayload === undefined ) { return <></>; }
                

                return (
                  <div
                    style={{
                      backgroundColor: 'var( --hive-greyscale-100 )',
                      padding: '10px',
                      borderRadius: '8px',
                      fontFamily: 'Flexo',
                      fontSize: 14,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        borderBottom: `solid 4px ${firstActivePayload.fill}`,
                      }}
                    >
                      <strong className="font-flexo-semibold">
                        {t(
                          `widgets.horizontalChart.${props?.data?.context}.${props?.data?.combinedKeys[i][0]}`
                        )}
                      </strong>{' '}
                      {props?.data?.context === 'economics'
                        ? `${unit} ${firstActivePayload.value} `
                        : `${firstActivePayload.value} ${unit}`}
                      
                      {' | '}
                    </p>

                    <p
                      style={{
                        margin: 0,
                        borderBottom: `solid 4px ${secondActivePayload.fill}`,
                      }}
                    >
                      <strong className="font-flexo-semibold">
                        &nbsp;
                        {t(
                          `widgets.horizontalChart.${props?.data?.context}.${props?.data?.combinedKeys[i][1]}`
                        )}
                      </strong>{' '}
                      {props?.data?.context === 'economics'
                        ? `${unit} ${secondActivePayload.value} `
                        : `${secondActivePayload.value} ${unit}`}
                    </p>
                  </div>
                )
              }
            })


        }


        return (
          <div
            style={{
              backgroundColor: 'var( --hive-greyscale-100 )',
              padding: '10px',
              borderRadius: '8px',
              fontFamily: 'Flexo',
              fontSize: 14,
            }}
          >
            <p
              style={{
                margin: 0,
                borderBottom: `solid 4px ${ThemeStore?.colors[props?.data?.tooltipColors[colorIndex].color]}${props.data?.tooltipColors[colorIndex].colorShade}`,
              }}
            >
              <strong className="font-flexo-semibold">
                {t(
                  `widgets.horizontalChart.${props?.data?.context}.${activePayload.dataKey}`
                )}
              </strong>{' '}
              {props?.data?.context === 'economics'
                ? `${unit} ${activePayload.value} `
                : `${activePayload.value} ${unit}`}
            </p>
          </div>
        );
      }
    }

    return null;
  };

  const CustomBarShape = (_props) => {
    const { y, height, fill, keyName, index, array } = _props;
    let {x, width} = _props;

    let firstvalue = 0;
    let secondValue = 0;
    let shouldHideTheNextBar = false;
    
    if (props?.data?.combinedKeys[0][1] === keyName) {
      firstvalue = _props[ props?.data?.combinedKeys[0][0]];
      secondValue = _props[props?.data?.combinedKeys[0][1]];
    } else if ( props?.data?.combinedKeys[1][1] === keyName ) {
      firstvalue = _props[ props?.data?.combinedKeys[1][0]];
      secondValue = _props[props?.data?.combinedKeys[1][1]];
    }

    if (props?.data?.combinedKeys[0][0] === keyName) {
      if (
        _props[props?.data?.combinedKeys[0][0]] === _props[props?.data?.combinedKeys[0][1]]
        
      ) {
        shouldHideTheNextBar = true;
      }
    }

    if (props?.data?.combinedKeys[1][0] === keyName) {
      if (
        _props[props?.data?.combinedKeys[1][0]] === _props[props?.data?.combinedKeys[1][1]]
        
      ) {
        shouldHideTheNextBar = true;
      }
    }

    if (shouldHideTheNextBar) {
      width = width + 6;
      x = x + 3;
    }
    
    if (firstvalue !== 0) {
      x = x - (width * (firstvalue / secondValue)) + 6;
    }



    if (!props?.data?.combinedKeys) {
      return <g></g>;
    }


    const isActive = props.data.activeKeys.includes(keyName);

    const sortedActiveKeys = [...props?.data?.activeKeys || []].sort((a, b) => {
      return props?.data?.graphKeys.indexOf(a) - props?.data?.graphKeys.indexOf(b);
    });

    const firstCommonKey = sortedActiveKeys.find(key => props?.data?.graphKeys.includes(key));
    const isFirstCommonKey = firstCommonKey === keyName;

    const keyIndex = (props?.data?.graphKeys || []).indexOf(keyName);

    function areItemsBeforeNull(index) {
      return props?.data?.graphKeys.slice(0, index).every(item => ((data[0][item] === null || data[0][item] === undefined)));
    }

    const indexBeforeAreNull = areItemsBeforeNull(keyIndex);

    const lastCommonKey = [...sortedActiveKeys].reverse().find(key => (props?.data?.graphKeys.includes(key)));
    const isLastCommonKey = lastCommonKey === keyName || shouldHideTheNextBar;

    return (
      <g>
        {/* Disegna la barra classica */}
        <rect
          x={ (index === array.length - 1 || isLastCommonKey ) ? x - 6 : x}
          y={y}
          width={width}
          height={height}
          fill={isActive ? fill : 'transparent'} // Rende invisibile la barra se non è attiva
          style={
            isFirstCommonKey && isLastCommonKey
              ? { clipPath: 'inset(0% 4px 0% 4px round 10px)' }
              : isFirstCommonKey
                ? { clipPath: 'inset(0px 4px 0px 0px)' }
                : isLastCommonKey
                  ? { clipPath: 'inset(0px 0px 0px 4px)' }
                  : indexBeforeAreNull
                    ? { clipPath: 'inset(0px 4px 0px 0px)' }
                    : {}
          }
          rx={isFirstCommonKey || isLastCommonKey || indexBeforeAreNull ? 4 : 0}
        />

        { !props?.data?.combinedKeys.flat()?.includes( keyName ) && (
          <rect
            x={x + width - 3} // Posiziona la linea sul lato destro
            y={y}
            width={3} // Larghezza del bordo destro
            height={height}
            fill="#FFFFFF" // Colore del bordo
          />
        )}
      </g>
    );
  };



  useEffect(() => {

    if (props?.data?.kpi && props?.data?.graphKeys) {
      const _data = props.data.kpi
        .filter((datum: any) => (props.data.activeKeys.includes(datum.name) && datum?.value !== null))
        .reduce(
          (prev, next) => ({
            ...prev,
            [next.name]: next.value,
          }),
          {
            name: t(props.data.rankingKpiName),
          }
        );

        setData([_data]);

    }
  }, [props.data]);



  return data?.length > 0 ? (
    <>
      <ResponsiveContainer width={'100%'} height={20} ref={ref}>
      <BarChart
        layout="vertical" // Disposizione orizzontale delle barre
        data={data}
        margin={{
          top: 4,
          right: 0,
          left: 0,
          bottom: 4,
        }}
        
        
      >
        <XAxis type="number" hide={true} axisLine={false} tick={ false} />
        <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} tick={ false}/>
        <Tooltip isAnimationActive={false} content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
        {props?.data?.graphKeys.map((key: string, index: number, array: any) => (
          <Bar
            isAnimationActive={true}
            key={key}
            id={key}
            dataKey={key}
            stackId={props.data.rankingKpiName}
            onMouseEnter={() => (setActiveTooltip(key+props?.data?.key), setHoveredBarKey(key))}
            onMouseLeave={() => (setActiveTooltip(null), setHoveredBarKey(null))}
            fill={`${ThemeStore?.colors[props?.data?.colors[index].color]}${props.data?.colors[index].colorShade}`}
            shape={(barProps) => (
              <CustomBarShape
                {...barProps}
                keyName={barProps.dataKey}
                index={index}
                array={array}
              />
            )}
          />
        ))}
      </BarChart>
      </ResponsiveContainer>
      {
        props?.data?.setRendered &&  props?.data?.setRendered(true)
      }
    </>
  ) : null;
}
