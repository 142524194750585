/*eslint no-sequences: "off"*/
/*eslint no-empty-pattern: "off"*/

import { createSlice } from '@reduxjs/toolkit';
import { Ikpi } from './interfaces';
import { initState } from './init.state';

import { SetKpiDataCommunityAsync, SetKpiDataCommunityRankingAsync, SetKpiDataSiteAsync, SetKpiDataMemberAsync, SetKpiDataMemberRankingAsync } from './async-thunks';

export const KPIslice = createSlice({
  name: 'kpi',
  initialState: initState,
  reducers: {
    SetKpiDataCommunity: (state: Ikpi, action: any) => {
      return {
        ...state,
        community: action?.payload || null
      };
    },
    SetKpiDataCommunityRanking: (state: Ikpi, action: any) => {
      return {
        ...state,
        communityRanking: {
          ...(state?.communityRanking || {}),
          ...action.payload,
        },
      };
    },
    SetKpiDataMemberRanking: (state: Ikpi, action: any) => {
      return {
        ...state,
        memberRanking: {
          ...(state?.memberRanking || {}),
          ...action.payload,
        },
      };
    },
    SetKpiDataSite: (state: Ikpi, action: any) => {
      return {
        ...state,
        site: action?.payload || null,
      };
    },
    SetKpiDataMember: (state: Ikpi, action: any) => {
      return {
        ...state,
        member: action?.payload || null,
      };
    },
    ResetKpiApiStore: () => {
      return initState;
    },
    SetKpiDataCustom: (state: Ikpi, action: any) => {
      // Aggiungi la logica per questa funzione personalizzata, se necessario
      return {
        ...state,
        customData: action.payload, // Esempio, sostituire con il tuo campo reale
      };
    },
    SetLoading: (state: Ikpi, action: any) => {
      return {
        ...state,
        isLoading: action.payload,
      };

    },

    ResetKpiFields: (state: Ikpi) => {
      return {
        ...state,
        community: null,
        member: null,
        communityRanking: null,
        memberRanking: null,
        site: null,
      } as any
    },

    ResetNamedKpiFields: (state: Ikpi, action: any) => {
      return {
        ...state,
        [action.payload]: null,
      } as any
    }
  },
  extraReducers: (builder) => {


    // ########### COMMUNITY DATA ########### 
    builder.addCase(SetKpiDataCommunityAsync.pending, (_state, _action) => ({
      ..._state,
      KpiDataCommunityAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetKpiDataCommunityAsync.rejected, (_state, _action) => ({
      ..._state,
      KpiDataCommunityAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetKpiDataCommunityAsync.fulfilled, (_state, action) => ({
      ..._state,
      community: action.payload,
      KpiDataCommunityAsyncStatus: 'SUCCESS',
    }));


    builder.addCase(SetKpiDataCommunityRankingAsync.pending, (_state, _action) => ({
      ..._state,
      KpiDataCommunityRankingAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetKpiDataCommunityRankingAsync.rejected, (_state, _action) => ({
      ..._state,
      KpiDataCommunityRankingAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetKpiDataCommunityRankingAsync.fulfilled, (_state, action) => ({
      ..._state,
      communityRanking: action.payload as any,
      KpiDataCommunityRankingAsyncStatus: 'SUCCESS',
    }));

    // ########### SITE DATA ########### 
    builder.addCase(SetKpiDataSiteAsync.pending, (_state, _action) => ({
      ..._state,
      KpiDataSiteAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetKpiDataSiteAsync.rejected, (_state, _action) => ({
      ..._state,
      KpiDataSiteAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetKpiDataSiteAsync.fulfilled, (_state, action) => ({
      ..._state,
      site: action.payload as any,
      KpiDataSiteAsyncStatus: 'SUCCESS',
    }));

    // ########### MEMBER DATA ########### 
    builder.addCase(SetKpiDataMemberAsync.pending, (_state, _action) => ({
      ..._state,
      KpiDataMemberAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetKpiDataMemberAsync.rejected, (_state, _action) => ({
      ..._state,
      KpiDataMemberAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetKpiDataMemberAsync.fulfilled, (_state, action) => ({
      ..._state,
      member: action.payload as any,
      KpiDataMemberAsyncStatus: 'SUCCESS',
    }));


    builder.addCase(SetKpiDataMemberRankingAsync.pending, (_state, _action) => ({
      ..._state,
      KpiDataMemberRankingAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetKpiDataMemberRankingAsync.rejected, (_state, _action) => ({
      ..._state,
      KpiDataMemberRankingAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetKpiDataMemberRankingAsync.fulfilled, (_state, action) => ({
      ..._state,
      memberRanking: action.payload as any,
      KpiDataMemberRankingAsyncStatus: 'SUCCESS',
    }));
  },
});

// Action creators are generated for each case reducer function
export const {
  SetKpiDataSite,
  SetKpiDataCommunity,
  SetKpiDataCommunityRanking,
  SetKpiDataMemberRanking,
  SetKpiDataCustom,
  ResetKpiApiStore,
  SetKpiDataMember,
  SetLoading,
  ResetKpiFields,
  ResetNamedKpiFields,
} = KPIslice.actions;

export { SetKpiDataCommunityAsync, SetKpiDataCommunityRankingAsync, SetKpiDataSiteAsync, SetKpiDataMemberAsync, SetKpiDataMemberRankingAsync };

export default KPIslice.reducer;
