import { IconWrapper } from '@flexo/general'
import styles from './right-drawer-content.module.scss';
import { useTranslation } from 'react-i18next'
import { timeFormat } from 'd3-time-format'
import { useDispatch, useSelector } from 'react-redux'
import { RIGHT_DRAWER } from '@flexo/reducers'
import { formatWeek, formatDay, formatMonth, formatYear, checkItIsToday } from '../../hive-calendar/helpers'


export default function MemberTotalEstimatedBenefitsDrawerContent() {

  const { t } = useTranslation();
  const dispatch = useDispatch()
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);
  const calendar = useSelector((state: any) => state.calendar);

  const memberData = kpi?.member?.['1day']?.[0]?.kpi || [];
  const { hiveCalendar: { selectedDate, selectedTimeSet } } = calendar;

  const memberDetails = metadata?.selectedEntity as any ||
    (metadata?.entities as any)?.member?.find(member => member?.memberId === metadata?.selectedEntityId);

  function getKpi(kpiName: string) {
    const kpi = memberData.find((kpi) => kpi.name === kpiName) || null;
    if (!kpi) return '';
    return `${kpi.unit} ${kpi?.value || '-.-'}`;
  }



  function closeDrawer() {
    dispatch(RIGHT_DRAWER.HideDrawer())
    
  }

  const getDate = () => {
    
    let result = '';
    switch (selectedTimeSet) {
      case 'day':
        if (
          checkItIsToday(selectedDate)
        )
          result = t('widgets.calendar.today');
        else result = formatDay(selectedDate);
        break;

      case 'week':
        result = selectedDate[0]
          ? `${formatWeek(selectedDate)}`
          : 'Select Week';
        break;

      case 'month':
        result = formatMonth(selectedDate);
        break;

      case 'year':
        result = formatYear(selectedDate);
        break;

      default:
        if (
          formatDay(selectedDate) ===
          formatDay([new Date(), new Date()] as any)
        )
          result = t('widgets.calendar.yesterday');
        else result = formatDay(selectedDate);
        break;
    }
    return result;
  };

  function getMemberSites() {
    const _id = metadata?.selectedEntityId;
    const _sites = ((metadata?.entities as any)?.site || [])?.filter((site: any) => site?.memberId === _id);
    return _sites
  }

  return (
    <div id="TotalEstimatedBenefitsDrawerContent" className={styles.RightDrawerContent__Wrapper}>
      <div className={styles.RightDrawerContent__Title}>
        <p className={styles.RightDrawerContent__Paragraph}>
          {t('drawers.memberTotalEstimatedBenefits.title')}
        </p>
        <div onClick={() => closeDrawer()}>
          <IconWrapper iconName="cross" />
        </div>
      </div>
      <div className={styles.RightDrawerContent__Subtitle}>
        <p className={styles.RightDrawerContent__Paragraph}>
          {memberDetails?.memberFirstName} {memberDetails?.memberLastName} <label>{getDate()}</label>
        </p>
      </div>
      <div className={styles.RightDrawerContent__Body}>
        <p className={styles.RightDrawerContent__Body__Value}>
          {getKpi('total_benefits')}
        </p>
        <p className={styles.RightDrawerContent__Body__Description}>
          {t('drawers.memberTotalEstimatedBenefits.description')}
        </p>
        <p className={styles.RightDrawerContent__Body__List__Title}>
          {getKpi('revenues')} {t('drawers.memberTotalEstimatedBenefits.list_title')}
        </p>
        <ul className={styles.RightDrawerContent__Body__List}>
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={styles.RightDrawerContent__Paragraph}>{t('drawers.memberTotalEstimatedBenefits.list_item_1')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
              {getKpi('charge_reduction')}
            </p>
          </li>
          <li className={styles.RightDrawerContent__Body__List__Item}>
            <p className={styles.RightDrawerContent__Paragraph}>{t('drawers.memberTotalEstimatedBenefits.list_item_2')}</p>
            <p className={styles.RightDrawerContent__Paragraph}>
              {getKpi('public_incentive')}
            </p>
          </li>
          {

            getMemberSites()?.filter(site => (site?.type === 'consumer' && site?.siteType?.[0]?.siteType !== 'consumer'))?.length > 0 &&
            <li className={styles.RightDrawerContent__Body__List__Item}>
              <p className={styles.RightDrawerContent__Paragraph}>{t('drawers.memberTotalEstimatedBenefits.list_item_3')}</p>
              <p className={styles.RightDrawerContent__Paragraph}>
                {getKpi('direct_compensation')}
              </p>
            </li>
          }

        </ul>

       

      </div>
      {
      (getKpi('savings') !== '' && getKpi('savings') !== '€ null' && getKpi('savings') !== '€ -.-') &&
        <p className={styles.RightDrawerContent__Body__List__Title} style={{ marginTop: '20px' }}>
          {getKpi('savings')} {t('drawers.memberTotalEstimatedBenefits.savings_title')}
        </p>
      }
    </div>
  )

}