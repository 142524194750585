
import React, { useContext, useEffect, useState, } from 'react';

import styles from './header-web.module.scss';
import { DataContext, ModulesContext } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import { BreadCrumbsDropDown, ProfileButton, Search } from '@flexo/atoms';

import './header-web.module.scss';
import { useNavigate } from 'react-router-dom';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__Light,
  Navigation__BreadCrumb,
  Header__Web__Center
} = styles;

function CommunityNavigation(props: any) {
  
  const { download = null, searchBar = null, profile = null, breadCrumbs = null } = props;
  const { label } = props;
  
  const { t } = useTranslation();
  
  const queryParams = window?.location?.search || '';
  const navigate = useNavigate()

  

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>
        
        <div className={Navigation}>
          <label className={ `${styles.clickable}`} onClick={() => navigate(`/communities${queryParams}`)}>{t(`widgets.header.communities`)}</label>
          
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right'/></span>
          {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          }

      
        </div>
        
        


      </div>
      <div className={Header__Web__Right}>
        {
          download && <>
            <IconWrapper iconName={'chevron-encircled'} />
          </>
        }
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(CommunityNavigation);
