/*eslint no-sequences: "off"*/
/*eslint no-empty-pattern: "off"*/

import { createSlice } from "@reduxjs/toolkit";
import { IMetaData } from './interfaces';
import { initState } from "./init.state";
import { setUrl } from '../../utils';
import { SetEntitiesAsync, SetMembersAsync } from "./async.thunks";



export const MetaDataSlice = createSlice({
  name: "metadata",
  initialState: initState,
  reducers: {
    SetFilteredData: (state: IMetaData, action: any) => {
      return {
        ...state,
        filteredData: [
          ...(state?.filteredData ? state.filteredData : []),
          ...action.payload,
        ]
      }
    },
    SetFilteredMembers: (state: IMetaData, action: any) => {
      return {
        ...state,
        filteredMembers: action.payload
      }
    },
    SetFilteredCommunities: (state: IMetaData, action: any) => {
      return {
        ...state,
        filteredCommunities: action.payload
      }
    },
    SetFilteredSites: (state: IMetaData, action: any) => {
      return {
        ...state,
        filteredSites: action.payload
      }
    },
    SetSelectedEntity: (state: IMetaData, action: any) => {
      return {
        ...state,
        selectedEntity: action.payload.selectedEntity,
        selectedEntityId: action.payload.id,
        entityType: action.payload.entityType,
      };
    },
    ClearSelectedEntity: (state: IMetaData) => {
      return {
        ...state,
        selectedEntity: null,
        selectedEntityId: null,
        entityType: null,
      };
    },
    SetSelectedEntityContent: (state: IMetaData, action: any) => {
      return {
        ...state,
        selectedEntity: action.payload,
      };
    },
    SetEntityType: (state: IMetaData, action: any) => {
      setUrl(`${action.payload}`);
      return {
        ...state,
        entityType: action.payload,
      };
    },
    SetSelectedEntityID: (state: IMetaData, action: any) => {
      setUrl(`${state.entityType}/${action.payload}`);
      return {
        ...state,
        selectedEntityId: action.payload,
      };
    },
    SetSelectedEntityName: (state: IMetaData, action: any) => {
      return {
        ...state,
        selectedEntityName: action.payload,
      };
    },

    SetPrevSelectedEntityId: (state: IMetaData, action: any) => {
      return {
        ...state,
        prevSelectedEntityId: action.payload,
      };
    },
    SetEntities: (state: IMetaData, action: any) => {
      return {
        ...state,
        entities: action.payload,
      };
    },
    SetCommunities: (state: IMetaData, action: any) => {

      return {
        ...state,
        communities: action.payload,
      };
    },
    SetMembers: (state: IMetaData, action: any) => {
      return {
        ...state,
        members: action.payload,
      };
    },
    SetMembersOverview: (state: IMetaData, action: any) => {
      return {
        ...state,
        membersOverview: action.payload,
      };
    },
    SetCommunitiesOverview: (state: IMetaData, action: any) => {
      return {
        ...state,
        communitiesOverview: action.payload,
      };
    },
    SetSortedEntities: (state: IMetaData, action: any) => {
      return {
        ...state,
        sortedEntities: action.payload,
      };
    },
    SetUsefulData: (state: IMetaData, action: any) => {
      return {
        ...state,
        usefulData: action.payload,
      };
    },

    SetMetadata: (state: IMetaData, action: any) => {
      return {
        ...action.payload,
      };
    },
    ResetMetadata: () => {
      console.log('RESET METADATA');
      return initState;
    },
    ResetAllFilters: (state: IMetaData) => {
      return {
        ...state,
        filteredCommunities: null,
        filteredMembers: null,
        filteredSites: null,
      }
    },
    ResetFilter: (state: IMetaData, action: any) => {
      return {
        ...state,
        [action.payload]: null,
      }
    }

  },
  extraReducers: (builder) => {
    // ########### ENTITIES DATA ########### 
    builder.addCase(SetEntitiesAsync.pending, (_state, _action) => ({
      ..._state,
      EntitiesAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetEntitiesAsync.rejected, (_state, _action) => ({
      ..._state,
      EntitiesAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetEntitiesAsync.fulfilled, (_state, action) => ({
      ..._state,
      entities: action.payload as any,
      EntitiesAsyncStatus: 'SUCCESS',
    }));

    // ########### MEMBERS DATA ########### 
    builder.addCase(SetMembersAsync.pending, (_state, _action) => ({
      ..._state,
      MembersAsyncStatus: 'PENDING',
    }));
    builder.addCase(SetMembersAsync.rejected, (_state, _action) => ({
      ..._state,
      MembersAsyncStatus: 'ERROR',
    }));
    builder.addCase(SetMembersAsync.fulfilled, (_state, action) => ({
      ..._state,
      members: action.payload as any,
      MembersAsyncStatus: 'SUCCESS',
    }));
  }
});

// Action creators are generated for each case reducer function
export const {
  SetSelectedEntity,
  SetEntities,
  SetSortedEntities,
  SetCommunities,
  SetMembers,
  SetMembersOverview,
  SetCommunitiesOverview,
  SetSelectedEntityID,
  SetSelectedEntityName,
  SetSelectedEntityContent,
  SetEntityType,
  SetUsefulData,
  ResetMetadata,
  SetFilteredData,
  SetFilteredMembers,
  SetFilteredCommunities,
  SetFilteredSites,
  ResetAllFilters,
  ResetFilter,
  ClearSelectedEntity,
  SetPrevSelectedEntityId,
  SetMetadata,
} = MetaDataSlice.actions;

export { SetEntitiesAsync, SetMembersAsync };

export default MetaDataSlice.reducer;
