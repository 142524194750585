// eslint-disable-prototype-builtins

import styles from './information-card.module.scss';
import { useContext } from 'react';
import { ModalContext, ModalTypes } from '@flexo/providers';

import { useTranslation } from 'react-i18next';
import { IconWrapper } from '@flexo/general';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { navigateToCommunity } from '../resolvers/community-resolver';
import { RIGHT_DRAWER } from '@flexo/reducers';
import { navigateToSite } from '../resolvers/site-overview-resolver';
import { navigateToMember } from '../resolvers/member-resolver';
import Shimmer from '../shimmer/shimmer';
import { CardSkeleton } from '../skeletons/card-skeleton';
import parse from 'html-react-parser';
import {
  formatWeek,
  formatDay,
  formatMonth,
  formatYear,
  checkItIsToday,
} from './helpers';

const ENV = (import.meta as any).env;

export function InformationCard(props: any) {
  const {
    InformationCard__Icon,
    InformationCard__Value,
    InformationCard__Label,
    InformationCard__Label__Disabled,
    InformationCard__Detail,
  } = styles;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: urlID } = useParams();
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);
  const calendar = useSelector((state: any) => state.calendar);
  const { selectedDate, selectedTimeSet } = calendar.hiveCalendar;

  const currencyFields = ['saving', 'benefit', 'economic', 'revenue'];

  const { setModalStore } = useContext(ModalContext);

  const { community, member } = kpi;

  let overviewKpiApiData = kpi?.site?.['1day']?.[0]?.kpi as any;
  let LOADING_STATE = kpi?.KpiDataSiteAsyncStatus;

  if (metadata?.entityType?.includes('community')) {
    overviewKpiApiData = community?.['1day']?.[0]?.kpi as any;
    LOADING_STATE = kpi?.KpiDataCommunityAsyncStatus;
  }

  if (metadata?.entityType?.includes('member')) {
    overviewKpiApiData = member?.['1day']?.[0]?.kpi as any;
    LOADING_STATE = kpi?.KpiDataMemberAsyncStatus;
  }

  const getDate = () => {
    let result = '';
    switch (selectedTimeSet) {
      case 'day':
        if (checkItIsToday(selectedDate)) result = t('widgets.calendar.today');
        else result = formatDay(selectedDate);
        break;

      case 'week':
        result = selectedDate[0]
          ? `${formatWeek(selectedDate)}`
          : 'Select Week';
        break;

      case 'month':
        result = formatMonth(selectedDate);
        break;

      case 'year':
        result = formatYear(selectedDate);
        break;

      default:
        if (
          formatDay(selectedDate) === formatDay([new Date(), new Date()] as any)
        )
          result = t('widgets.calendar.yesterday');
        else result = formatDay(selectedDate);
        break;
    }
    return result;
  };

  const onClickLabel = () => {
    switch (props?.env || '') {
      case 'web':
        if (props?.descriptionCallback === 'communityEnergy') {
          navigateToCommunity('community-energy', urlID as string);
        } else if (props?.descriptionCallback === 'siteEnergy') {
          navigateToSite('site-energy', urlID as string);
        } else if (props?.descriptionCallback === 'communityEconomics') {
          navigateToCommunity('community-economics', urlID as string);
        } else if (props?.descriptionCallback === 'MemberBenefits') {
          navigateToMember('member-economics', urlID as string);
        } else if (props?.descriptionCallback === 'memberEnergy') {
          navigateToMember('member-energy', urlID as string);
        } else if (
          props?.descriptionCallback === 'communityTotalEstimatedBenefits'
        ) {
          dispatch(
            RIGHT_DRAWER.SetDrawer({
              ...props,
              type: 'COMMUNITY_TOTAL_ESTIMATED_BENEFITS',
            })
          );
        } else if (
          props?.descriptionCallback === 'memberTotalEstimatedBenefits'
        ) {
          dispatch(
            RIGHT_DRAWER.SetDrawer({
              ...props,
              type: 'MEMBER_TOTAL_ESTIMATED_BENEFITS',
            })
          );
        } else if (
          props?.descriptionCallback === 'SiteTotalEstimatedBenefits'
        ) {
          dispatch(
            RIGHT_DRAWER.SetDrawer({
              ...props,
              type: 'SITE_TOTAL_ESTIMATED_BENEFITS',
            })
          );
        } else if (props?.descriptionCallback === 'siteEconomics') {
          navigateToSite('site-economics', urlID as string);
        } else {
          dispatch(
            RIGHT_DRAWER.SetDrawer({
              ...props,
            })
          );
        }

        break;
      default:
        if (!props.details.title) return;
        setModalStore({
          type: ModalTypes.OpenModal,
          payload: {
            value: {
              type: 'CARD',
              visible: true,
              show: true,
              props: { ...props },
              content: 'CARD',
            } as any,
          },
        });
        break;
    }
  };

  const getCardValue = () => {
    let cardValue = overviewKpiApiData?.find(
      (el) => el.name === props['totalValue']
    );

    if (props['totalValue'] === 'sites_count') {
      cardValue = {
        value: metadata?.sortedEntities?.communities[0]?.members.reduce(
          (prev, next) => prev + (next?.sites?.length || 0),
          0
        ),
        unit: '',
      };
    }

    return (
      <div
        key={uuidv4()}
        className={`${
          ENV.VITE_APP_IS_WEB === 'true' &&
          styles.InformationCard__Web__FeaturedData__Wrapper
        }`}
      >
        <span
          key={uuidv4()}
          className={`featuredData ${
            ENV.VITE_APP_IS_WEB === 'true' &&
            styles.InformationCard__Web__FeaturedData
          }`}
          style={{ fontWeight: 200 }}
        >
          {!cardValue?.value ? '-.-' : cardValue?.value}
          {/* {/[.,]/.test(overviewKpiApiData[props['totalValue']])
            ? ''
            : ',00'}{' '} */}
        </span>
        <span key={uuidv4()} className="heading3L" style={{ fontWeight: 200 }}>
          {' ' + (cardValue?.unit || cardValue?.unit_type || '')}
        </span>
      </div>
    );
  };

  const getCardApiValue = (key) => {
    const cardValue = overviewKpiApiData?.find((el) => el.name === key);

    if (
      cardValue === null ||
      cardValue === undefined ||
      Number.isNaN(cardValue?.value)
    ) {
      return { value: ' -.- ', unit: '' }; // Ensure value and unit are always present
    } else {
      return currencyFields?.includes(cardValue?.type)
        ? { ...cardValue, value: (cardValue?.value || 0).toFixed(2) }
        : cardValue;
    }
  };

  function getSiteRanking() {
    const site =
      (metadata?.entities as any)?.site?.find(
        (_site: any) =>
          _site.siteId ===
          kpi?.communityRanking?.economicsRanking?.[0]?.site?.[0]?.siteId
      ) || null;
    return site?.siteName || '';
  }

  const getCardDetail = () => {
    // console.log('props.descriptionType', props.descriptionType);
    const cardValue = getCardApiValue(props['descriptionValue']);

    switch (props.descriptionType) {
      case 'totalSitesCard':
        if (
          !(metadata as any)?.sortedEntities ||
          (metadata as any)?.entities?.length === 0
        ) {
          return <></>;
        }

        // eslint-disable-next-line
        let community = (metadata as any)?.sortedEntities?.communities.find(
          (community) =>
            community.id === metadata?.selectedEntityId ||
            community.communityId === metadata?.selectedEntityId
        );

        if (!community) {
          community = (metadata as any)?.sortedEntities?.communities[0];
        }

        // eslint-disable-next-line
        const siteTypeCounts = {
          consumer: 0,
          producer: 0,
          prosumer: 0,
          prosumage: 0,
        };

        community.members.forEach((member) => {
          member.sites.forEach((site) => {
            if (Array.isArray(site.siteType)) {
              site.siteType.forEach((type) => {
                if (
                  type.siteType &&
                  // eslint-disable-next-line
                  (siteTypeCounts as any).hasOwnProperty(type.siteType)
                ) {
                  siteTypeCounts[type.siteType]++;
                }
              });
            } else if (typeof site.siteType === 'string') {
              // eslint-disable-next-line
              if (siteTypeCounts.hasOwnProperty(site.siteType)) {
                siteTypeCounts[site.siteType]++;
              }
            }
          });
        });

        for (const key in siteTypeCounts) {
          if (siteTypeCounts[key] === 0) {
            siteTypeCounts[key] = null;
          }
        }

        // eslint-disable-next-line
        const keys = props.description.map((el) =>
          el.key.split('.').slice(-1).pop()
        );

        return (
          <>
            {keys
              .filter((_el: any) => siteTypeCounts[_el] !== null)
              .map((siteType: string, _i: number, array: any) => {
                const label = props.description.find((desc: any) =>
                  desc.key.includes(siteType)
                );

                return (
                  <span key={uuidv4()} className="paragraph">
                    {t(label.key)} {siteTypeCounts[siteType]}{' '}
                    {_i < array.length - 1 ? '|' : ''}
                  </span>
                );
              })}
          </>
        );
      case 'web:label+value|label+value':
        return (
          <span className="paragraph">
            {props.description.map((el, _i, array) => (
              <>
                {t(el.key)}{' '}
                {getCardApiValue(el?.value)?.value ||
                  '-.- ' + getCardApiValue(el?.value)?.unit ||
                  ''}{' '}
                {_i < array.length - 1 ? '| ' : ''}
              </>
            ))}
          </span>
        );
      case 'label+value|label+value':
        return (
          <div>
            {props.description.map((el, _i, array) => (
              <span key={uuidv4()} className="paragraph">
                {t(el.key)}{' '}
                {getCardApiValue(el?.value)?.value ||
                  '-.- ' + getCardApiValue(el?.value)?.unit ||
                  ''}{' '}
                {_i < array.length - 1 ? '|' : ''}
              </span>
            ))}
          </div>
        );
      case 'label':
        return (
          <span key={uuidv4()} className="paragraph">
            {t(props.description)}
          </span>
        );
      case 'label+value+unit':
        return (
          <span key={uuidv4()} className="paragraph">
            {parse(
              t(props.description, {
                value: getCardApiValue(props?.value)?.value || '-.- ',
                unit: getCardApiValue(props?.value)?.unit || '',
              })
            )}
          </span>
        );
      case 'description':
        return <div className="paragraph">{t(props.description)}</div>;
      case 'keyValue':
        // eslint-disable-next-line no-case-declarations

        // eslint-disable-next-line no-case-declarations
        const result = props.description?.map((el) => {
          return (
            <div>
              <div>
                <span className="paragraph">{t(el.key)} </span>
                <span className="heading5">
                  {' '}
                  {(getCardApiValue(el?.value)?.value || '-.- ')?.toString() +
                    (getCardApiValue(el?.value)?.unit ||
                      getCardApiValue(el?.value)?.unit_type ||
                      '')}
                </span>
              </div>
            </div>
          );
        });

        return result;
      case 'value+label':
        return (
          <div>
            <span key={uuidv4()} className="paragraph">
              {' '}
              <span className="heading5">
                {(cardValue?.value || '-.- ') + (cardValue?.unit || '')}
              </span>
            </span>
            <span key={uuidv4()} className="paragraph">
              {t(props.description)}
            </span>
          </div>
        );
      case 'label+value':
        if (typeof props.description === 'string') {
          return (
            <div>
              <span key={uuidv4()} className="paragraph">
                {t(props.description)}
              </span>
              <span key={uuidv4()} className="paragraph">
                {' '}
                <span className="heading5">{cardValue?.value || '-.-'}</span>
              </span>
            </div>
          );
        } else if (Array.isArray(props.description)) {
          return (
            <div key={uuidv4()}>
              {props.description.map((el, _i, array) => (
                <div key={uuidv4()}>
                  <span className={`color-hive-greyscale-600`}>
                    <span className="font-flexo-semibold heading5">
                      {t(el.key)}{' '}
                    </span>
                    {(getCardApiValue(el?.value)?.value || '-.- ')?.toString() +
                      (getCardApiValue(el?.value)?.unit ||
                        getCardApiValue(el?.value)?.unit_type ||
                        '')}
                  </span>
                  {_i < array.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        }
        break;
      case '[member]label+value':
        if (typeof props.description === 'string') {
          return (
            <div>
              <span key={uuidv4()} className="paragraph">
                {t(props.description)}
              </span>
              <span key={uuidv4()} className="paragraph">
                {' '}
                <span className="heading5">{cardValue?.value || '-.-'}</span>
              </span>
            </div>
          );
        } else if (Array.isArray(props.description)) {

          let allowedIndex = 1;
          const memberSitesTypes = [...metadata?.sortedEntities?.communities?.map(c => c.members)?.flat() || []]?.find(m => m.memberId === urlID)?.sites?.map(s => s?.siteType?.[0].siteType) || [];
          if ( memberSitesTypes?.includes('producer') ) { allowedIndex++; }
          if ( memberSitesTypes?.includes('prosumer') ) { allowedIndex = 3; }
          if ( memberSitesTypes?.includes('prosumage') ) { allowedIndex = 3; }
          

          return (
            <div key={uuidv4()}>
              {props.description.map((el, _i, array) => {
                if (_i < allowedIndex) {
                  return <div key={uuidv4()}>
                    <span className={`color-hive-greyscale-600`}>
                      <span className="font-flexo-semibold heading5">
                        {t(el.key)}{' '}
                      </span>
                      {(getCardApiValue(el?.value)?.value || '-.- ')?.toString() +
                        (getCardApiValue(el?.value)?.unit ||
                          getCardApiValue(el?.value)?.unit_type ||
                          '')}
                    </span>
                    {_i < array.length - 1 && <br />}
                  </div>
                }
              })}
            </div>
          );
        }
        break;
      case 'siteType[label+value]':
        if (typeof props.description === 'string') {
          return (
            <div>
              <span key={uuidv4()} className="paragraph">
                {t(
                  props?.[
                    (metadata?.selectedEntity as any)?.siteType?.[0]
                      ?.siteType || 'default'
                  ]?.description
                )}
              </span>
              <span key={uuidv4()} className="paragraph">
                {' '}
                <span className="heading5">{cardValue?.value || '-.- '}</span>
              </span>
            </div>
          );
        } else if (Array.isArray(props.description)) {
          return (
            <div key={uuidv4()}>
              {props.description?.map((el, _i, array) => (
                <div key={uuidv4()}>
                  <span className={`color-hive-greyscale-600`}>
                    <span className="font-flexo-semibold heading5">
                      {t(el.key)}{' '}
                    </span>
                    {(getCardApiValue(el?.value)?.value || '-.- ')?.toString() +
                      (getCardApiValue(el?.value)?.unit ||
                        getCardApiValue(el?.value)?.unit_type ||
                        '')}
                  </span>
                  {_i < array.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        } else if (Object.keys(props?.description)?.length > 0) {
          return (
            <div key={uuidv4()}>
              {props.description?.[
                (metadata?.selectedEntity as any)?.siteType?.[0]?.siteType ||
                  (metadata?.selectedEntity as any)?.type ||
                  'default'
              ]?.map((el, _i, array) => (
                <div key={uuidv4()}>
                  <span className={`color-hive-greyscale-600`}>
                    <span className="font-flexo-semibold heading5">
                      {t(el.key)}{' '}
                    </span>
                    {(getCardApiValue(el?.value)?.value || '-.- ')?.toString() +
                      (getCardApiValue(el?.value)?.unit ||
                        getCardApiValue(el?.value)?.unit_type ||
                        '')}
                  </span>
                  {_i < array.length - 1 && <br />}
                </div>
              ))}
            </div>
          );
        }
        break;

      case '{{trees}}':
        return (
          <div>
            <span>
              {t(props.description.line_1)}
              <br />
              {t(props.description.line_2, { trees: cardValue?.value || 0 })}
            </span>
          </div>
        );
        break;
      case 'trees':
        return (
          <div className="paragraph">
            <span className="paragraph">
              {parse(t(props.description.line_1))}
              <br />
              {parse(t(props.description.line_2))}
              <span
                style={{
                  fontSize: '14px !important',
                  fontWeight: '500 !important',
                }}
                className="heading5"
              >
                {` ${cardValue?.value || ' -.-'}  ${t(
                  'widgets.pollutionCard.trees'
                )} `}
              </span>
              {t(props.description.line_3)}
            </span>
          </div>
        );
      case 'ranking[label+value]':
        return (
          <div>
            <span key={uuidv4()} className="paragraph">
              <span className="heading5"> {t(props.description)} </span>
            </span>
            <span key={uuidv4()} className="paragraph">
              {getSiteRanking()}
            </span>
          </div>
        );
        break;
    }
  };

  const { id } = props;
  return (
    <div
      className={` ${
        props.gradientBorder && styles.InformationCard__GradientBorder
      }`}
      style={{ height: '100%' }}
      key={Math.floor(Math.random() * 100)}
    >
      {LOADING_STATE === 'PENDING' && <Shimmer />}

      {LOADING_STATE === 'PENDING' &&
      (overviewKpiApiData?.length === 0 || !overviewKpiApiData) ? (
        <CardSkeleton id={props.id} icon={props.icon} />
      ) : (
        <div
          className={`${styles.InformationCard} ${
            ENV.VITE_APP_IS_WEB === 'true' && styles.InformationCard__Web
          }`}
        >
          {props.icon && (
            <span
              key={uuidv4()}
              className={`${InformationCard__Icon} ${
                ENV.VITE_APP_IS_WEB === 'true' &&
                styles.InformationCard__Web__Icon
              }`}
            >
              {<IconWrapper iconName={props.icon} />}{' '}
              {props?.isOverview === true && (
                <span className="paragraph color-grey-500">{getDate()}</span>
              )}
            </span>
          )}{' '}
          {props?.totalValue && (
            <span className={`${InformationCard__Value} `}>
              {' '}
              {getCardValue()}
            </span>
          )}
          <div
            key={uuidv4()}
            className={`${
              props?.detail === false
                ? InformationCard__Label__Disabled
                : InformationCard__Label
            } ${
              ENV.VITE_APP_IS_WEB === 'true' &&
              styles.InformationCard__Web__Label
            } heading4`}
            onClick={() =>
              props?.detail === false ? undefined : onClickLabel()
            }
            style={{ marginTop: '16px' }}
          >
            {/* {props.id === 'pollutionCard' ? (
              <span>
                CO<sub>2</sub>
              </span>
            ) : (
              parse(t(`widgets.${props.id}.title`))
            )} */}
            {parse(t(`widgets.${id}.title`))}
            <IconWrapper
              className={'InformationCard__Chevron'}
              iconName="chevron-right"
                style={ENV.VITE_APP_IS_WEB === 'true' ? { marginTop: '1.5px' } : { marginTop: -12} }
            />
          </div>
          <span
            className={`${InformationCard__Detail} paragraph ${
              ENV.VITE_APP_IS_WEB === 'true' &&
              styles.InformationCard__Web__Detail
            }`}
          >
            {getCardDetail()}
          </span>
        </div>
      )}
    </div>
  );
}

export default InformationCard;
