import { useContext, useRef, useState } from 'react';
import { Button, PageHeader, Textfield } from '@flexo/atoms';
import { FirebaseService, IconWrapper, httpService } from '@flexo/general';
import styles from './log-in.module.scss';
import { AlertContext, AlertTypes } from '@flexo/providers';
import { Player } from '@lottiefiles/react-lottie-player';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MODULES, USER } from '@flexo/reducers';
import { useNavigate } from 'react-router-dom';

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

/* eslint-disable-next-line */
export interface ApiLogInProps {
  logInData: any;
  setLogInFlow: any;
  bgColor?: string;
}

export function ApiLogIn({ setLogInFlow }: ApiLogInProps) {
  const {
    LogIn__Inputs,
    LogIn__Footer,
    LogIn__Footer__Register,
    LogIn__Footer__Register__Button,
    LogIn__Inputs__PasswordConditions,
  } = styles;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [authData, setAuthData] = useState({
    email: '',
    password: '',
    createPassword: '',
  });
  const [logInClicked, setLogInClicked] = useState(false);
  const { setAlertStore } = useContext(AlertContext);
  const [logInError, setLogInError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(false);
  const { t } = useTranslation();
  const passwordInputRef = useRef(null);

  const [inputType, setInputType] = useState({
    email: 'email',
    password: 'password',
    confirmPassword: 'password',
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    createPassword: false,
  });

  const [icon, setIcon] = useState('visibility');

  const {
    title,
    subtitle,
    inputs,
    buttonLabel,
    footer,
    onButtonClick,
    passwordConditions,
  } = t('pages.login.data', { returnObjects: true }) as any;

  const loader = '/loaderLottie.json';

  const handleInputChange = (event: any) => {
    setAuthData({ ...authData, [event.target.name]: event.target.value });
  };

  const signIn = async (event: any) => {
    if (logInClicked) return;
    setLogInClicked(true);
    setLogInError(false);
    try {
      // First, get the user token.
      const response: any = await httpService.post({
        url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/generate-signin-token`,
        data: {
          email: authData.email,
          password: authData.password,
        },
      });
      // Use the token from the response to sign in.
      const firebaseResult = await firebaseService.signInWithToken(
        response?.data?.token
      );

      dispatch(USER.SetUserAsync(firebaseResult as any) as any);

      navigate(`/${ENV.VITE_APP_FIRST_PAGE}`);
      //selected entity is set in site overview resolver

      // window.location.href = `/${ENV.VITE_APP_FIRST_PAGE}`;

      return firebaseResult;
    } catch (error: any) {
      if (error.message === 'ERR_NETWORK') {
        // console.error('Network error', error);
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            } as any,
          },
        });
      } else {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_WRONG_LOGIN__MOBILE',
            } as any,
          },
        });
      }

      setLogInError(true);

      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: error`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'warning' as any,
        extra: {
          error,
        },
      });
      setAuthData({ ...authData, password: '' });
      // console.error(error);
    } finally {
      // Reset logInClicked state after attempt.
      setLogInClicked(false);
    }
  };

  const checkCondition = (condition: string): string => {
    let iconName = '__outline';

    switch (condition) {
      case '8 o più caratteri':
        if (authData.createPassword.length >= 8) iconName = 'tick_outline';
        break;
      case 'Almeno 1 numero':
        if (authData.createPassword.match(/\d/)) iconName = 'tick_outline';
        break;
      // case 'Almeno 1 carattere speciale':
      //   if (authData.createPassword.match(/[@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/))
      //     return 'tick_outline';
      case 'Almeno 1 lettera maiuscola':
        if (authData.createPassword.match(/[A-Z]/)) iconName = 'tick_outline';
        break;
      case 'Almeno 1 lettera minuscola':
        if (authData.createPassword.match(/[a-z]/)) iconName = 'tick_outline';
        break;
      default:
        break;
    }

    return iconName;
  };

  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
    setIcon(icon === 'visibility' ? 'hide-visibility' : 'visibility');
  };

  const isEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    if (name !== 'email' || !value.length) return;
    if (!isEmail(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const forgotPassword = () => {
    dispatch(MODULES.ActivateModuleByName('forgot-password-mobile' as any));
    //TODO change to navigate
    navigate('/forgot-password-mobile');
    //Needs to update module, not working right now
  };
  const register = () => {
    dispatch(MODULES.ActivateModuleByName('register' as any));
    navigate('/register');
    //Needs to update module, not working right now

    //TODO change to navigate
  };

  return (
    <div className={styles.LogIn} style={{ backgroundColor: '#F7F7F7' }}>
      {/* <div className={LogIn__Header}>
          <span className={`${LogIn__Header__Title} heading2`}>{title}</span>{' '}
          <span className={`${LogIn__Header__Subtitle} heading5`}>
            {subtitle}
          </span>
        </div> */}
      <PageHeader title={title} subtitle={subtitle} />
      <div className={styles.Login__Content}>
        <div className={LogIn__Inputs}>
          {inputs.map((input: any) => (
            <div key={input.name}>
              <Textfield
                containedLabel={true}
                name={input.name}
                label={input.label}
                type={inputType[input.name]}
                placeholder={input.placeholder}
                error={errors[input.name]}
                value={authData[input.name]}
                onChange={(event) => handleInputChange(event)}
                onPaste={(e) => false}
                onCopy={(e) => false}
                description={input.description}
                errorDescription={
                  errors[input.name] && 'Inserisci una mail valida'
                }
                icon={input.name === 'password' ? icon : ''}
                onIconClick={togglePasswordVisibility}
                onDescriptionClick={() => forgotPassword()}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                onBlur={handleBlur}
              />

              {input.name === 'createPassword' && (
                <div>
                  {passwordConditions.map((condition: any) => (
                    <div
                      className={LogIn__Inputs__PasswordConditions}
                      key={condition}
                    >
                      <IconWrapper iconName={checkCondition(condition)} />
                      <span>{condition}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={LogIn__Footer}>
          <Button
            onClick={onButtonClick ? () => setLogInFlow(onButtonClick) : signIn}
            label={
              logInClicked && !logInError ? (
                <Player autoplay loop src={loader} style={{ height: 20 }} />
              ) : (
                buttonLabel
              )
            }
            variant="primary"
            color="secondary"
            disabled={
              authData.email === '' ||
              authData.password === '' ||
              (logInClicked && !logInError) ||
              networkStatus ||
              !isEmail(authData.email) ||
              errors.email
            }
          />
          <span className={`paragraph ${LogIn__Footer__Register}`}>
            {footer.label}
            <button
              className={`paragraph ${LogIn__Footer__Register__Button} `}
              style={{ fontSize: '1em' }}
              onClick={() => register()}
            >
              {footer.buttonLabel}
            </button>
          </span>{' '}
        </div>
        <div className={styles.LogIn__Logo}>
          <IconWrapper iconName="logo" />
        </div>
      </div>
    </div>
  );
}

export default ApiLogIn;
