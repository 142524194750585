import { useDispatch, useSelector } from 'react-redux';
import * as luxon from 'luxon';
import {
  formatDay,
  formatWeek,
  checkItIsToday,
  formatMonth,
  formatYear,
  isSevenDaysApart,
  getStartDate,
} from './helpers';

import { CALENDAR } from '@flexo/reducers';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';

import styles from './hive-calendar.module.scss';

export function HiveCalendarInput(props: any) {
  const { showCalendar, setShowCalendar } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendar = useSelector((state: any) => state.calendar);
  const hiveCalendar = calendar.hiveCalendar;
  const { selectedTimeSet, selectedDate } = hiveCalendar;

  const startDate = getStartDate();

  function getCalendarInputText() {
    const timeSet = selectedTimeSet;
    const _selectedDate = selectedDate;

    switch (timeSet) {
      case 'day':
        if (checkItIsToday(_selectedDate)) {
          return t('widgets.calendar.today');
        } else {
          return formatDay(_selectedDate);
        }
      case 'week':
        if (isSevenDaysApart(_selectedDate[0], _selectedDate[1])) {
          return formatWeek(_selectedDate);
        } else {
          if (checkItIsToday(_selectedDate)) {
            return t('widgets.calendar.today');
          } else {
            return formatDay(_selectedDate);
          }
        }
      case 'month':
        return formatMonth(_selectedDate);

      case 'year':
        return formatYear(_selectedDate);

      default:
        return _selectedDate[0]?.toDateString();
    }
  }

  function getFurtherOptions() {
    let options: any = { days: 1 };
    switch (selectedTimeSet) {
      case 'day':
        options = { days: 1 };
        break;
      case 'week':
        options = { days: 7 };
        break;
      case 'month':
        options = { months: 1 };
        break;
      case 'year':
        options = { years: 1 };
        break;
      default:
        break;
    }

    const _nextFirstDate = luxon.DateTime.fromJSDate(selectedDate[0])
      .plus(options)
      .startOf('day');
    const _nextSecondDate = luxon.DateTime.fromJSDate(selectedDate[1])
      .plus(options)
      .startOf('day');
    const _today = luxon.DateTime.fromJSDate(new Date()).startOf('day');

    return { options, _nextFirstDate, _nextSecondDate, _today };
  }

  function canGoFurther() {
    const { _nextFirstDate, _today } = getFurtherOptions();

    if (_nextFirstDate > _today) {
      return false;
    } else {
      return true;
    }
  }

  function getPreviuosOptions() {
    let options: any = { days: 1 };
    switch (selectedTimeSet) {
      case 'day':
        options = { days: 1 };
        break;
      case 'week':
        options = { days: 7 };
        break;
      case 'month':
        options = { months: 1 };
        break;
      case 'year':
        options = { years: 1 };
        break;
      default:
        break;
    }

    const _previousFirstDate = luxon.DateTime.fromJSDate(selectedDate[0])
      .minus(options)
      .startOf('day');
    const _previousSecondDate = luxon.DateTime.fromJSDate(selectedDate[1])
      .minus(options)
      .startOf('day');
    const _today = luxon.DateTime.fromJSDate(new Date()).startOf('day');

    return { options, _previousFirstDate, _previousSecondDate, _today };
  }

  function canGoBackward() {
    if (!startDate) {
      return true;
    }

    const { _previousFirstDate } = getPreviuosOptions();

    const luxonStartDate = luxon.DateTime.fromJSDate(
      new Date(startDate)
    ).startOf('day');

    if (selectedTimeSet === 'month') {
      return _previousFirstDate.year >= luxonStartDate.year;
    }

    if (_previousFirstDate < luxonStartDate) {
      return false;
    } else {
      return true;
    }
  }

  function goToNextDate() {
    const { _nextFirstDate, _nextSecondDate } = getFurtherOptions();
    dispatch(
      CALENDAR.setHiveDate({
        date: [_nextFirstDate.toJSDate(), _nextSecondDate.toJSDate()],
        timeSet: selectedTimeSet,
      } as any)
    );
  }

  function goToPrevDate() {
    const { _previousFirstDate, _previousSecondDate } = getPreviuosOptions();
    dispatch(
      CALENDAR.setHiveDate({
        date: [_previousFirstDate.toJSDate(), _previousSecondDate.toJSDate()],
        timeSet: selectedTimeSet,
      } as any)
    );
  }

  function toggleCalendar() {
    if (showCalendar === true) {
      dispatch(CALENDAR.setHiveCalendarDate(null as any));
      dispatch(CALENDAR.setHiveCalendarTimeSet(null as any));
    }

    setShowCalendar(!showCalendar);
  }

  return (
    <div className={styles.HiveCalendar__Input}>
      <div
        className={`
        ${styles.HiveCalendar__Input__Arrow}
        ${
          canGoBackward() === false
            ? styles.HiveCalendar__Input__Arrow__Disabled
            : ''
        }
        `}
        onClick={() => (canGoBackward() ? goToPrevDate() : null)}
      >
        <IconWrapper iconName="chevron-left" />
      </div>

      <div
        className={styles.HiveCalendar__Input__Content}
        onClick={() => toggleCalendar()}
      >
        {getCalendarInputText()}
      </div>

      <div
        className={`
        ${styles.HiveCalendar__Input__Arrow}
        ${
          canGoFurther() === false
            ? styles.HiveCalendar__Input__Arrow__Disabled
            : ''
        }
        `}
        onClick={() => (canGoFurther() ? goToNextDate() : null)}
      >
        <IconWrapper iconName="chevron-right" />
      </div>
    </div>
  );
}
