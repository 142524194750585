import styles from './skeleton.module.scss';
import { useTranslation } from 'react-i18next';

export function RankingSkeleton(props: any) {

  const { title, context, hideTitle = false } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.RankingSkeleton}>
      {
        hideTitle === false &&
        <>
          <div className={styles.RankingSkeleton__Title}>
            {t(title)}
          </div>
          <div className={styles.RankingSkeleton__Header}>
            <div className={styles.RankingSkeleton__Header__Label}></div>
            <div className={styles.RankingSkeleton__Header__Label}></div>
            <div className={styles.RankingSkeleton__Header__Label}></div>
          </div></>
      }
      <div className={styles.RankingSkeleton__Content}>

        {Array.from({ length: 4 }).map((_, index) => (
          <div key={`RankingSkeleton__Content__Item__${index}`} className={styles.RankingSkeleton__Content__Item}>
            <div className={styles.RankingSkeleton__Content__Item__Desc}>
              <div className={styles.RankingSkeleton__Content__Item__Desc__Unit}>
                -- {context === 'economics' ? '€' : 'kWh'}
              </div>
              <div className={styles.RankingSkeleton__Content__Item__Desc__Label}>
                {
                  context === 'economics'
                    ? t('widgets.horizontalChart.economics.benefits')
                    : index % 2 === 0 ? t('widgets.horizontalChart.energy.consumption') : t('widgets.horizontalChart.energy.production')
                }
              </div>
            </div>
            <div className={styles.RankingSkeleton__Content__Item__Bar}></div>
          </div>
        ))}




      </div>
    </div>
  );
}