import { useContext, useEffect } from 'react';


import {  AlertContext, AlertTypes } from '@flexo/providers';
import { FirebaseService, httpService } from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { METADATA, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';
;

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

export const MembersResolver = () => {
  
  const dispatch = useDispatch();

  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 
  const params = useParams()
  const metadata = useSelector((state: any) => state.metadata);

  const {setAlertStore} = useContext(AlertContext);

  async function init() {


    const idToken = await firebaseService.getIdToken();


    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   }
      // })

      dispatch(USER.SetLogout())

      return;
    }

    const COMMUNITY_ID = pathSegment === 'community' ? params.id : null;

    if (!COMMUNITY_ID) {
      return;
    }

   
    dispatch(METADATA.SetMembersAsync({idToken, setAlertStore, COMMUNITY_ID}) as any)



    // try {

      
    // globalSetFiltersStore({
    //   type: FiltersStoreTypes.SetFilteredMembers,
    //   payload: {
    //     value: members
    //   }
    // })
    // } catch (err: any) {
    //   console.warn(err);
    // }

  }

  useEffect(() => {
      init();
  }, [params.id]);



  return <></>
};
