
import React from 'react';

import styles from './header-web.module.scss';
import { IconWrapper } from '@flexo/general';
import {  ProfileButton, Search, navigateToCommunity, navigateToMember } from '@flexo/atoms';

import './header-web.module.scss';
import { Select } from '@mui/material';
import { Calendar, HiveCalendar } from '@flexo/molecules';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
  Header__Web__Center,
} = styles;

function MemberDataVisNavigation(props: any) {
  
  const params = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 

  const metadata = useSelector((state: any) => state.metadata);
  const communities = (metadata?.entities as any)?.community || [];
  const members = (metadata?.entities as any)?.member || [];
  const [selectValue, setSelectValue] = React.useState<string | null>(pathSegment || null);
  const [open, setOpen] = React.useState(false);


  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;


  function getMemberName() {
    
    const foundMember = members.find((member: any) => (member?.memberId === params.id ));
    
    const memberName = foundMember?.memberFirstName && foundMember?.memberLastName ? `${foundMember?.memberFirstName} ${foundMember?.memberLastName}` : null;
    

    return memberName  || 'Member name not found';

  }

  function getMemberCommunity() {
    
    const memberCommunityId = (metadata?.entities as any)?.member?.find( (member: any) => member?.memberId === params.id )?.communityId || null
    

    const foundCommunity = communities.find((community: any) => (community?.communityId === memberCommunityId ));

    if ( !foundCommunity) {
      return communities?.[0]?.communityId;
    } else {
      return foundCommunity;
    }

  }

  function getMemberCommunityName() {
    const foundCommunity = getMemberCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  function triggerClick() {
    setOpen( !open )
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>

       
        
        <div className={Navigation}>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity( 'community', getMemberCommunity()?.communityId  )} >{ getMemberCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <label className={ `${styles.clickable}`} onClick={() => navigateToMember( 'member', params?.id as string )} >{ getMemberName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          {selectValue &&
            <Select
            className='site-navigation-select'
              id="site-navigation-select"
              open={open}
            onOpen={() => setOpen( true )}     
            onClose={() => setOpen( false )}     
            MenuProps={{
              className: 'site-navigation-select-menu'
            }}
            value={ selectValue }
            IconComponent={() => <div onClick={() => triggerClick()}><IconWrapper className={'site-navigation-select-icon'} iconName='chevron-down' /></div>}
          >
            <option
              disabled={ pathSegment === 'member-energy' }
              onClick={() => {
                setSelectValue('member-energy');
                navigateToMember( 'member-energy', params?.id as string )
              }} value="member-energy">Energy</option>
            <option
              disabled={ pathSegment === 'member-economics' }
              onClick={() => {
                setSelectValue('member-economics');
                navigateToMember( 'member-economics', params?.id as string )
            }} value="member-economics">Economics</option>
          </Select>
          }

          
      {/* {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          } */}
      
    </div>


      </div>

      <div className={Header__Web__Center}>
          <HiveCalendar />
        </div>

      <div className={Header__Web__Right}>
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(MemberDataVisNavigation);
