import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './header-web.module.scss';
import { IconWrapper } from '@flexo/general';
import {  BreadCrumbsDropDown, ProfileButton, Search, navigateToCommunity, navigateToMember } from '@flexo/atoms';

import './header-web.module.scss';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
  Header__Web__Center
} = styles;

function SiteOverviewNavigation(props: any) {
  
  const params = useParams();
  const navigate = useNavigate()  
  const metadata = useSelector((state: any) => state.metadata);
  const { t } = useTranslation();
  
  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;

  const communities = (metadata?.entities as any)?.community || [];
  const sites = (metadata?.entities as any)?.site || [];

  function getSiteCommunity() {

    const site = sites.find((site: any) => site?.siteId === params.id );
    const foundCommunity = communities?.find((community: any) => community?.communityId === site?.communityId ) || null;
    
    if ( !foundCommunity) {
      return communities?.[0];
    } else {
      return foundCommunity;
    }

  }

  function getSiteCommunityName() {
    const foundCommunity = getSiteCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  function getSiteMembers() {
    const membersList = ([
      ...((metadata?.sortedEntities as any)?.communities || []).reduce((prev: any, next: any) => ([...prev, ...(next?.members || [])]), []),
      ...((metadata as any)?.unassignedMembers || [])
    ])
      ?.filter((member: any) => member?.sites?.some((site: any) => site?.siteId === params.id));
    
    return membersList[0];
    
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>
        
        <div className={Navigation}>
          {/* <label className={`${Navigation__BreadCrumb} ${styles.clickable}`} onClick={() => goToCommunities()}>{t(`widgets.header.communities`)}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span> */}
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity( 'community', getSiteCommunity()?.communityId  ) } >{ getSiteCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <label className={`${Navigation__BreadCrumb} ${styles.clickable}`} onClick={() => navigateToMember( 'member', getSiteMembers()?.memberId ) }>{ getSiteMembers()?.memberFirstName } { getSiteMembers()?.memberLastName }</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          {/* <strong>{getSiteName()}</strong> */}
          {/* <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span> */}
          {/* <Select
            className='site-navigation-select'
            id="site-navigation-select"
            MenuProps={{
              className: 'site-navigation-select-menu'
            }}
            // open={true}
            defaultValue={metaDataApiStore?.entityType || null}
            
          >
            <option disabled={ metaDataApiStore?.entityType === 'site-energy' } onClick={() => {
              navigateToSite( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'energy' )
            }} value="site-energy">Energy</option>
            <option disabled={ metaDataApiStore?.entityType === 'site-economics' } onClick={() => {
              navigateToSite( metaDataApiStore?.selectedEntity || metaDataApiStore?.selectedEntityId, setMetaDataApiStore, setModulesStore, 'economics' )
            }} value="site-economics">Economics</option>
          </Select> */}
          {/* <span>{ (metaDataApiStore?.selectedEntity as any)?.type || 'undefined type' }</span> */}

          
      {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          }
      
    </div>


      </div>

      {/* <div className={Header__Web__Center}>
          <Calendar />
        </div> */}

      <div className={Header__Web__Right}>
        {
          download && <>
            <IconWrapper iconName={'chevron-encircled'} />
          </>
        }
        {
          searchBar &&
          <Search />
        }
        {
       profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(SiteOverviewNavigation);
