import { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { IconWrapperProps } from '../libs.interfaces';

export function IconWrapper(props: IconWrapperProps) {
  const { iconName, className = '', style = {} } = props;
  const [svgContent, setSvgContent] = useState('');
  
  useEffect(() => {

    if (!iconName || iconName === '') { return }

    // Use the original, simple path that already works
    fetch(`icons/${iconName}.svg`)
      .then(response => response.text())
      .then(svgText => {
        setSvgContent(svgText);
      })
      .catch(error => {
        console.error(`Failed to load icon ${iconName}:`, error);
      });
  }, [iconName]);
  
  return (
    <div className={className} style={style}>
      {svgContent ? parse(svgContent) : ''}
    </div>
  );
}

export default IconWrapper;
