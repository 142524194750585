import {
  Member,
  Site,
  Community,
} from '@flexo/providers';
import {
  Autocomplete,
  Box,
  List,
  ListItem,
  Popper,
  TextField,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import styles from './search.module.scss';
import { IconWrapper } from '@flexo/general';
import { navigateToMember } from '../resolvers/member-resolver';
import { navigateToSite } from '../resolvers/site-overview-resolver';
import { useOnClickOutside } from '@flexo/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { METADATA } from '@flexo/reducers';
import { navigateToCommunity } from '../resolvers/community-resolver';

function highlightedText({
  text,
  searchText,
}: {
  text: string;
  searchText: string;
}) {
  if (!searchText) {
    return <>{text}</>;
  }

  const lowercasedText = text.toLowerCase();
  const lowercasedSearchText = searchText.toLowerCase();

  const startIndex = lowercasedText.indexOf(lowercasedSearchText);

  if (startIndex === -1) {
    return <span>{text}</span>;
  }

  const endIndex = startIndex + searchText.length;

  return (
    <>
      {text.slice(0, startIndex)}
      <label>{text.slice(startIndex, endIndex)}</label>
      {text.slice(endIndex)}
    </>
  );
}

function getMemberName(member: any) {
  return `${member.memberFirstName} ${member.memberLastName}`;
}

function filterData(structuredData: any | null, searchText: string) {
  if (!structuredData) {
    return {
      filteredStructuredData: null,
    };
  }

  const lowercasedSearchText = searchText.toLowerCase();

  const filteredCommunities = structuredData.communities
    .map((community) => {
      const filteredMembers = community.members
        .map((member) => {
          const filteredSites = member.sites.filter(
            (site) =>
              site.siteName.toLowerCase().includes(lowercasedSearchText) ||
              site.pod.toLowerCase().includes(lowercasedSearchText) ||
              site.siteAddress.toLowerCase().includes(lowercasedSearchText) ||
              site.memberId.toLowerCase().includes(lowercasedSearchText)
          );

          if (
            getMemberName(member)
              .toLowerCase()
              .includes(lowercasedSearchText) ||
            member.memberExternalId
              .toLowerCase()
              .includes(lowercasedSearchText) ||
            member.memberId.toLowerCase().includes(lowercasedSearchText) ||
            member.email.toLowerCase().includes(lowercasedSearchText) ||
            filteredSites.length > 0
          ) {
            return { ...member, sites: filteredSites };
          }

          return null;
        })
        .filter((member) => member !== null);

      if (
        community.communityName.toLowerCase().includes(lowercasedSearchText) ||
        community.communityCode.toLowerCase().includes(lowercasedSearchText) ||
        community.communityExternalId
          .toLowerCase()
          .includes(lowercasedSearchText) ||
        filteredMembers.length > 0
      ) {
        return { ...community, members: filteredMembers };
      }

      return null;
    })
    .filter((community) => community !== null);

  const filteredMembers = structuredData.unassignedMembers.filter(
    (member) =>
      getMemberName(member).toLowerCase().includes(lowercasedSearchText) ||
      member.meberExternalId.toLowerCase().includes(lowercasedSearchText) ||
      member.memberId.toLowerCase().includes(lowercasedSearchText) ||
      member.email.toLowerCase().includes(lowercasedSearchText)
  );

  const filteredSites = structuredData.unassignedSites.filter(
    (site) =>
      site.communityId.toLowerCase().includes(lowercasedSearchText) ||
      site.siteType[0].flexoSiteType
        .toLowerCase()
        .includes(lowercasedSearchText) ||
      site.siteName.toLowerCase().includes(lowercasedSearchText) ||
      site.pod.toLowerCase().includes(lowercasedSearchText) ||
      site.siteAddress.toLowerCase().includes(lowercasedSearchText) ||
      site.memberId.toLowerCase().includes(lowercasedSearchText)
  );

  const filteredEVs = structuredData.unassignedEVs.filter((ev) =>
    ev.evId.toLowerCase().includes(lowercasedSearchText)
  );

  const filteredWallboxes = structuredData.unassignedWallboxes.filter(
    (wallbox) => wallbox.wallboxId.toLowerCase().includes(lowercasedSearchText)
  );

  return {
    filteredStructuredData: {
      communities: filteredCommunities.sort(( a: any, b: any ) => { return a.communityName.localeCompare(b.communityName)} ),
      unassignedMembers: filteredMembers,
      unassignedSites: filteredSites,
      unassignedEVs: filteredEVs,
      unassignedWallboxes: filteredWallboxes,
    },
  };
}

function filterCommunities(
  structuredData: any | null,
  communitiesData: any[],
  searchText: string
) {
  const lowercasedSearchText = searchText.toLowerCase();

  const _filteredStructeredData = filterData(structuredData, searchText);

  const _filteredCommunities = communitiesData.filter((community: any) =>
    (_filteredStructeredData as any)?.filteredStructuredData?.communities.some(
      (_filterCom: any) => _filterCom.communityId === community.communityId
    )
  );

  return {
    ..._filteredStructeredData,
    filteredCommunities: _filteredCommunities.sort(( a: any, b: any ) => { return a.communityName.localeCompare(b.communityName)} ) as any,
  };
}

function filterMembers(
  structuredData: any | null,
  membersData: any,
  searchText: string
) {
  const lowercasedSearchText = searchText.toLowerCase();

  const filterTimeMembers = (members: any) => {
    if (!members || members.length === 0 || Object.keys(members).length === 0) {
      return [];
    }

    return members
      .map((member) => {
        const filteredSites = member.sites.filter(
          (site) =>
            site.siteName.toLowerCase().includes(lowercasedSearchText) ||
            site.siteId.toLowerCase().includes(lowercasedSearchText) ||
            site.siteExternalId.toLowerCase().includes(lowercasedSearchText) ||
            site.pod.toLowerCase().includes(lowercasedSearchText) ||
            site.address.toLowerCase().includes(lowercasedSearchText)
        );

        if (
          getMemberName(member).toLowerCase().includes(lowercasedSearchText) ||
          filteredSites.length > 0
        ) {
          return { ...member };
        }

        return null;
      })
      .filter((member) => member !== null) as Member[];
  };

  return {
    ...filterData(structuredData, searchText),
    filteredMembers: {
      current: filterTimeMembers(membersData?.current || {}),
      future: filterTimeMembers(membersData?.future || {}),
      previous: filterTimeMembers(membersData?.previous || {}),
    },
  };
}

function filterSites(
  structuredData: any | null,
  sitesData: any[],
  searchText: string
) {
  const lowercasedSearchText = searchText.toLowerCase();

  const filteredSites = (sitesData || []).filter(
    (site) =>
      (site?.siteName || site?.name || '')
        .toLowerCase()
        .includes(lowercasedSearchText) ||
      site?.pod.toLowerCase().includes(lowercasedSearchText) ||
      site.externalId.toLowerCase().includes(lowercasedSearchText) ||
      (site?.id || site?.flexoSiteId || '')
        .toLowerCase()
        .includes(lowercasedSearchText)
  );

  return {
    ...filterData(structuredData, searchText),
    filteredSites: filteredSites as any,
  };
}

const debounce = (fn, delay = 100) => {

  const handler = setTimeout(() => {
    fn();
  }, delay);

  return () => {
    clearTimeout(handler);
  };
};

function flattenStructuredData(structuredData: any): any[] {
  if (!structuredData) {
    return [];
  }
  const flatArray: any[] = [];

  flatArray.push(...structuredData.communities);
  flatArray.push(...structuredData.unassignedMembers);
  flatArray.push(...structuredData.unassignedSites);
  flatArray.push(...structuredData.unassignedEVs);
  flatArray.push(...structuredData.unassignedWallboxes);

  return flatArray;
}

export function SiteListItem(option: any, props: any) {
  
  const location = useLocation();
  const params = useParams();
  const pathSegment = location.pathname.split('/')[1]; 
  const metadata = useSelector((state: any) => state.metadata);

  const isActive =
    option?.isActive ||
    option?.option?.siteId === params.id
  const firstItem = option?.firstItem === true;

  return (
    <ListItem
      key={`${uuidv4()}`}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      }`}
    >
      <span
        key={`${uuidv4()}`}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={() =>
          ( option.toggleInput(),
          navigateToSite(
            pathSegment?.includes('energy') ? 'site-energy' : pathSegment?.includes('economics') ? 'site-economics' : 'site',
            option?.option?.siteId,
          ) )
        }
      >
        {/* {highlightedText({
          text: option?.option?.pod || option?.option?.siteName,
          searchText: option.searchText,
        })} */}
        {option?.option?.pod || option?.option?.siteName}
        <br />
        <small>{ option?.option?.siteName }</small>
      </span>
    </ListItem>
  );
}

export function MemberListItem(option: any, props: any) {
  
  const metadata = useSelector((state: any) => state.metadata);
  const location = useLocation();
  const params = useParams();
  const pathSegment = location.pathname.split('/')[1]; 

  const isActive = option?.isActive || option?.option?.memberId === params.id

  const firstItem = option?.firstItem === true;

  return (
    <ListItem
      key={`${uuidv4()}`}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      }`}
    >
      <span
        key={`${uuidv4()}`}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={() =>
          ( option.toggleInput(),
          navigateToMember(
            pathSegment?.includes('energy') ? 'member-energy' : pathSegment?.includes('economics') ? 'member-economics' : 'member',
            option?.option?.memberId,
          )
        )
        }
      >
        {/* {highlightedText({
          text: `${option?.option.memberFirstName} ${option?.option.memberLastName}`,
          searchText: option.searchText,
        })} */}
        { `${option?.option.memberFirstName} ${option?.option.memberLastName}` }<br />
        <small>{ option?.option?.memberExternalId }</small>
      </span>

      {option?.option?.sites && (
        <List  className={`${styles.Autocomplete__List}`}>
          {([ ...option?.option?.sites ] )?.sort((a: any, b: any) => {
            return ( `${a.pod}` ).localeCompare( `${b.pod}` );
          }).map((site: any) => (
            <SiteListItem
              key={uuidv4()}
              option={site}
              props={props}
              padding={(option?.padding || 24) + 16}
              searchText={option.searchText}
              isActive={isActive}
              toggleInput={option.toggleInput}
            />
          ))}
        </List>
      )}
    </ListItem>
  );
}

export function CommunitiesListItem(option: any, props: any) {
  
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  const pathSegment = location.pathname.split('/')[1]; 
  const metadata = useSelector((state: any) => state.metadata);

  const isActive = option?.option?.communityId === params.id && pathSegment?.includes( 'community' );

  const firstItem = option?.firstItem === true;

  return (
    <ListItem
      key={`${uuidv4()}`}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      } `}
      {...props}
      value={option.value}
    >
      <span
        key={`${uuidv4()}`}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={() =>
        (option.toggleInput(),
          navigateToCommunity(
          `${pathSegment?.includes('energy')
            ? 'community-energy'
            : pathSegment?.includes('economics')
              ? 'community-economics'
                : 'community'}`,
                option?.option?.communityId
          )
        )}
      >
        {/* {highlightedText({
          text: option?.option?.communityName,
          searchText: option.searchText,
        })} */}
        {option?.option?.communityName}
        <br />
        <small>{ option?.option?.communityExternalId }</small>
      </span>
      {option?.option?.members && (
        <List key={`${uuidv4()}`} className={`${styles.Autocomplete__List}`}>
          {option?.option?.members.sort((a: any, b: any) => {
            return ( `${a.memberFirstName} ${a.memberLatName}` ).localeCompare( `${b.memberFirstName} ${b.memberLatName}` );
          })
            .map((member: any) => (
            <MemberListItem
              key={uuidv4()}
              option={member}
              props={props}
              padding={(option?.padding || 24) + 16}
              searchText={option.searchText}
              isActive={isActive}
              toggleInput={option.toggleInput}
            />
          ))}
        </List>
      )}
    </ListItem>
  );
}

export function AutoCompleteComponent(props: any) {
  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);
  
  const [showInput, setShowInput] = useState(false);
  const magnifierEl = useRef<any>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchText, setSearchText] = useState('');
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)
  
  let LOADING_STATE = kpi?.KpiDataSiteAsyncStatus;

  if (metadata?.entityType?.includes('community')) {
        LOADING_STATE = kpi?.KpiDataCommunityAsyncStatus
  }

  if (metadata?.entityType?.includes('member')) {
        LOADING_STATE = kpi?.KpiDataMemberAsyncStatus
  }

  function isChildOf(event) {
    const parentDiv = document.getElementsByClassName(
      'MuiAutocomplete-popper'
    )[0];
    let targetElement = event.target; // L'elemento che è stato cliccato

    // Itera attraverso i genitori dell'elemento target fino a raggiungere il body
    while (targetElement !== document.body) {
      if (targetElement === parentDiv) {
        return true; // Il target è figlio del parentDiv
      }
      targetElement = targetElement.parentNode; // Vai al genitore dell'elemento target
    }

    return false; // Il target non è figlio del parentDiv
  }

  useOnClickOutside(magnifierEl, (event) => {
      
    const classContent = (event?.target as any)?.className && (event?.target as any)?.className as string || '';

    try {
      if (
        (event?.target as any)?.attributes?.[2]?.textContent === 'autocomplete' ||
        classContent?.includes('MuiAutocomplete') ||
        (isChildOf(event))
      ) {
        return;
      } else {
        setShowInput(false);
      }
    } catch (err: any) {
      console.warn(err);
    }

      
    }
  )

  

  function toggleInput() {
    if (showInput === true) {
      // inputRef.current?.blur();
      setSearchText('');

      // globalSetFiltersStore({
      //   type: FiltersStoreTypes.ResetAllFilters,
      // });
    } else {
      // inputRef.current?.focus();
      setTimeout(() => {
        const _input = document.getElementById('autocomplete-search');
        _input?.focus();
      }, 100)
      
    }

    setSearchText('');
    setShowInput(!showInput);
    setIsAutocompleteOpen( !showInput )

  }

  function sortByEntityTypes(entities: any) {

    let optionType = '';
    if (entities?.[0]?.memberId) {
      optionType = 'member';
    }
    if (entities?.[0]?.communityName) {
      optionType = 'community';
    }
    if (entities?.[0]?.siteName) {
      optionType = 'site';
    }

    try {
      return entities.sort((a: any, b: any) => {
        switch (optionType) {
          case 'community':
            return a.communityName.localeCompare(b.communityName);
            break;
          case 'member':
            return (`${a.memberFirstName} ${a.memberLatName}`).localeCompare(`${b.memberFirstName} ${b.memberLatName}`);
            break;
          case 'site':
            return (`${a.pod}`).localeCompare(`${b.pod}`);
            break;
          default:
            return a.id.localeCompare(b.id);
        }
      })
    } catch (err: any) {
      console.warn(err);
      return entities;
    }

    

  }

  return (
    <div
      className={`${styles.Search__Container} ${
        showInput
          ? styles.Search__Container__Active
          : styles.Search__Container__InActive
      }`}
    >
      <div
        className={`${styles.Autocomplete__Wrapper} ${
          showInput && styles.Autocomplete__Wrapper__Active
        }`}
      >
        {showInput &&
        <Autocomplete
          // disablePortal
          id="autocomplete-search"
          open={true}
          options={
            sortByEntityTypes(flattenStructuredData(metadata?.sortedEntities || null) ||
            [])
          }
          getOptionLabel={(option: any) =>
            option?.communityName ||
            option?.name ||
            option?.siteName ||
            option?.evId ||
            option?.wallboxId || ''
          }
          filterOptions={(options, { inputValue }) => {
            setSearchText(inputValue);
            return props.handleFilterOptions(options, { inputValue });
          }}
          clearOnBlur={false}
          sx={{ width: 260, marginTop: 1 }}
          className={`${styles.Search}`}
          PopperComponent={({ children, ...props }) => (
            <Popper
              {...props}
              style={{ width: 315, borderRadius: 8, paddingTop: 16, cursor: 'pointer' }}
              placement="bottom-start"
              id="search-popper"
            >
              {children}
            </Popper>
          )}
          noOptionsText={
            <span className={`${styles.Autocomplete__List__Item}`}>
              {t('widgets.search.no_results')}
            </span>
          }
          renderOption={(props, option, { selected }) => {
            let optionType = '';
            if (option?.memberId) {
              optionType = 'member';
            }
            if (option?.communityName) {
              optionType = 'community';
            }
            if (option?.siteName) {
              optionType = 'site';
            }

            switch (optionType) {
              case 'community':
                return (
                  <CommunitiesListItem
                    key={option.communityId}
                    option={option}
                    props={props}
                    firstItem={true}
                    searchText={searchText}
                    toggleInput={toggleInput}
                  />
                );
                break;
              case 'member':
                return (
                  <MemberListItem
                    key={option.memberId}
                    option={option}
                    props={props}
                    firstItem={true}
                    searchText={searchText}
                    toggleInput={toggleInput}
                  />
                );
                break;
              case 'site':
                return (
                  <SiteListItem
                    key={option.siteId}
                    option={option}
                    props={props}
                    firstItem={true}
                    searchText={searchText}
                    toggleInput={toggleInput}
                  />
                );
                break;
              default:
                return (
                  <ListItem {...props} value={option.value}>
                    <span> {option?.name}</span>
                  </ListItem>
                );
            }
          }}
          onChange={(_e, v) =>
            props.handleFilterOptions(props.data, {
              inputValue: (_e.target as any)?.outerText || '',
            })
          }
          renderInput={(params) => (
            <TextField
              inputRef={inputRef}
              style={{ marginTop: -8 }}
              focused={showInput}
              {...params}
              id="autocomplete-search-input"
              className={`${styles.Search__Input__Text}`}
              label={t('widgets.search.common_placeholder') as string}
              placeholder={t('widgets.search.common_placeholder') as string}
            ></TextField>
          )}
        />}
      </div>
      <div
        className={`${styles.Search__Icon__Wrapper}`}
        onClick={() => {
          toggleInput();
        }}
        ref={magnifierEl}
      >
        <IconWrapper
          iconName={showInput === false ? 'search' : 'cross'}
          className={`${styles.Search__Magnifier}`}
        />
      </div>
    </div>
  );
}

export function CommunitiesAutocomplete(props: any) {
  
  const dispatch = useDispatch();
  const metadata = useSelector((state: any) => state.metadata);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredCommunities } = filterCommunities(
      metadata?.sortedEntities,
      (metadata as any)?.communities,
      inputValue
    ) as any;

    if (JSON.stringify(filteredCommunities) !== JSON.stringify(metadata?.filteredCommunities)) {
      if (
        (metadata as any)?.communities?.length >=
        (filteredCommunities?.length || 0)
      ) {
        debounce(() =>
        dispatch( METADATA.SetFilteredCommunities( filteredCommunities ) )
        );
      }

    }


     

    return flattenStructuredData(filteredStructuredData);
  };

  useEffect(() => {
    return () => {
      if (loaded) {
        dispatch( METADATA.ResetFilter( 'filteredCommunities' as any ) )
      } else {
        setLoaded(true);
      }
    };
  }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={metadata?.communities || []}
    />
  );
}

export function SitesAutocomplete(props: any) {
  
  const dispatch = useDispatch();
  const metadata = useSelector((state: any) => state.metadata);

  const [loaded, setLoaded] = useState(false);

  
  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredSites } = filterSitesMobile(
      (metadata as any)?.entities.sites,
      inputValue
    ) as any;
    return filteredSites;
  };

  useEffect(() => {
    return () => {
      if (loaded) {
        dispatch( METADATA.ResetFilter( 'filteredSites' as any ) )
      } else {
        setLoaded(true);
      }
    };
  }, []);

  return (
    <AutoCompleteComponentMobile
      handleFilterOptions={handleFilterOptions}
      data={metadata?.entities || []}
    />
  );
}

export function CommunityAutocomplete(props: any) {
  
  const dispatch = useDispatch();
  const params = useParams();
  const metadata = useSelector((state: any) => state.metadata);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredMembers } = filterMembers(
      metadata?.sortedEntities,
      metadata.members,
      inputValue
    ) as any;



    if ( props?.context === 'community' && JSON.stringify(filteredMembers) !== JSON.stringify(metadata?.filteredMembers)) {

      const filteredMembersLength = Object.values(filteredMembers || {} ).reduce((acc, val: any) => acc + val.length, 0);
      const community = metadata?.selectedEntity?.communityId || params.id;
      const communityMembers = (metadata?.entities?.member || [])?.filter((member: any) => member?.communityId === community) || [];

      if ( communityMembers?.length >= ( filteredMembersLength || 0) ) {
        debounce(() =>
        dispatch( METADATA.SetFilteredMembers( filteredMembers ) )
        );
      }
    }
      
    // }

    return flattenStructuredData(filteredStructuredData);
  };

  useEffect(() => {
    return () => {
      if (loaded) {
        dispatch( METADATA.ResetFilter( 'filteredMembers' as any ) )
      } else {
        setLoaded(true);
      }
    };
  }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={(metadata?.entities as any)?.members || {}}
    />
  );
}

export function MemberAutocomplete(props: any) {
  
  const metadata = useSelector((state: any) => state.metadata);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredMembers } = filterMembers(
      metadata?.sortedEntities,
      (metadata?.entities as any)?.members,
      inputValue
    ) as any;

    // if (inputValue !== '') {

    //   debounce(() =>
    //     globalSetFiltersStore({
    //       type: FiltersStoreTypes.SetFilteredMembers,
    //       payload: {
    //         value: filteredMembers
    //       }
    //     })
    //   )

    // } else {

    //   if ( Object.values( filterMembers ).reduce((acc, val) => acc + val.length, 0) === 0) {

    //     debounce(() =>
    //       globalSetFiltersStore({
    //         type: FiltersStoreTypes.SetFilteredMembers,
    //         payload: {
    //           value: dataStore?.data?.members
    //         }
    //       })
    //     )

    //   }
    // }

    return flattenStructuredData(filteredStructuredData);
  };

  // useEffect(() => {
  //   return () => {
  //     if (loaded) {
  //       globalSetFiltersStore({
  //         type: FiltersStoreTypes.ResetFilter,
  //         payload: {
  //           value: 'filteredPayments',
  //         },
  //       });
  //     } else {
  //       setLoaded(true);
  //     }
  //   };
  // }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={metadata?.members || {}}
    />
  );
}

export function SiteAutocomplete(props: any) {
  
  const metadata = useSelector((state: any) => state.metadata);
  const [loaded, setLoaded] = useState(false);

  const handleFilterOptions = (options, { inputValue }) => {
    const { filteredStructuredData, filteredSites } = filterSites(
      metadata?.sortedEntities,
      (metadata?.entities as any)?.site,
      inputValue
    ) as any;

    // if (inputValue !== '') {

    //   debounce(() =>
    //     globalSetFiltersStore({
    //       type: FiltersStoreTypes.SetFilteredMembers,
    //       payload: {
    //         value: filteredMembers
    //       }
    //     })
    //   )

    // } else {

    //   if ( Object.values( filterMembers ).reduce((acc, val) => acc + val.length, 0) === 0) {

    //     debounce(() =>
    //       globalSetFiltersStore({
    //         type: FiltersStoreTypes.SetFilteredMembers,
    //         payload: {
    //           value: dataStore?.data?.members
    //         }
    //       })
    //     )

    //   }
    // }

    return flattenStructuredData(filteredStructuredData);
  };

  // useEffect(() => {
  //   return () => {
  //     if (loaded) {
  //       globalSetFiltersStore({
  //         type: FiltersStoreTypes.ResetFilter,
  //         payload: {
  //           value: 'filteredPayments',
  //         },
  //       });
  //     } else {
  //       setLoaded(true);
  //     }
  //   };
  // }, []);

  return (
    <AutoCompleteComponent
      handleFilterOptions={handleFilterOptions}
      data={metadata?.members || {}}
    />
  );
}

function filterSitesMobile(sitesData: any[], searchText: string) {
  const lowercasedSearchText = searchText.toLowerCase();

  const filteredSites = (sitesData || []).filter((site) =>
    (site?.siteName || site?.name || '')
      .toLowerCase()
      .includes(lowercasedSearchText)
  );

  return {
    filteredSites: filteredSites as any,
  };
}
export function MobileSites(option: any, props: any) {
  
  const metadata = useSelector((state: any) => state.metadata);

  const isActive =
    option?.option?.siteId ===
    (metadata.selectedEntity as Site)?.siteId;
  const firstItem = option?.firstItem === true;

  const handleClick = () => {
    // Navigate to the selected site and close the dropdown
    navigateToSite( 'household', option?.option.siteId );
  };

  return (
    <ListItem
      key={option.option.siteId}
      className={`${styles.Autocomplete__List__Item} ${
        firstItem && styles.Autocomplete__List__Item__First
      }`}
    >
      <span
        key={option.option.siteId}
        style={{
          paddingLeft: option?.padding || 24,
          cursor: 'pointer',
          color: isActive ? 'var(--hive-primary-500)' : 'inherit',
        }}
        onClick={handleClick} // Ensure handleClick closes the dropdown
      >
        {/* {highlightedText({
          text: option?.option?.siteName,
          searchText: option.searchText,
        })} */}
        { option?.option?.siteName }
      </span>
    </ListItem>
  );
}

// Autocomplete component that handles dropdown
export function AutoCompleteComponentMobile(props: any) {

  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);

  const [searchText, setSearchText] = useState('');

  return (
    <Autocomplete
      disablePortal
      id="autocomplete"
      options={(metadata?.entities as any).sites || []}
      getOptionLabel={(option: any) => option?.siteName || ''}
      filterOptions={(options, { inputValue }) => {
        setSearchText(inputValue);
        return props.handleFilterOptions(options, { inputValue });
      }}
      // sx={{ width: 260 }}
      className={`${styles.SearchMobile}`}
      PopperComponent={({ children, ...props }) => (
        <Popper
          {...props}
          style={{
            width: '90%',
            borderRadius: 8,
            padding: 16,
          }}
          placement="bottom-start"
        >
          {children}
        </Popper>
      )}
      noOptionsText={
        <span className={`${styles.Autocomplete__List__Item}`}>
          {t('no sites found')}
        </span>
      }
      renderOption={(props, option, { selected }) => {
        if (option?.siteName) {
          return <MobileSites option={option} searchText={searchText} />;
        }
        return (
          <ListItem {...props} value={option.value}>
            <span> {option?.name}</span>
          </ListItem>
        );
      }}
      onChange={(_e, value) => {
        props.handleFilterOptions(props.data, {
          inputValue: value?.siteName || '',
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {(metadata.selectedEntity as any)?.siteName ||
                (metadata?.entities as any).sites?.[0]?.siteName}{' '}
              <IconWrapper iconName="chevron-down" />
            </Box>
          }
          placeholder={''}
          InputProps={{
            ...params.InputProps,
            sx: {
              color: 'green', // Style input text color
              fontFamily: 'Source Sans Pro', // Correct property for font family
              fontWeight: 600, // camelCase for font-weight
              fontSize: '24px !important', // camelCase for font-size
              lineHeight: '32px', // camelCase for line-height
              '&::placeholder': {
                // This correctly targets the placeholder styling
                color: 'green',
                opacity: 1, // Ensure the placeholder has the same opacity as the input text
              },
            },
          }}
          InputLabelProps={{
            sx: {
              color: 'green', // Style label text color
              fontFamily: 'Source Sans Pro', // Correct property for font family
              fontWeight: 600, // camelCase for font-weight
              fontSize: '24px !important', // camelCase for font-size
              lineHeight: '32px', // camelCase for line-height
            },
          }}
        />
      )}
    />
  );
}
