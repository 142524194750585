import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@flexo/providers';

import styles from './horizontal-chart.module.scss';
import HorizontalChart from './horizontal-chart';
import { navigateToMember, navigateToSite, RankingSkeleton, Shimmer } from '@flexo/atoms';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function HorizontalChartWrapper(props: any) {

  const { t } = useTranslation();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 
  const { ThemeStore } = useContext(ThemeContext)
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);

  const chartWrapperRef = React.useRef<HTMLDivElement>(null);
  
  
  const [activeKeys, setActiveKeys] = useState<string[]>(props.graphKeys);
  const [data, setData] = useState<any>([]);
  const [rendered, setRendered] = useState<any>(false);
  
  let rawData = kpi?.communityRanking || null;
  let LOADING_STATE = kpi?.KpiDataCommunityRankingAsyncStatus
  
  if (pathSegment?.includes('member')) {
    rawData = kpi?.memberRanking || null
    LOADING_STATE = kpi?.KpiDataMemberRankingAsyncStatus
  }

  function handleNavigation(line: any) {

    if (pathSegment?.includes('member')) {
      navigateToMember(
        props?.context === 'energy' ? 'member-energy' : props?.context === 'economics' ? 'member-economics' : 'member',
        line?.memberId,
      )
    } else {
      navigateToSite(
        props?.context === 'energy' ? 'site-energy' : props?.context === 'economics' ? 'site-economics' : 'site',
        line?.siteId,
      )
    }

  }

  const getItemsList = () => {

    const sitesList = ((metadata?.entities as any)?.site) || [];

    if (props?.context === 'energy') {

      if (
        rawData?.consumptionRanking?.[0]?.site &&
        rawData?.productionRanking?.[0]?.site
      ) {
        const sites = rawData?.consumptionRanking?.[0]?.site
          .map((_site: any) => ({
            ..._site,
            ...sitesList.find((entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
          }))
          .sort((a, b) => a?.ranking > b?.ranking ? 1 : -1)
        .filter(  (_, i) => i < 5)

        return sites.map((site: any) => ([
          site,
          rawData?.productionRanking?.[0]?.site
            .find((_site: any) => _site.siteId === site?.siteId)
        ]))
      }

    } else if (props?.context === 'economics') {

      return rawData?.economicsRanking?.[0]?.site
        .map((_site: any) => ({
          ..._site,
          ...sitesList.find((entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
        })).sort((a, b) => a?.ranking > b?.ranking ? 1 : -1)

    }

    return []
  }

  function getKpiValue(item: any) {
    const kpi = item?.kpi?.find((datum: any) => props?.rankingKpi === datum.name);


    const value = kpi?.value || 0;
    const unit = kpi?.unit || '';
    return props?.context === 'economics' ? `${unit} ${value}` : `${value} ${unit}`;
  }


  // const handleButtonClick = (key: string) => {
  //   if (props.combinedKeys?.includes(key)) {
  //     // Se fa parte delle combinedKeys, attiva/disattiva entrambe
  //     const combinedKeyIndex = props.combinedKeys.indexOf(key);
  //     const combinedKey = props.combinedKeys[combinedKeyIndex + 1];

  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key && k !== combinedKey)
  //         : [...prev, key, combinedKey]
  //     );
  //   } else {
  //     // Se non fa parte delle combinedKeys, attiva/disattiva normalmente
  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key)
  //         : [...prev, key]
  //     );
  //   }
  // };

  const handleButtonClick = (key: string) => {

    if (activeKeys.length === 1 && activeKeys.includes(key)) {
      return;
    }

    if (activeKeys.length === props?.combinedKeys?.length && activeKeys?.includes(key) && props?.combinedKeys?.includes(key)) {
      return;
    }

    if (props.combinedKeys.includes(key)) {
      // Trova tutte le chiavi nel gruppo combinato
      const combinedKeyGroup = props.combinedKeys;

      // Verifica se tutte le chiavi del gruppo sono attive
      const areAllKeysActive = combinedKeyGroup.every(k => activeKeys.includes(k));

      setActiveKeys((prev) =>
        (areAllKeysActive
          ? prev.filter(k => !combinedKeyGroup.includes(k))
          : [...prev, ...combinedKeyGroup.filter(k => !prev.includes(k))])
          .filter(x => x)
      );
    } else {

      setActiveKeys((prev) =>
        (prev.includes(key)
          ? prev.filter((k) => k !== key)
          : [...prev, key])
          .filter(x => x)
      );
    }
  };

  useEffect(() => {

    if (!rawData) { setData([]);  return }

    const _data = getItemsList();

    setData(_data);

  }, [rawData])

  useEffect(() => {

    if (chartWrapperRef.current) {

      const _layouts = props?.layouts?.lg || [];
      const newGridHeight = Math.ceil(chartWrapperRef.current?.getBoundingClientRect().height / 8) + 6;

      const previousHeight = _layouts?.find((widget: any) => widget.i === props?.id)?.h || 0;

      // if (newGridHeight > 53) {

        const newConfig = {
          lg: _layouts?.map((widget: any) => {

            if (widget.i === props?.id) {
              return {
                ...widget,
                h: newGridHeight
              }
            }
            return widget;
          })
        }

        
      if (rendered === true) {
        props?.setStoredLayouts(newConfig)
      }

      // }


    }
  }, [chartWrapperRef.current?.getBoundingClientRect().height, rendered]);




  return (
    <div id="HorizontalChart__Wrapper" className={styles.HorizontalChart__Wrapper} ref={chartWrapperRef}>

      {
        LOADING_STATE === 'PENDING' && <Shimmer />
      }

      { 
        (LOADING_STATE === 'PENDING' && data?.length === 0)
          ?<RankingSkeleton title={props.title} context={props?.context} />
          : data?.map(datum => datum.ranking).every(rank => rank === null)
            ? <div>
            <div className={styles.HorizontalChart__Title}>
              {t(props.title)}

            </div>
            <div className={styles.HorizontalChart__Buttons__Wrapper} style={{ marginBottom: 10}}>
              {
                props.buttons.map((button: any, index: number) => {
                  return (
                    <div
                      key={`HorizontalChart__${index}__button__${button}`}
                      className={`
                        ${styles.HorizontalChart__Button}
                        ${props?.combinedKeys.includes(props.graphKeys[0][index])
                          ? styles.HorizontalChart__Button__Touch
                          : ''
                        }
                        `}
                    >
                      {t(button)}
                      <div className={styles.HorizontalChart__Button__Underline} style={{ backgroundColor: `${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}` }}></div>
                    </div>
                  )


                })
              }
            </div>
            <RankingSkeleton title={props.title} context={props?.context} hideTitle={true} />
      </div>
      : <div ref={ chartWrapperRef }>
            
            <div className={styles.HorizontalChart__Title}>
        {t(props.title)}

      </div>
      <div className={styles.HorizontalChart__Buttons__Wrapper}>
        {
          props.buttons.map((button: any, index: number) => {
            return (
              <div
                key={`HorizontalChart__${index}__button__${button}`}
                className={`
                    ${styles.HorizontalChart__Button}
                    ${props?.combinedKeys.includes(props.graphKeys[index])
                    ? styles.HorizontalChart__Button__Touch
                    : ''
                  }
                    ${!activeKeys?.includes(props.graphKeys[index])
                    ? styles.HorizontalChart__Button__Disabled
                    : ''
                  }
                  `}
                onClick={() => handleButtonClick(props.graphKeys[index])}
              >
                {t(button)}
                <div className={styles.HorizontalChart__Button__Underline} style={{ backgroundColor: `${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}` }}></div>
              </div>
            )


          })
        }
      </div>

            <div >
      {
        props?.context === 'energy'
          ? (data || []).map((line: any, lineIndex: number, lineArray: any) => {
            return line.map((item: any, index: number, itemArray: any) => {
              <div key={`${item?.pod}_${lineIndex}_${index}`} className={styles.HorizontalChart__Wrapper__Line}>

                <div className={styles.HorizontalChart__Wrapper__Line__Label} onClick={() => handleNavigation(line)}>
                  {
                    item?.pod
                  }
                </div>
                <div className={styles.HorizontalChart__Wrapper__Line__Rank}>
                  <div className={styles.HorizontalChart__Wrapper__Line__Rank__Value__Name__Wrapper}>
                    <p className={styles.HorizontalChart__Wrapper__Line__Rank__Value}>{getKpiValue(item)}</p>
                    <p className={styles.HorizontalChart__Wrapper__Line__Rank__Name}> {t(props?.rankingKpiName[lineIndex])}</p>

                  </div>
                  <HorizontalChart data={{
                    kpi: item?.kpi,
                    ...props,
                    activeKeys,
                    ...( ( (lineIndex === lineArray.length - 1) && (index === itemArray.length - 1)) ? { setRendered: setRendered } : {})

                  }} />
                </div>

              </div>
            })
          })

          : (data || []).map((item: any, index: number, itemArray: any) => {
            return (<div key={`${item?.pod}_${index}`} className={styles.HorizontalChart__Wrapper__Line}>
              <div className={styles.HorizontalChart__Wrapper__Line__Label} onClick={() => handleNavigation(item)}>
                {
                  item?.pod
                }

                {props?.context === 'economics' && <span className="font-flexo-light">&nbsp; {item?.siteName}</span>}
              </div>
              <div className={styles.HorizontalChart__Wrapper__Line__Rank}>
              <div className={styles.HorizontalChart__Wrapper__Line__Rank__Value__Name__Wrapper}>
                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Value}>{getKpiValue(item)}</p>
                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Name}> {t(props?.rankingKpiName)}</p>

                </div>
                <HorizontalChart data={{
                  kpi: item?.kpi,
                  ...props,
                  activeKeys,
                  ...( index === itemArray.length - 1 ? { setRendered: setRendered } : {})
                }} />
              </div>

            </div>)
          })
              }
              
              </div>
          
          </div>
      }


      

    </div>
  )

}