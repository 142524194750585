export default [
  {
    name: 'login',
    active: {
      default: true,
    },
    visible: {
      default: true,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'WebLogin',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'WebLogin',
          type: 'web-login',
          devID: 'daring-dragon',
          devPath: 'login->default->WebLogin',
        },
      ],
    },
  },
  {
    name: 'forgot-password',
    active: {
      default: true,
    },
    visible: {
      default: true,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'ForgotPassword',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'ForgotPassword',
          type: 'forgot-password',
          devID: 'daring-narwhal',
          devPath: 'forgot-password->default->ForgotPassword',
        },
      ],
    },
  },
  {
    name: 'register',
    active: {
      default: false,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'Register',
            x: 0,
            y: 12,
            w: 12,
            h: 8,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'Register',
          type: 'register',
          devID: 'glowing-espresso',
          devPath: 'register->default->Register',
        },
      ],
    },
  },
  {
    name: 'account-creation',
    active: {
      default: false,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'AccountCreation',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'AccountCreation',
          type: 'accountCreation-web',
          devID: 'mirthful-kangaroo',
          devPath: 'account-creation->default->AccountCreation',
        },
      ],
    },
  },
  {
    name: 'password-reset',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'PasswordReset',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'PasswordReset',
          type: 'PasswordReset-web',
          devID: 'zesty-marshmallow',
          devPath: 'password-reset->default->PasswordReset',
        },
      ],
    },
  },
  {
    name: 'register-by-invite',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: false,
    layouts: {
      default: {
        lg: [
          {
            i: 'RegisterByInvite',
            x: 4,
            y: 12,
            w: 4,
            h: 75,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          id: 'RegisterByInvite',
          type: 'RegisterByInvite',
          devID: 'daring-lollipop',
          devPath: 'register-by-invite->default->RegisterByInvite',
        },
      ],
    },
  },
  {
    name: 'communities',
    needsAuth: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: [
        'CommunitiesResolver',
        'CommunitiesOverviewResolver',
        'EntitiesResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communitiesTable',
            x: 0,
            y: 11,
            w: 12,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'communities',
            navigation: false,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'rambunctious-dragon',
          devPath: 'communities->default->headerWeb',
        },
        {
          type: 'communitiesTable',
          id: 'communitiesTable',
          devID: 'rambunctious-spoon',
          devPath: 'communities->default->communitiesTable',
        },
      ],
    },
  },
  {
    name: 'community',
    needsAuth: true,
    needsID: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: [
        'CommunityResolver',
        'MembersResolver',
        'EntitiesResolver',
        'KpiCommunityResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communityBenefitsCard',
            x: 0,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'communityEnergyFlowsCard',
            x: 4,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'membersTable',
            x: 0,
            y: 42,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'CommunityDetailsParameters',
            x: 10,
            y: 42,
            w: 3,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'silly-iceberg',
          devPath: 'community->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communityBenefitsCard',
          id: 'communityBenefitsCard',
          isOverview: true,
          totalValue: 'total_benefits',
          icon: 'euro',
          descriptionCallback: 'communityEconomics',
          descriptionType: 'label',
          description: 'widgets.communityBenefitsCard.description.default',
          devID: 'waggish-xylophone',
          devPath: 'community->default->communityBenefitsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communityEnergyFlowsCard',
          id: 'communityEnergyFlowsCard',
          isOverview: true,
          icon: 'lightning',
          descriptionCallback: 'communityEnergy',
          descriptionType: 'label+value',
          description: [
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.consumption',
              value: 'consumption',
            },
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.production',
              value: 'production',
            },
            {
              key: 'widgets.communityEnergyFlowsCard.description.default.shared',
              value: 'total_shared',
            },
          ],
          devID: 'peculiar-quokka',
          devPath: 'community->default->communityEnergyFlowsCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          devID: 'giddy-cat',
          devPath: 'community->default->map',
        },
        {
          type: 'membersTable',
          id: 'membersTable',
          devID: 'jazzy-mango',
          devPath: 'community->default->membersTable',
        },
        {
          type: 'CommunityDetailsParameters',
          id: 'CommunityDetailsParameters',
          data: {
            title: 'widgets.communityCommunityDetailsParameters.title',
            fields: {
              communityId:
                'widgets.communityCommunityDetailsParameters.communityId',
              externalId:
                'widgets.communityCommunityDetailsParameters.externalId',
              communityCode:
                'widgets.communityCommunityDetailsParameters.communityCode',
              communityName:
                'widgets.communityCommunityDetailsParameters.communityName',
              communityType:
                'widgets.communityCommunityDetailsParameters.communityType',
              startDate:
                'widgets.communityCommunityDetailsParameters.startDate',
            },
            shownFields: [
              'externalId',
              'communityName',
              'communityType',
              'startDate',
            ],
          },
          devID: 'happy-avocado',
          devPath: 'community->default->CommunityDetailsParameters',
        },
      ],
    },
  },
  {
    name: 'member',
    needsAuth: true,
    needsID: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: [
        'EntitiesResolver',
        'MembersResolver',
        'MemberResolver',
        'KpiMemberResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberBenefitsCard',
            x: 0,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'memberEnergyFlowsCard',
            x: 4,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'paymentsTable',
            x: 0,
            y: 41,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'MemberDetailsParameters',
            x: 10,
            y: 41,
            w: 3,
            h: 50,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberSitesList',
            x: 10,
            y: 93,
            w: 3,
            h: 82,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'members',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'zany-penguin',
          devPath: 'member->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'memberBenefitsCard',
          id: 'memberBenefitsCard',
          totalValue: 'total_benefits',
          isOverview: true,
          icon: 'euro',
          descriptionCallback: 'MemberBenefits',
          descriptionType: 'label',
          description: 'widgets.memberBenefitsCard.description.default',
          devID: 'jazzy-glove',
          devPath: 'member->default->memberBenefitsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'memberEnergyFlowsCard',
          id: 'memberEnergyFlowsCard',
          icon: 'lightning',
          descriptionCallback: 'memberEnergy',
          descriptionType: '[member]label+value',
          isOverview: true,
          description: [
            {
              key: 'widgets.memberEnergyFlowsCard.description.default.consumption',
              value: 'consumption',
            },
            {
              key: 'widgets.memberEnergyFlowsCard.description.default.production',
              value: 'production',
            },
            {
              key: 'widgets.memberEnergyFlowsCard.description.default.self_consumption',
              value: 'self_consumption',
            },
          ],
          devID: 'rambunctious-shoe',
          devPath: 'member->default->memberEnergyFlowsCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          context: 'member',
          devID: 'nutty-quokka',
          devPath: 'member->default->map',
        },
        {
          type: 'paymentsTable',
          id: 'paymentsTable',
          devID: 'waggish-marshmallow',
          devPath: 'member->default->paymentsTable',
        },
        {
          type: 'MemberDetailsParameters',
          id: 'MemberDetailsParameters',
          data: {
            title: 'widgets.memberDetailsParameters.title',
            fields: {
              communityId: 'widgets.memberDetailsParameters.communityId',
              externalId: 'widgets.memberDetailsParameters.externalId',
              communityCode: 'widgets.memberDetailsParameters.communityCode',
              communityName: 'widgets.memberDetailsParameters.communityName',
              communityType: 'widgets.memberDetailsParameters.communityType',
              startDate: 'widgets.memberDetailsParameters.startDate',
              memberId: 'widgets.memberDetailsParameters.memberId',
              clientId: 'widgets.memberDetailsParameters.clientId',
              name: 'widgets.memberDetailsParameters.name',
              address: 'widgets.memberDetailsParameters.address',
              city: 'widgets.memberDetailsParameters.city',
              postcode: 'widgets.memberDetailsParameters.postcode',
              phone: 'widgets.memberDetailsParameters.phone',
              email: 'widgets.memberDetailsParameters.email',
            },
            sitesNumber: 'widgets.memberDetailsParameters.sitesNumber',
            shownFields: ['memberId', 'address', 'city', 'phone', 'email'],
          },
          devID: 'glowing-lighthouse',
          devPath: 'member->default->MemberDetailsParameters',
        },
        {
          type: 'memberSitesList',
          id: 'memberSitesList',
          devID: 'golden-pajamas',
          devPath: 'member->default->memberSitesList',
        },
      ],
    },
  },
  {
    name: 'member-economics',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'MemberResolver',
        'KpiMemberResolver',
        'MemberRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'memberSavingsCard',
            x: 0,
            y: 13,
            w: 12,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 52,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'horizontalChart',
            x: 0,
            y: 96,
            w: 12,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
              display: 'flex',
            },
          },
        ],
      },
      administrator: {
        default: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberSavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 96,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                display: 'flex',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        default_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberSavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
      supervisor: {
        default: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberSavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 96,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                display: 'flex',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        default_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberSavingsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'member-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'jazzy-waffle',
          devPath: 'member-economics->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'memberSavingsCard',
          id: 'memberSavingsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          description: 'widgets.memberSavingsCard.description.default',
          descriptionCallback: 'memberTotalEstimatedBenefits',
          devID: 'clumsy-butterfly',
          devPath: 'member-economics->default->memberSavingsCard',
        },
        {
          env: 'web',
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: [
            {
              key: 'revenues',
              color: 'economics',
              combined: false,
            },
            {
              key: 'savings',
              color: 'savings',
              combined: false,
            },
          ],
          colors: ['economics', 'savings'],
          title: 'widgets.savingsStackBar.title',
          devID: 'zealous-pajamas',
          devPath: 'member-economics->default->savingsStackBar',
        },
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          entity: 'member',
          context: 'economics',
          rankingKpi: 'revenues',
          rankingKpiName: 'widgets.horizontalChart.economics.revenues',
          graphKeys: ['revenues', 'savings'],
          combinedKeys: [],
          buttons: [
            'widgets.horizontalChart.economics.benefits',
            'widgets.horizontalChart.economics.savings',
          ],
          colors: [
            {
              color: 'economics',
              colorShade: '',
            },
            {
              color: 'savings',
              colorShade: '',
            },
          ],
          title: 'widgets.horizontalChart.economics.title',
          devID: 'vivacious-octopus',
          devPath: 'member-economics->default->horizontalChart',
        },
      ],
      administrator: {
        default: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'member-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'whacky-spoon',
            devPath: 'member-economics->administrator->default->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'memberSavingsCard',
            id: 'memberSavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.memberSavingsCard.description.default',
            descriptionCallback: 'memberTotalEstimatedBenefits',
            devID: 'playful-firecracker',
            devPath:
              'member-economics->administrator->default->memberSavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'jubilant-jigsaw',
            devPath:
              'member-economics->administrator->default->savingsStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            entity: 'member',
            context: 'economics',
            rankingKpi: 'revenues',
            rankingKpiName: 'widgets.horizontalChart.economics.revenues',
            graphKeys: ['revenues', 'savings'],
            combinedKeys: [],
            buttons: [
              'widgets.horizontalChart.economics.benefits',
              'widgets.horizontalChart.economics.savings',
            ],
            colors: [
              {
                color: 'economics',
                colorShade: '',
              },
              {
                color: 'savings',
                colorShade: '',
              },
            ],
            title: 'widgets.horizontalChart.economics.title',
            devID: 'golden-cat',
            devPath:
              'member-economics->administrator->default->horizontalChart',
          },
        ],
        default_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'member-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rowdy-lemonade',
            devPath:
              'member-economics->administrator->default_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'memberSavingsCard',
            id: 'memberSavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.memberSavingsCard.description.default',
            descriptionCallback: 'memberTotalEstimatedBenefits',
            devID: 'bizarre-spoon',
            devPath:
              'member-economics->administrator->default_short->memberSavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'ferocious-giraffe',
            devPath:
              'member-economics->administrator->default_short->savingsStackBar',
          },
        ],
      },
      supervisor: {
        default: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'member-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'daring-saxophone',
            devPath: 'member-economics->supervisor->default->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'memberSavingsCard',
            id: 'memberSavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.memberSavingsCard.description.default',
            descriptionCallback: 'memberTotalEstimatedBenefits',
            devID: 'nocturnal-dragon',
            devPath: 'member-economics->supervisor->default->memberSavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'zesty-kiwi',
            devPath: 'member-economics->supervisor->default->savingsStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            entity: 'member',
            context: 'economics',
            rankingKpi: 'revenues',
            rankingKpiName: 'widgets.horizontalChart.economics.revenues',
            graphKeys: ['revenues', 'savings'],
            combinedKeys: [],
            buttons: [
              'widgets.horizontalChart.economics.benefits',
              'widgets.horizontalChart.economics.savings',
            ],
            colors: [
              {
                color: 'economics',
                colorShade: '',
              },
              {
                color: 'savings',
                colorShade: '',
              },
            ],
            title: 'widgets.horizontalChart.economics.title',
            devID: 'rampaging-enchilada',
            devPath: 'member-economics->supervisor->default->horizontalChart',
          },
        ],
        default_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'member-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'unruly-marshmallow',
            devPath: 'member-economics->supervisor->default_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'memberSavingsCard',
            id: 'memberSavingsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.memberSavingsCard.description.default',
            descriptionCallback: 'memberTotalEstimatedBenefits',
            devID: 'vivacious-saxophone',
            devPath:
              'member-economics->supervisor->default_short->memberSavingsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'nutty-flamingo',
            devPath:
              'member-economics->supervisor->default_short->savingsStackBar',
          },
        ],
      },
    },
  },
  {
    name: 'member-energy',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'MemberResolver',
        'KpiMemberResolver',
        'MemberRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
      default_short: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
      administrator: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer_battery: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_shortconsumer_producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
      supervisor: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer_battery: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_shortconsumer_producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'memberStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'memberSelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberSharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'memberPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'mystical-spoon',
          devPath: 'member-energy->default->headerWeb',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'memberStatsOverviewConsumption',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'member',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'consumption_self_consumption_direct_rate',
              label:
                'widgets.memberStatsOverviewConsumption.self_consumption_direct',
            },
            {
              key: 'from_grid',
              percentage: 'consumption_from_grid_rate',
              label: 'widgets.memberStatsOverviewConsumption.from_grid',
            },
          ],
          graphColors: ['self-consumption', 'consumption'],
          title: 'widgets.memberStatsOverviewConsumption.title',
          devID: 'zesty-marshmallow',
          devPath: 'member-energy->default->memberStatsOverviewConsumption',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'memberStatsOverviewProduction',
          totalValue: 'production',
          color: 'production',
          view: 'member',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'production_self_consumption_direct_rate',
              label:
                'widgets.memberStatsOverviewProduction.self_consumption_direct',
            },
            {
              key: 'to_grid',
              percentage: 'production_to_grid_rate',
              label: 'widgets.memberStatsOverviewProduction.to_grid',
            },
          ],
          graphColors: ['self-consumption', 'production'],
          title: 'widgets.memberStatsOverviewProduction.title',
          devID: 'witty-dragon',
          devPath: 'member-energy->default->memberStatsOverviewProduction',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          devID: 'wild-pencil',
          devPath: 'member-energy->default->map',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'memberSelfSufficiencyCard',
          id: 'memberSelfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          value: 'self_consumption',
          icon: 'self_sufficiency',
          descriptionType: 'label+value+unit',
          description: 'widgets.memberSelfSufficiencyCard.description.default',
          devID: 'hysterical-jellyfish',
          devPath: 'member-energy->default->memberSelfSufficiencyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'memberSharedEnergyCard',
          id: 'memberSharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'web:label+value|label+value',
          description: [
            {
              key: 'widgets.memberSharedEnergyCard.description.key_1',
              value: 'from_shared',
            },
            {
              key: 'widgets.memberSharedEnergyCard.description.key_2',
              value: 'to_shared',
            },
          ],
          devID: 'rampaging-octopus',
          devPath: 'member-energy->default->memberSharedEnergyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'memberPollutionCard',
          id: 'memberPollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_trees',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
            line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
          },
          devID: 'boisterous-spoon',
          devPath: 'member-energy->default->memberPollutionCard',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          totalValue: 'consumption',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'self_consumption_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'from_shared',
              color: 'consumption',
              combined: true,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: true,
            },
          ],
          combinedKeys: [
            {
              key: 'from_shared',
              color: 'consumption',
              combined: true,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.consumptionStackBar.title',
          devID: 'whacky-quokka',
          devPath: 'member-energy->default->consumptionStackBar',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          totalValue: 'production',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'production_to_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'to_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          combinedKeys: [
            {
              key: 'to_shared',
              color: 'productionShared',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'production',
              colorShade: '',
            },
            {
              color: 'productionShared',
              colorShade: '',
            },
          ],
          title: 'widgets.productionStackBar.title',
          devID: 'zippy-lollipop',
          devPath: 'member-energy->default->productionStackBar',
        },
        {
          id: 'batteryChart',
          title: 'widgets.batteryChart.title',
          type: 'batteryChart',
          gradientBorder: false,
          context: 'member',
          buttons: ['widgets.batteryChart.self_consumption'],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
          ],
          db_fields: ['battery_net_flow'],
          devID: 'bizarre-marshmallow',
          devPath: 'member-energy->default->batteryChart',
        },
        {
          env: 'web',
          id: 'areaChartSelfConsumption',
          title: 'widgets.areaChartSelfConsumptionCommunity.title',
          type: 'areaChart',
          subtype: 'areaChart',
          gradientBorder: false,
          view: 'community',
          graphKeys: ['consumption', 'production', 'self_consumption'],
          colors: ['consumption', 'production', 'shared'],
          devID: 'mirthful-dragon',
          devPath: 'member-energy->default->areaChartSelfConsumption',
        },
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          context: 'energy',
          rankingKpi: 'consumption',
          rankingKpiName: [
            'widgets.horizontalChart.energy.consumption',
            'widgets.horizontalChart.energy.production',
          ],
          graphKeys: [
            [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid',
            ],
          ],
          combinedKeys: ['from_shared', 'from_grid'],
          buttons: [
            'widgets.horizontalChart.energy.self_consumption_direct',
            'widgets.horizontalChart.energy.self_consumption_battery',
            'widgets.horizontalChart.energy.from_shared',
            'widgets.horizontalChart.energy.from_grid',
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.horizontalChart.energy.title',
          devID: 'hysterical-penguin',
          devPath: 'member-energy->default->horizontalChart',
        },
      ],
      administrator: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'ludicrous-lollipop',
            devPath: 'member-energy->administrator->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'daring-cabbage',
            devPath:
              'member-energy->administrator->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'fantastic-mango',
            devPath: 'member-energy->administrator->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'quirksome-cabbage',
            devPath: 'member-energy->administrator->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'bizarre-rhinoceros',
            devPath: 'member-energy->administrator->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'ludicrous-dragon',
            devPath:
              'member-energy->administrator->consumer->consumptionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: 'widgets.horizontalChart.energy.consumption',
            graphKeys: ['from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            buttons: [
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'zesty-waffle',
            devPath: 'member-energy->administrator->consumer->horizontalChart',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'playful-lollipop',
            devPath: 'member-energy->administrator->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'turbulent-cat',
            devPath:
              'member-energy->administrator->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'rampaging-cat',
            devPath: 'member-energy->administrator->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'daring-xylophone',
            devPath: 'member-energy->administrator->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'mystical-cabbage',
            devPath: 'member-energy->administrator->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'frisky-hamburger',
            devPath:
              'member-energy->administrator->producer->productionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'production',
            rankingKpiName: 'widgets.horizontalChart.energy.production',
            graphKeys: ['production_to_battery', 'to_shared', 'to_grid'],
            combinedKeys: ['to_shared', 'to_grid'],
            buttons: [
              'widgets.horizontalChart.energy.to_shared',
              'widgets.horizontalChart.energy.to_grid',
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'peculiar-lighthouse',
            devPath: 'member-energy->administrator->producer->horizontalChart',
          },
        ],
        consumer_producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'mirthful-buffet',
            devPath:
              'member-energy->administrator->consumer_producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'oddball-giraffe',
            devPath:
              'member-energy->administrator->consumer_producer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'glowing-cactus',
            devPath:
              'member-energy->administrator->consumer_producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.memberSharedEnergyCard.description.consumer.key_1',
            devID: 'eccentric-espresso',
            devPath:
              'member-energy->administrator->consumer_producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'wild-kangaroo',
            devPath:
              'member-energy->administrator->consumer_producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'wild-jellyfish',
            devPath: 'member-energy->administrator->consumer_producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'nutty-penguin',
            devPath:
              'member-energy->administrator->consumer_producer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'fantastic-glove',
            devPath:
              'member-energy->administrator->consumer_producer->productionStackBar',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'eccentric-banana',
            devPath:
              'member-energy->administrator->consumer_producer->horizontalChartDouble',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rambunctious-gumball',
            devPath: 'member-energy->administrator->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'mystical-dragon',
            devPath:
              'member-energy->administrator->prosumer->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'turbulent-cactus',
            devPath:
              'member-energy->administrator->prosumer->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'bizarre-rhinoceros',
            devPath:
              'member-energy->administrator->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'zesty-kangaroo',
            devPath: 'member-energy->administrator->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'quirky-cabbage',
            devPath: 'member-energy->administrator->prosumer->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'zany-shoe',
            devPath:
              'member-energy->administrator->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'maniacal-iceberg',
            devPath:
              'member-energy->administrator->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'playful-saxophone',
            devPath:
              'member-energy->administrator->prosumer->areaChartSelfConsumptionSite',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'rambunctious-spoon',
            devPath:
              'member-energy->administrator->prosumer->horizontalChartDouble',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'happy-lollipop',
            devPath: 'member-energy->administrator->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'quirky-hedgehog',
            devPath:
              'member-energy->administrator->prosumage->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'unruly-penguin',
            devPath:
              'member-energy->administrator->prosumage->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'vivacious-dragon',
            devPath: 'member-energy->administrator->prosumage->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'happy-jellyfish',
            devPath:
              'member-energy->administrator->prosumage->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'production',
              },
            ],
            devID: 'waggish-ukulele',
            devPath:
              'member-energy->administrator->prosumage->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'quirky-cactus',
            devPath:
              'member-energy->administrator->prosumage->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'angry-obelisk',
            devPath:
              'member-energy->administrator->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'fantastic-espresso',
            devPath:
              'member-energy->administrator->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'zealous-yogurt',
            devPath: 'member-energy->administrator->prosumage->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'zesty-penguin',
            devPath:
              'member-energy->administrator->prosumage->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'nocturnal-pencil',
            devPath:
              'member-energy->administrator->prosumage->horizontalChartDouble',
          },
        ],
        prosumer_battery: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'turbulent-penguin',
            devPath:
              'member-energy->administrator->prosumer_battery->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'zealous-iceberg',
            devPath:
              'member-energy->administrator->prosumer_battery->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'boisterous-saxophone',
            devPath:
              'member-energy->administrator->prosumer_battery->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'mirthful-xylophone',
            devPath: 'member-energy->administrator->prosumer_battery->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'ferocious-brick',
            devPath:
              'member-energy->administrator->prosumer_battery->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'production',
              },
            ],
            devID: 'unruly-glove',
            devPath:
              'member-energy->administrator->prosumer_battery->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'jazzy-espresso',
            devPath:
              'member-energy->administrator->prosumer_battery->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'ludicrous-enchilada',
            devPath:
              'member-energy->administrator->prosumer_battery->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'quirky-kiwi',
            devPath:
              'member-energy->administrator->prosumer_battery->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'waggish-avocado',
            devPath:
              'member-energy->administrator->prosumer_battery->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'zealous-saxophone',
            devPath:
              'member-energy->administrator->prosumer_battery->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'spritely-vortex',
            devPath:
              'member-energy->administrator->prosumer_battery->horizontalChartDouble',
          },
        ],
        consumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'wild-ferret',
            devPath: 'member-energy->administrator->consumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'unruly-butterfly',
            devPath:
              'member-energy->administrator->consumer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'hysterical-penguin',
            devPath:
              'member-energy->administrator->consumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'angry-cabbage',
            devPath:
              'member-energy->administrator->consumer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'jubilant-giraffe',
            devPath: 'member-energy->administrator->consumer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'rowdy-lighthouse',
            devPath:
              'member-energy->administrator->consumer_short->consumptionStackBar',
          },
        ],
        producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'wild-obelisk',
            devPath: 'member-energy->administrator->producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'nocturnal-igloo',
            devPath:
              'member-energy->administrator->producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'quirksome-firecracker',
            devPath:
              'member-energy->administrator->producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'daring-lemonade',
            devPath:
              'member-energy->administrator->producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'glowing-kiwi',
            devPath: 'member-energy->administrator->producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'turbulent-mango',
            devPath:
              'member-energy->administrator->producer_short->productionStackBar',
          },
        ],
        producer_shortconsumer_producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'bizarre-quokka',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'jazzy-buffet',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'unruly-hamburger',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.memberSharedEnergyCard.description.consumer.key_1',
            devID: 'maniacal-lighthouse',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'giddy-pencil',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'luminous-cat',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'angry-lighthouse',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'boisterous-enchilada',
            devPath:
              'member-energy->administrator->producer_shortconsumer_producer_short->productionStackBar',
          },
        ],
        prosumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'nocturnal-mango',
            devPath: 'member-energy->administrator->prosumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'frisky-penguin',
            devPath:
              'member-energy->administrator->prosumer_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'whimsical-giraffe',
            devPath:
              'member-energy->administrator->prosumer_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'jubilant-dragon',
            devPath:
              'member-energy->administrator->prosumer_short->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'mirthful-xylophone',
            devPath:
              'member-energy->administrator->prosumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'zippy-marshmallow',
            devPath:
              'member-energy->administrator->prosumer_short->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'frisky-quokka',
            devPath:
              'member-energy->administrator->prosumer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'mirthful-quokka',
            devPath:
              'member-energy->administrator->prosumer_short->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'quirky-marshmallow',
            devPath:
              'member-energy->administrator->prosumer_short->areaChartSelfConsumptionSite',
          },
        ],
        prosumage_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rambunctious-saxophone',
            devPath: 'member-energy->administrator->prosumage_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'jubilant-buffet',
            devPath:
              'member-energy->administrator->prosumage_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'nocturnal-hamburger',
            devPath:
              'member-energy->administrator->prosumage_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'ludicrous-narwhal',
            devPath: 'member-energy->administrator->prosumage_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'daring-marshmallow',
            devPath:
              'member-energy->administrator->prosumage_short->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'wild-espresso',
            devPath:
              'member-energy->administrator->prosumage_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'ludicrous-octopus',
            devPath:
              'member-energy->administrator->prosumage_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'jazzy-cactus',
            devPath:
              'member-energy->administrator->prosumage_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'impish-ferret',
            devPath:
              'member-energy->administrator->prosumage_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'site',
            buttons: [
              'widgets.batteryChart.self_consumption',
              'widgets.batteryChart.soc',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow', 'soc_battery'],
            devID: 'bizarre-obelisk',
            devPath:
              'member-energy->administrator->prosumage_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'happy-mango',
            devPath:
              'member-energy->administrator->prosumage_short->areaChartSelfConsumption',
          },
        ],
        prosumer_battery_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'spritely-quokka',
            devPath:
              'member-energy->administrator->prosumer_battery_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'waggish-butterfly',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'rambunctious-kangaroo',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'daring-dinosaur',
            devPath:
              'member-energy->administrator->prosumer_battery_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'impish-avocado',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'jubilant-flamingo',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'turbulent-marshmallow',
            devPath:
              'member-energy->administrator->prosumer_battery_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'nocturnal-butterfly',
            devPath:
              'member-energy->administrator->prosumer_battery_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'wild-narwhal',
            devPath:
              'member-energy->administrator->prosumer_battery_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'turbulent-cactus',
            devPath:
              'member-energy->administrator->prosumer_battery_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'turbulent-rhinoceros',
            devPath:
              'member-energy->administrator->prosumer_battery_short->areaChartSelfConsumption',
          },
        ],
      },
      supervisor: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'maniacal-jigsaw',
            devPath: 'member-energy->supervisor->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'playful-butterfly',
            devPath:
              'member-energy->supervisor->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'golden-obelisk',
            devPath: 'member-energy->supervisor->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'daring-kiwi',
            devPath: 'member-energy->supervisor->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'glowing-iceberg',
            devPath: 'member-energy->supervisor->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'eccentric-nacho',
            devPath: 'member-energy->supervisor->consumer->consumptionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: 'widgets.horizontalChart.energy.consumption',
            graphKeys: ['from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            buttons: [
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'mirthful-hedgehog',
            devPath: 'member-energy->supervisor->consumer->horizontalChart',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'frisky-marshmallow',
            devPath: 'member-energy->supervisor->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'fantastic-dinosaur',
            devPath: 'member-energy->supervisor->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'nutty-cactus',
            devPath: 'member-energy->supervisor->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'luminous-enchilada',
            devPath: 'member-energy->supervisor->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'glowing-firecracker',
            devPath: 'member-energy->supervisor->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'waggish-cabbage',
            devPath: 'member-energy->supervisor->producer->productionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'production',
            rankingKpiName: 'widgets.horizontalChart.energy.production',
            graphKeys: ['production_to_battery', 'to_shared', 'to_grid'],
            combinedKeys: ['to_shared', 'to_grid'],
            buttons: [
              'widgets.horizontalChart.energy.to_shared',
              'widgets.horizontalChart.energy.to_grid',
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'mystical-iceberg',
            devPath: 'member-energy->supervisor->producer->horizontalChart',
          },
        ],
        consumer_producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'mystical-cabbage',
            devPath: 'member-energy->supervisor->consumer_producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'mirthful-cat',
            devPath:
              'member-energy->supervisor->consumer_producer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'mystical-tangerine',
            devPath:
              'member-energy->supervisor->consumer_producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.memberSharedEnergyCard.description.consumer.key_1',
            devID: 'impish-avocado',
            devPath:
              'member-energy->supervisor->consumer_producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'unruly-buffet',
            devPath:
              'member-energy->supervisor->consumer_producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'clumsy-cactus',
            devPath: 'member-energy->supervisor->consumer_producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'nocturnal-kangaroo',
            devPath:
              'member-energy->supervisor->consumer_producer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'jazzy-yogurt',
            devPath:
              'member-energy->supervisor->consumer_producer->productionStackBar',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'spritely-spoon',
            devPath:
              'member-energy->supervisor->consumer_producer->horizontalChartDouble',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'glowing-pajamas',
            devPath: 'member-energy->supervisor->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'fantastic-cactus',
            devPath:
              'member-energy->supervisor->prosumer->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'kooky-firecracker',
            devPath:
              'member-energy->supervisor->prosumer->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'whacky-glove',
            devPath: 'member-energy->supervisor->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'turbulent-butterfly',
            devPath: 'member-energy->supervisor->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'impish-nacho',
            devPath: 'member-energy->supervisor->prosumer->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'nocturnal-jigsaw',
            devPath: 'member-energy->supervisor->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'fantastic-xylophone',
            devPath: 'member-energy->supervisor->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'maniacal-ferret',
            devPath:
              'member-energy->supervisor->prosumer->areaChartSelfConsumptionSite',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'playful-pajamas',
            devPath:
              'member-energy->supervisor->prosumer->horizontalChartDouble',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'unruly-shoe',
            devPath: 'member-energy->supervisor->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'boisterous-cat',
            devPath:
              'member-energy->supervisor->prosumage->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'nutty-narwhal',
            devPath:
              'member-energy->supervisor->prosumage->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'spritely-dinosaur',
            devPath: 'member-energy->supervisor->prosumage->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'silly-zeppelin',
            devPath:
              'member-energy->supervisor->prosumage->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'production',
              },
            ],
            devID: 'luminous-buffet',
            devPath:
              'member-energy->supervisor->prosumage->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'spritely-tangerine',
            devPath:
              'member-energy->supervisor->prosumage->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'zealous-hedgehog',
            devPath:
              'member-energy->supervisor->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'quirksome-buffet',
            devPath: 'member-energy->supervisor->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'eccentric-cactus',
            devPath: 'member-energy->supervisor->prosumage->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'luminous-firecracker',
            devPath:
              'member-energy->supervisor->prosumage->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'witty-butterfly',
            devPath:
              'member-energy->supervisor->prosumage->horizontalChartDouble',
          },
        ],
        prosumer_battery: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'boisterous-hamburger',
            devPath: 'member-energy->supervisor->prosumer_battery->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'golden-hedgehog',
            devPath:
              'member-energy->supervisor->prosumer_battery->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'whimsical-zeppelin',
            devPath:
              'member-energy->supervisor->prosumer_battery->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'whacky-igloo',
            devPath: 'member-energy->supervisor->prosumer_battery->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'mystical-butterfly',
            devPath:
              'member-energy->supervisor->prosumer_battery->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'consumption',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'production',
              },
            ],
            devID: 'impish-cactus',
            devPath:
              'member-energy->supervisor->prosumer_battery->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'cannibal-enchilada',
            devPath:
              'member-energy->supervisor->prosumer_battery->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'kooky-rhinoceros',
            devPath:
              'member-energy->supervisor->prosumer_battery->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'bizarre-dinosaur',
            devPath:
              'member-energy->supervisor->prosumer_battery->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'mystical-flamingo',
            devPath:
              'member-energy->supervisor->prosumer_battery->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'nutty-brick',
            devPath:
              'member-energy->supervisor->prosumer_battery->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'nocturnal-firecracker',
            devPath:
              'member-energy->supervisor->prosumer_battery->horizontalChartDouble',
          },
        ],
        consumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'frisky-brick',
            devPath: 'member-energy->supervisor->consumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'quirky-espresso',
            devPath:
              'member-energy->supervisor->consumer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'eccentric-yogurt',
            devPath:
              'member-energy->supervisor->consumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'jubilant-pencil',
            devPath: 'member-energy->supervisor->consumer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'boisterous-ukulele',
            devPath: 'member-energy->supervisor->consumer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'whimsical-banana',
            devPath:
              'member-energy->supervisor->consumer_short->consumptionStackBar',
          },
        ],
        producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'golden-dinosaur',
            devPath: 'member-energy->supervisor->producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'luminous-flamingo',
            devPath:
              'member-energy->supervisor->producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'giddy-ukulele',
            devPath:
              'member-energy->supervisor->producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'eccentric-buffet',
            devPath: 'member-energy->supervisor->producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'nutty-cat',
            devPath: 'member-energy->supervisor->producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'witty-jellyfish',
            devPath:
              'member-energy->supervisor->producer_short->productionStackBar',
          },
        ],
        producer_shortconsumer_producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'wild-jellyfish',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'daring-butterfly',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'giddy-rhinoceros',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.memberSharedEnergyCard.description.consumer.key_1',
            devID: 'golden-octopus',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'mirthful-marshmallow',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'member-energy',
            devID: 'nocturnal-dragon',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'vivacious-jigsaw',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'golden-giraffe',
            devPath:
              'member-energy->supervisor->producer_shortconsumer_producer_short->productionStackBar',
          },
        ],
        prosumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rowdy-vortex',
            devPath: 'member-energy->supervisor->prosumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'witty-tangerine',
            devPath:
              'member-energy->supervisor->prosumer_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'peculiar-avocado',
            devPath:
              'member-energy->supervisor->prosumer_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'rambunctious-kiwi',
            devPath:
              'member-energy->supervisor->prosumer_short->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'rowdy-penguin',
            devPath:
              'member-energy->supervisor->prosumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'zealous-lighthouse',
            devPath: 'member-energy->supervisor->prosumer_short->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'rampaging-glove',
            devPath:
              'member-energy->supervisor->prosumer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'angry-hedgehog',
            devPath:
              'member-energy->supervisor->prosumer_short->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'whimsical-enchilada',
            devPath:
              'member-energy->supervisor->prosumer_short->areaChartSelfConsumptionSite',
          },
        ],
        prosumage_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'nocturnal-xylophone',
            devPath: 'member-energy->supervisor->prosumage_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'vivacious-dragon',
            devPath:
              'member-energy->supervisor->prosumage_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'kooky-quokka',
            devPath:
              'member-energy->supervisor->prosumage_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'kooky-xylophone',
            devPath: 'member-energy->supervisor->prosumage_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'silly-tangerine',
            devPath:
              'member-energy->supervisor->prosumage_short->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'zealous-shoe',
            devPath:
              'member-energy->supervisor->prosumage_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'boisterous-pencil',
            devPath:
              'member-energy->supervisor->prosumage_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'kooky-dinosaur',
            devPath:
              'member-energy->supervisor->prosumage_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'eccentric-cat',
            devPath:
              'member-energy->supervisor->prosumage_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'site',
            buttons: [
              'widgets.batteryChart.self_consumption',
              'widgets.batteryChart.soc',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow', 'soc_battery'],
            devID: 'rampaging-vortex',
            devPath: 'member-energy->supervisor->prosumage_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'bizarre-pajamas',
            devPath:
              'member-energy->supervisor->prosumage_short->areaChartSelfConsumption',
          },
        ],
        prosumer_battery_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zany-spoon',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'kooky-pencil',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'waggish-tangerine',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'nutty-tangerine',
            devPath: 'member-energy->supervisor->prosumer_battery_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSelfSufficiencyCard',
            id: 'memberSelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.memberSelfSufficiencyCard.description.default',
            devID: 'jubilant-zeppelin',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->memberSelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'impish-tangerine',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'zealous-butterfly',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'vivacious-hedgehog',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'cannibal-obelisk',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'boisterous-lollipop',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'unruly-brick',
            devPath:
              'member-energy->supervisor->prosumer_battery_short->areaChartSelfConsumption',
          },
        ],
      },
    },
  },
  {
    name: 'site',
    needsAuth: true,
    needsID: true,
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    sideBar: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    apis: {
      default: ['EntitiesResolver', 'SiteOverviewResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'siteBenefitsCard',
            x: 0,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              height: '100%',
            },
          },
          {
            i: 'siteEnergyFlowsCard',
            x: 4,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingRight: 8,
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'map',
            x: 8,
            y: 12,
            w: 4,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              paddingLeft: 8,
              height: '100%',
            },
          },
          {
            i: 'sitePaymentsTable',
            x: 0,
            y: 42,
            w: 9,
            h: 38,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'SiteDetailsParameters',
            x: 10,
            y: 41,
            w: 3,
            h: 36,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'siteMembersList',
            x: 10,
            y: 77,
            w: 3,
            h: 57,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site',
            breadCrumbs: true,
            navigation: true,
            calendar: false,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'happy-shoe',
          devPath: 'site->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'siteBenefitsCard',
          id: 'siteBenefitsCard',
          isOverview: true,
          totalValue: 'total_benefits',
          icon: 'euro',
          descriptionCallback: 'siteEconomics',
          descriptionType: 'label',
          description: 'widgets.savingsCard.description.default',
          devID: 'cannibal-firecracker',
          devPath: 'site->default->siteBenefitsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'siteEnergyFlowsCard',
          id: 'siteEnergyFlowsCard',
          isOverview: true,
          icon: 'euro',
          descriptionCallback: 'siteEnergy',
          descriptionType: 'siteType[label+value]',
          description: {
            default: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
            ],
            consumer: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
            ],
            producer: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.production',
                value: 'production',
              },
            ],
            prosumage: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.production',
                value: 'production',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.self_consumption',
                value: 'self_consumption',
              },
            ],
            prosumer: [
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.consumption',
                value: 'consumption',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.production',
                value: 'production',
              },
              {
                key: 'widgets.siteEnergyFlowsCard.description.default.self_consumption',
                value: 'self_consumption',
              },
            ],
          },
          devID: 'wild-tangerine',
          devPath: 'site->default->siteEnergyFlowsCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          context: 'site',
          devID: 'giddy-jigsaw',
          devPath: 'site->default->map',
        },
        {
          type: 'sitePaymentsTable',
          id: 'sitePaymentsTable',
          devID: 'unruly-narwhal',
          devPath: 'site->default->sitePaymentsTable',
        },
        {
          type: 'SiteDetailsParameters',
          id: 'SiteDetailsParameters',
          data: {
            title: 'widgets.siteDetailsParameters.title',
            siteName: 'widgets.siteDetailsParameters.siteName',
            joinDate: 'widgets.siteDetailsParameters.joinDate',
            ownership: 'widgets.siteDetailsParameters.ownership',
            type: 'widgets.siteDetailsParameters.type',
            productionBenefits:
              'widgets.siteDetailsParameters.productionBenefits',
          },
          devID: 'vivacious-obelisk',
          devPath: 'site->default->SiteDetailsParameters',
        },
        {
          type: 'siteMembersList',
          id: 'siteMembersList',
          devID: 'rambunctious-dinosaur',
          devPath: 'site->default->siteMembersList',
        },
      ],
    },
  },
  {
    name: 'site-economics',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: ['EntitiesResolver', 'SiteOverviewResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'savingsCard',
            x: 0,
            y: 13,
            w: 12,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 52,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
        ],
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'waggish-jellyfish',
          devPath: 'site-economics->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'savingsCard',
          id: 'savingsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          descriptionCallback: 'SiteTotalEstimatedBenefits',
          description: 'widgets.savingsCard.description.default',
          devID: 'happy-pencil',
          devPath: 'site-economics->default->savingsCard',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: [
            {
              key: 'revenues',
              color: 'economics',
              combined: false,
            },
            {
              key: 'savings',
              color: 'savings',
              combined: false,
            },
          ],
          colors: ['economics', 'savings'],
          combinedKeys: [],
          title: 'widgets.savingsStackBar.title',
          devID: 'nocturnal-espresso',
          devPath: 'site-economics->default->savingsStackBar',
        },
      ],
    },
  },
  {
    name: 'site-energy',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: ['EntitiesResolver', 'SiteOverviewResolver', 'KpiSiteResolver'],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
      administrator: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'siteStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'siteStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'siteStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'siteStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                paddingLeft: 8,
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
      supervisor: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'siteStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'siteStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'siteStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'siteStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                paddingLeft: 8,
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'site-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'cannibal-shoe',
          devPath: 'site-energy->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'selfSufficiencyCard',
          id: 'selfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          value: 'self_consumption',
          icon: 'self_sufficiency',
          descriptionType: 'label+value+unit',
          description: 'widgets.selfSufficiencyCard.description.prosumer',
          devID: 'happy-marshmallow',
          devPath: 'site-energy->default->selfSufficiencyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'sharedEnergyCard',
          id: 'sharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'web:label+value|label+value',
          description: [
            {
              key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
              value: 'from_shared',
            },
            {
              key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
              value: 'to_shared',
            },
          ],
          devID: 'bizarre-cabbage',
          devPath: 'site-energy->default->sharedEnergyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'pollutionCard',
          id: 'pollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_trees',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.pollutionCard.description.prosumer.line_1',
            line_2: 'widgets.pollutionCard.description.prosumer.line_2',
          },
          devID: 'rampaging-saxophone',
          devPath: 'site-energy->default->pollutionCard',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          context: 'site-energy',
          devID: 'angry-marshmallow',
          devPath: 'site-energy->default->map',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          totalValue: 'consumption',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'self_consumption_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'from_shared',
              color: 'consumption',
              combined: false,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: false,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.consumptionStackBar.title',
          devID: 'daring-dinosaur',
          devPath: 'site-energy->default->consumptionStackBar',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          totalValue: 'production',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'production_to_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'to_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          combinedKey: [
            {
              key: 'to_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'production',
              colorShade: '',
            },
            {
              color: 'productionShared',
              colorShade: '60',
            },
          ],
          title: 'widgets.productionStackBar.title',
          devID: 'zealous-cactus',
          devPath: 'site-energy->default->productionStackBar',
        },
        {
          env: 'web',
          id: 'areaChartSelfConsumptionSite',
          title: 'widgets.areaChartSelfConsumptionSite.title',
          type: 'areachart',
          subtype: 'areachart',
          gradientBorder: false,
          colors: ['consumption', 'production', 'self-consumption'],
          graphKeys: ['consumption', 'production', 'self_consumption'],
          devID: 'rambunctious-nacho',
          devPath: 'site-energy->default->areaChartSelfConsumptionSite',
        },
      ],
      administrator: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zippy-dinosaur',
            devPath: 'site-energy->administrator->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'impish-obelisk',
            devPath:
              'site-energy->administrator->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'ludicrous-flamingo',
            devPath: 'site-energy->administrator->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'clumsy-xylophone',
            devPath: 'site-energy->administrator->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'cannibal-enchilada',
            devPath: 'site-energy->administrator->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'rowdy-hedgehog',
            devPath:
              'site-energy->administrator->consumer->consumptionStackBar',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'jubilant-ukulele',
            devPath: 'site-energy->administrator->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'kooky-rhinoceros',
            devPath:
              'site-energy->administrator->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'kooky-xylophone',
            devPath: 'site-energy->administrator->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'zippy-lemonade',
            devPath: 'site-energy->administrator->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'ferocious-rhinoceros',
            devPath: 'site-energy->administrator->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'kooky-iceberg',
            devPath: 'site-energy->administrator->producer->productionStackBar',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zippy-lighthouse',
            devPath: 'site-energy->administrator->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.siteStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.siteStatsOverviewConsumption.title',
            devID: 'jubilant-cactus',
            devPath:
              'site-energy->administrator->prosumer->siteStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.siteStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.siteStatsOverviewProduction.title',
            devID: 'wild-lighthouse',
            devPath:
              'site-energy->administrator->prosumer->siteStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'playful-hedgehog',
            devPath:
              'site-energy->administrator->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'kooky-lollipop',
            devPath: 'site-energy->administrator->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'nutty-ukulele',
            devPath: 'site-energy->administrator->prosumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'nocturnal-tangerine',
            devPath: 'site-energy->administrator->prosumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'clumsy-saxophone',
            devPath:
              'site-energy->administrator->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'clumsy-obelisk',
            devPath: 'site-energy->administrator->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'spritely-avocado',
            devPath:
              'site-energy->administrator->prosumer->areaChartSelfConsumptionSite',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'luminous-firecracker',
            devPath: 'site-energy->administrator->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.siteStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.siteStatsOverviewConsumption.title',
            devID: 'zippy-yogurt',
            devPath:
              'site-energy->administrator->prosumage->siteStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.siteStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.siteStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'selfConsumption_battery', 'productionGrid'],
            title: 'widgets.siteStatsOverviewProduction.title',
            devID: 'playful-flamingo',
            devPath:
              'site-energy->administrator->prosumage->siteStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumage',
            devID: 'ludicrous-cabbage',
            devPath:
              'site-energy->administrator->prosumage->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'bizarre-buffet',
            devPath: 'site-energy->administrator->prosumage->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumage.line_1',
              line_2: 'widgets.pollutionCard.description.prosumage.line_2',
            },
            devID: 'bizarre-saxophone',
            devPath: 'site-energy->administrator->prosumage->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'quirksome-iceberg',
            devPath: 'site-energy->administrator->prosumage->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'cannibal-tangerine',
            devPath:
              'site-energy->administrator->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'glowing-jigsaw',
            devPath:
              'site-energy->administrator->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'site',
            buttons: [
              'widgets.batteryChart.self_consumption',
              'widgets.batteryChart.soc',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow', 'soc_battery'],
            devID: 'angry-waffle',
            devPath: 'site-energy->administrator->prosumage->batteryChart',
          },
          {
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'site',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'self-consumption'],
            devID: 'angry-saxophone',
            devPath:
              'site-energy->administrator->prosumage->areaChartSelfConsumptionSite',
          },
        ],
      },
      supervisor: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'quirky-hamburger',
            devPath: 'site-energy->supervisor->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'whacky-rhinoceros',
            devPath: 'site-energy->supervisor->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'whimsical-ukulele',
            devPath: 'site-energy->supervisor->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'mystical-flamingo',
            devPath: 'site-energy->supervisor->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'turbulent-xylophone',
            devPath: 'site-energy->supervisor->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'wild-saxophone',
            devPath: 'site-energy->supervisor->consumer->consumptionStackBar',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'playful-pencil',
            devPath: 'site-energy->supervisor->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'golden-ukulele',
            devPath: 'site-energy->supervisor->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'wondrous-cabbage',
            devPath: 'site-energy->supervisor->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'wild-flamingo',
            devPath: 'site-energy->supervisor->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'frisky-hamburger',
            devPath: 'site-energy->supervisor->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'waggish-gumball',
            devPath: 'site-energy->supervisor->producer->productionStackBar',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'clumsy-pajamas',
            devPath: 'site-energy->supervisor->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.siteStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.siteStatsOverviewConsumption.title',
            devID: 'whacky-butterfly',
            devPath:
              'site-energy->supervisor->prosumer->siteStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.siteStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.siteStatsOverviewProduction.title',
            devID: 'angry-giraffe',
            devPath:
              'site-energy->supervisor->prosumer->siteStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'bizarre-giraffe',
            devPath: 'site-energy->supervisor->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'impish-nacho',
            devPath: 'site-energy->supervisor->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'waggish-shoe',
            devPath: 'site-energy->supervisor->prosumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'quirky-lighthouse',
            devPath: 'site-energy->supervisor->prosumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'waggish-ferret',
            devPath: 'site-energy->supervisor->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'mirthful-glove',
            devPath: 'site-energy->supervisor->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'impish-giraffe',
            devPath:
              'site-energy->supervisor->prosumer->areaChartSelfConsumptionSite',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'site-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'maniacal-mango',
            devPath: 'site-energy->supervisor->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.siteStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.siteStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.siteStatsOverviewConsumption.title',
            devID: 'fantastic-mango',
            devPath:
              'site-energy->supervisor->prosumage->siteStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'siteStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'site',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.siteStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.siteStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.siteStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'productionGrid'],
            title: 'widgets.siteStatsOverviewProduction.title',
            devID: 'rambunctious-hamburger',
            devPath:
              'site-energy->supervisor->prosumage->siteStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumage',
            devID: 'glowing-yogurt',
            devPath: 'site-energy->supervisor->prosumage->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumage.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'spritely-jellyfish',
            devPath: 'site-energy->supervisor->prosumage->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumage.line_1',
              line_2: 'widgets.pollutionCard.description.prosumage.line_2',
            },
            devID: 'clumsy-firecracker',
            devPath: 'site-energy->supervisor->prosumage->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'site-energy',
            devID: 'zany-hedgehog',
            devPath: 'site-energy->supervisor->prosumage->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'from_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'fantastic-lollipop',
            devPath: 'site-energy->supervisor->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'to_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'ferocious-octopus',
            devPath: 'site-energy->supervisor->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'site',
            buttons: [
              'widgets.batteryChart.self_consumption',
              'widgets.batteryChart.soc',
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow', 'soc_battery'],
            devID: 'frisky-vortex',
            devPath: 'site-energy->supervisor->prosumage->batteryChart',
          },
          {
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'site',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'self-consumption'],
            devID: 'zealous-tangerine',
            devPath:
              'site-energy->supervisor->prosumage->areaChartSelfConsumptionSite',
          },
        ],
      },
    },
  },
  {
    name: 'community-economics',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'KpiCommunityResolver',
        'CommunityResolver',
        'CommunityRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
          {
            i: 'communityBenefitsCard',
            x: 0,
            y: 13,
            w: 6,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
              paddingRight: 8,
            },
          },
          {
            i: 'communityEarningsCard',
            x: 7,
            y: 13,
            w: 6,
            h: 27,
            static: true,
            draggable: false,
            resize: false,
            style: {
              minHeight: '100%',
              height: '100%',
              paddingLeft: 8,
            },
          },
          {
            i: 'savingsStackBar',
            x: 0,
            y: 42,
            w: 12,
            h: 52,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
            },
          },
          {
            i: 'horizontalChart',
            x: 0,
            y: 96,
            w: 12,
            h: 53,
            static: true,
            draggable: false,
            resize: false,
            style: {
              height: '100%',
              boxSizing: 'border-box',
              display: 'flex',
            },
          },
        ],
      },
      administrator: {
        default: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityBenefitsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 96,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        default_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityBenefitsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
      supervisor: {
        default: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityBenefitsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 96,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        default_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityBenefitsCard',
              x: 0,
              y: 13,
              w: 12,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                minHeight: '100%',
                height: '100%',
              },
            },
            {
              i: 'savingsStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 52,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-economics',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'eccentric-dragon',
          devPath: 'community-economics->default->headerWeb',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          subtype: 'communityBenefitsCard',
          id: 'communityBenefitsCard',
          totalValue: 'total_benefits',
          icon: 'savings',
          descriptionType: 'label',
          description: 'widgets.communityBenefitsCard.description.default',
          descriptionCallback: 'communityTotalEstimatedBenefits',
          devID: 'zippy-narwhal',
          devPath: 'community-economics->default->communityBenefitsCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communityEarningsCard',
          id: 'communityEarningsCard',
          totalValue: 'total_benefits',
          icon: 'house',
          descriptionType: 'ranking[label+value]',
          description: 'widgets.communityEarningsCard.description.default',
          style: {
            paddingLeft: 8,
          },
          devID: 'ferocious-hedgehog',
          devPath: 'community-economics->default->communityEarningsCard',
        },
        {
          env: 'web',
          type: 'graphs',
          gradientBorder: false,
          subtype: 'savingsStackBar',
          id: 'savingsStackBar',
          graphKeys: [
            {
              key: 'revenues',
              color: 'economics',
              combined: false,
            },
            {
              key: 'savings',
              color: 'savings',
              combined: false,
            },
          ],
          colors: ['economics', 'savings'],
          title: 'widgets.savingsStackBar.title',
          devID: 'quirksome-kangaroo',
          devPath: 'community-economics->default->savingsStackBar',
        },
        {
          type: 'horizontalChart',
          gradientBorder: false,
          subtype: 'horizontalChart',
          id: 'horizontalChart',
          context: 'economics',
          rankingKpi: 'revenues',
          rankingKpiName: 'widgets.horizontalChart.economics.revenues',
          graphKeys: ['revenues', 'savings'],
          combinedKeys: [],
          buttons: [
            'widgets.horizontalChart.economics.benefits',
            'widgets.horizontalChart.economics.savings',
          ],
          colors: [
            {
              color: 'economics',
              colorShade: '',
            },
            {
              color: 'savings',
              colorShade: '',
            },
          ],
          title: 'widgets.horizontalChart.economics.title',
          devID: 'zippy-pencil',
          devPath: 'community-economics->default->horizontalChart',
        },
      ],
      administrator: {
        default: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'eccentric-gumball',
            devPath: 'community-economics->administrator->default->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'communityBenefitsCard',
            id: 'communityBenefitsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.communityBenefitsCard.description.default',
            descriptionCallback: 'communityTotalEstimatedBenefits',
            devID: 'wondrous-giraffe',
            devPath:
              'community-economics->administrator->default->communityBenefitsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'angry-giraffe',
            devPath:
              'community-economics->administrator->default->savingsStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            entity: 'community',
            context: 'economics',
            rankingKpi: 'revenues',
            rankingKpiName: 'widgets.horizontalChart.economics.revenues',
            graphKeys: ['revenues', 'savings'],
            combinedKeys: [],
            buttons: [
              'widgets.horizontalChart.economics.revenues',
              'widgets.horizontalChart.economics.savings',
            ],
            colors: [
              {
                color: 'economics',
                colorShade: '',
              },
              {
                color: 'savings',
                colorShade: '',
              },
            ],
            title: 'widgets.horizontalChart.economics.title',
            devID: 'ferocious-banana',
            devPath:
              'community-economics->administrator->default->horizontalChart',
          },
        ],
        default_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'nutty-banana',
            devPath:
              'community-economics->administrator->default_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'communityBenefitsCard',
            id: 'communityBenefitsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.communityBenefitsCard.description.default',
            descriptionCallback: 'communityTotalEstimatedBenefits',
            devID: 'hysterical-quokka',
            devPath:
              'community-economics->administrator->default_short->communityBenefitsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'bizarre-nacho',
            devPath:
              'community-economics->administrator->default_short->savingsStackBar',
          },
        ],
      },
      supervisor: {
        default: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'ludicrous-flamingo',
            devPath: 'community-economics->supervisor->default->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'communityBenefitsCard',
            id: 'communityBenefitsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.communityBenefitsCard.description.default',
            descriptionCallback: 'communityTotalEstimatedBenefits',
            devID: 'mystical-penguin',
            devPath:
              'community-economics->supervisor->default->communityBenefitsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'turbulent-enchilada',
            devPath:
              'community-economics->supervisor->default->savingsStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            entity: 'community',
            context: 'economics',
            rankingKpi: 'revenues',
            rankingKpiName: 'widgets.horizontalChart.economics.revenues',
            graphKeys: ['revenues', 'savings'],
            combinedKeys: [],
            buttons: [
              'widgets.horizontalChart.economics.benefits',
              'widgets.horizontalChart.economics.savings',
            ],
            colors: [
              {
                color: 'economics',
                colorShade: '',
              },
              {
                color: 'savings',
                colorShade: '',
              },
            ],
            title: 'widgets.horizontalChart.economics.title',
            devID: 'silly-espresso',
            devPath:
              'community-economics->supervisor->default->horizontalChart',
          },
        ],
        default_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-economics',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'eccentric-giraffe',
            devPath:
              'community-economics->supervisor->default_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            subtype: 'communityBenefitsCard',
            id: 'communityBenefitsCard',
            totalValue: 'total_benefits',
            icon: 'savings',
            descriptionType: 'label',
            description: 'widgets.communityBenefitsCard.description.default',
            descriptionCallback: 'communityTotalEstimatedBenefits',
            devID: 'jazzy-dinosaur',
            devPath:
              'community-economics->supervisor->default_short->communityBenefitsCard',
          },
          {
            env: 'web',
            type: 'graphs',
            gradientBorder: false,
            subtype: 'savingsStackBar',
            id: 'savingsStackBar',
            graphKeys: [
              {
                key: 'revenues',
                color: 'economics',
                combined: false,
              },
              {
                key: 'savings',
                color: 'savings',
                combined: false,
              },
            ],
            colors: ['economics', 'savings'],
            title: 'widgets.savingsStackBar.title',
            devID: 'zany-avocado',
            devPath:
              'community-economics->supervisor->default_short->savingsStackBar',
          },
        ],
      },
    },
  },
  {
    name: 'community-energy',
    active: {
      default: true,
    },
    visible: {
      default: false,
    },
    footer: {
      default: false,
    },
    header: {
      default: false,
    },
    datePicker: {
      default: false,
    },
    pullToRefresh: {
      default: false,
    },
    needsAuth: true,
    needsID: true,
    apis: {
      default: [
        'EntitiesResolver',
        'KpiCommunityResolver',
        'CommunityResolver',
        'CommunityRankingResolver',
      ],
    },
    layouts: {
      default: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
      default_short: {
        lg: [
          {
            i: 'headerWeb',
            x: 0,
            y: 0,
            w: 12,
            h: 10,
            static: true,
            draggable: false,
            resize: false,
          },
        ],
      },
      administrator: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProductionWeb',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer_battery: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_shortconsumer_producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
      supervisor: {
        consumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChart',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_producer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProductionWeb',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumage: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 98,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        prosumer_battery: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
            {
              i: 'horizontalChartDouble',
              x: 0,
              y: 202,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
          ],
        },
        consumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalProductionCard',
              x: 0,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 3,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 6,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'map',
              x: 9,
              y: 13,
              w: 3,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'productionStackBar',
              x: 0,
              y: 42,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        producer_shortconsumer_producer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'totalConsumptionCard',
              x: 0,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'totalProductionCard',
              x: 2.4,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4.8,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 16,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'pollutionCard',
              x: 7.2,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'map',
              x: 9.6,
              y: 13,
              w: 2.4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                marginRight: -8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 42,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'selfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
              },
            },
            {
              i: 'sharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                paddingRight: 8,
              },
            },
            {
              i: 'pollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingRight: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                paddingLeft: 8,
                boxSizing: 'border-box',
              },
            },
            {
              i: 'areaChartSelfConsumptionSite',
              x: 0,
              y: 147,
              w: 12,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumage_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
        prosumer_battery_short: {
          lg: [
            {
              i: 'headerWeb',
              x: 0,
              y: 0,
              w: 12,
              h: 10,
              static: true,
              draggable: false,
              resize: false,
            },
            {
              i: 'communityStatsOverviewConsumption',
              x: 0,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
                marginLeft: -16,
                paddingLeft: 16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communityStatsOverviewProduction',
              x: 4,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                backgroundColor: 'var( --hive-pureWhite )',
              },
            },
            {
              i: 'map',
              x: 8,
              y: 11,
              w: 4,
              h: 50,
              static: true,
              draggable: false,
              resize: false,
              style: {
                height: '100%',
                marginRight: -16,
                minWidth: '33.3%!important',
              },
            },
            {
              i: 'communitySelfSufficiencyCard',
              x: 0,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communitySharedEnergyCard',
              x: 4,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'communityPollutionCard',
              x: 8,
              y: 63,
              w: 4,
              h: 27,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
              },
            },
            {
              i: 'consumptionStackBar',
              x: 0,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'productionStackBar',
              x: 7,
              y: 92,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                boxSizing: 'border-box',
                height: '100%',
              },
            },
            {
              i: 'batteryChart',
              x: 0,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingRight: 8,
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
              },
            },
            {
              i: 'areaChartSelfConsumption',
              x: 6,
              y: 147,
              w: 6,
              h: 53,
              static: true,
              draggable: false,
              resize: false,
              style: {
                paddingLeft: 8,
                height: '100%',
                boxSizing: 'border-box',
              },
            },
          ],
        },
      },
    },
    widgets: {
      default: [
        {
          type: 'headerWeb',
          id: 'headerWeb',
          data: {
            label: 'community-energy',
            breadCrumbs: true,
            navigation: true,
            calendar: true,
            download: false,
            searchBar: true,
            profile: true,
          },
          devID: 'mirthful-nacho',
          devPath: 'community-energy->default->headerWeb',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'communityStatsOverviewConsumption',
          totalValue: 'consumption',
          color: 'consumption',
          view: 'community',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'consumption_self_consumption_direct_rate',
              label:
                'widgets.communityStatsOverviewConsumption.self_consumption_direct',
            },
            {
              key: 'from_grid',
              percentage: 'consumption_from_grid_rate',
              label: 'widgets.communityStatsOverviewConsumption.from_grid',
            },
          ],
          graphColors: ['self-consumption', 'consumption'],
          title: 'widgets.communityStatsOverviewConsumption.title',
          devID: 'boisterous-yogurt',
          devPath:
            'community-energy->default->communityStatsOverviewConsumption',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'communityStatsOverviewProduction',
          totalValue: 'production',
          color: 'production',
          view: 'community',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'consumption_self_consumption_direct_rate',
              label:
                'widgets.communityStatsOverviewProduction.self_consumption_direct',
            },
            {
              key: 'to_grid',
              percentage: 'production_to_grid_rate',
              label: 'widgets.communityStatsOverviewProduction.to_grid',
            },
          ],
          graphColors: ['self-consumption', 'production'],
          title: 'widgets.communityStatsOverviewProduction.title',
          devID: 'rowdy-shoe',
          devPath:
            'community-energy->default->communityStatsOverviewProduction',
        },
        {
          env: 'web',
          type: 'statsOverviewWeb',
          subtype: 'statsOverviewWeb',
          id: 'communityStatsOverviewProduction',
          totalValue: 'production',
          color: 'production',
          view: 'community',
          graphDataKeys: [
            {
              key: 'self_consumption_direct',
              percentage: 'production_self_consumption_direct_rate',
              label:
                'widgets.communityStatsOverviewProduction.self_consumption_direct',
            },
            {
              key: 'production_to_battery',
              percentage: 'production_to_battery_rate',
              label:
                'widgets.communityStatsOverviewProduction.production_to_battery',
            },
            {
              key: 'to_grid',
              percentage: 'production_to_grid_rate',
              label: 'widgets.communityStatsOverviewProduction.to_grid',
            },
          ],
          graphColors: ['self-consumption', 'battery', 'production'],
          title: 'widgets.communityStatsOverviewProduction.title',
          devID: 'zesty-marshmallow',
          devPath:
            'community-energy->default->communityStatsOverviewProduction',
        },
        {
          env: 'web',
          type: 'map',
          gradientBorder: false,
          id: 'map',
          devID: 'unruly-waffle',
          devPath: 'community-energy->default->map',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'totalSitesCard',
          id: 'totalSitesCard',
          totalValue: 'sites_count',
          icon: 'sites',
          descriptionType: 'totalSitesCard',
          description: [
            {
              key: 'widgets.totalSitesCard.description.default.consumer',
              value: 'consumer',
            },
            {
              key: 'widgets.totalSitesCard.description.default.producer',
              value: 'producer',
            },
            {
              key: 'widgets.totalSitesCard.description.default.prosumage',
              value: 'prosumage',
            },
            {
              key: 'widgets.totalSitesCard.description.default.prosumer',
              value: 'prosumer',
            },
          ],
          devID: 'ludicrous-jigsaw',
          devPath: 'community-energy->default->totalSitesCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communitySelfSufficiencyCard',
          id: 'communitySelfSufficiencyCard',
          totalValue: 'self_sufficiency_rate',
          value: 'self_consumption',
          icon: 'self_sufficiency',
          descriptionType: 'label+value+unit',
          description:
            'widgets.communitySelfSufficiencyCard.description.default',
          devID: 'waggish-lemonade',
          devPath: 'community-energy->default->communitySelfSufficiencyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communitySharedEnergyCard',
          id: 'communitySharedEnergyCard',
          totalValue: 'total_shared',
          icon: 'shared_energy',
          descriptionType: 'label',
          description: 'widgets.communitySharedEnergyCard.description.default',
          devID: 'jubilant-pajamas',
          devPath: 'community-energy->default->communitySharedEnergyCard',
        },
        {
          env: 'web',
          type: 'card',
          gradientBorder: false,
          detail: false,
          subtype: 'communityPollutionCard',
          id: 'communityPollutionCard',
          totalValue: 'co2_offset_kgs',
          trees: 'co2_offset_trees',
          icon: 'pollution',
          descriptionValue: 'co2_offset_trees',
          descriptionType: '{{trees}}',
          description: {
            line_1: 'widgets.pollutionCard.description.prosumer.line_1',
            line_2: 'widgets.pollutionCard.description.prosumer.line_2',
          },
          devID: 'zealous-yogurt',
          devPath: 'community-energy->default->communityPollutionCard',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          subtype: 'consumptionStackBar',
          id: 'consumptionStackBar',
          totalValue: 'consumption',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'self_consumption_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'total_shared',
              color: 'consumption',
              combined: false,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: false,
            },
          ],
          combinedKeys: [
            {
              key: 'total_shared',
              color: 'consumption',
              combined: true,
            },
            {
              key: 'from_grid',
              color: 'consumptionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '',
            },
            {
              color: 'consumption',
              colorShade: '60',
            },
          ],
          title: 'widgets.consumptionStackBar.title',
          devID: 'mystical-marshmallow',
          devPath: 'community-energy->default->consumptionStackBar',
        },
        {
          type: 'graphs',
          gradientBorder: false,
          env: 'web',
          subtype: 'productionStackBar',
          id: 'productionStackBar',
          totalValue: 'production',
          graphKeys: [
            {
              key: 'self_consumption_direct',
              color: 'self-consumption',
              combined: false,
            },
            {
              key: 'production_to_battery',
              color: 'battery',
              combined: false,
            },
            {
              key: 'total_shared',
              color: 'production',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          combinedKeys: [
            {
              key: 'total_shared',
              color: 'productionShared',
              combined: true,
            },
            {
              key: 'to_grid',
              color: 'productionShared',
              combined: true,
            },
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'production',
              colorShade: '',
            },
            {
              color: 'productionShared',
              colorShade: '',
            },
          ],
          title: 'widgets.productionStackBar.title',
          devID: 'nocturnal-flamingo',
          devPath: 'community-energy->default->productionStackBar',
        },
        {
          id: 'batteryChart',
          title: 'widgets.batteryChart.title',
          type: 'batteryChart',
          gradientBorder: false,
          context: 'community',
          buttons: ['widgets.batteryChart.self_consumption'],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
          ],
          db_fields: ['battery_net_flow'],
          devID: 'mirthful-quokka',
          devPath: 'community-energy->default->batteryChart',
        },
        {
          env: 'web',
          id: 'areaChartSelfConsumption',
          title: 'widgets.areaChartSelfConsumptionCommunity.title',
          type: 'areaChart',
          subtype: 'areaChart',
          gradientBorder: false,
          view: 'community',
          graphKeys: ['consumption', 'production', 'total_shared'],
          colors: ['consumption', 'production', 'shared'],
          devID: 'mystical-hamburger',
          devPath: 'community-energy->default->areaChartSelfConsumption',
        },
        {
          type: 'horizontalChartDouble',
          gradientBorder: false,
          subtype: 'horizontalChartDouble',
          id: 'horizontalChartDouble',
          context: 'energy',
          rankingKpi: 'consumption',
          kpiNameLabels: ['consumption', 'production'],
          rankingKpiName: [
            'widgets.horizontalChart.energy.consumption',
            'widgets.horizontalChart.energy.production',
          ],
          graphKeys: [
            [
              'self_consumption_direct',
              'self_consumption_battery',
              'from_shared',
              'from_grid',
            ],
            [
              'self_consumption_direct',
              'production_to_battery',
              'to_shared',
              'to_grid',
            ],
          ],
          combinedKeys: [
            ['from_shared', 'from_grid'],
            ['to_shared', 'to_grid'],
          ],
          buttons: [
            'widgets.horizontalChart.energy.self_consumption_direct',
            'widgets.horizontalChart.energy.self_consumption_battery',
            'widgets.horizontalChart.energy.from_shared',
            'widgets.horizontalChart.energy.from_grid',
          ],
          tooltipColors: [
            [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
          ],
          colors: [
            {
              color: 'self-consumption',
              colorShade: '',
            },
            {
              color: 'battery',
              colorShade: '',
            },
            {
              color: 'sharedGrid',
              colorShade: '',
            },
            {
              color: 'sharedGrid',
              colorShade: '60',
            },
          ],
          title: 'widgets.horizontalChart.energy.title',
          devID: 'turbulent-lollipop',
          devPath: 'community-energy->default->horizontalChartDouble',
        },
      ],
      administrator: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zippy-lollipop',
            devPath: 'community-energy->administrator->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'luminous-dragon',
            devPath:
              'community-energy->administrator->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'kooky-hamburger',
            devPath:
              'community-energy->administrator->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'rampaging-vortex',
            devPath: 'community-energy->administrator->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'giddy-waffle',
            devPath: 'community-energy->administrator->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'kooky-avocado',
            devPath:
              'community-energy->administrator->consumer->consumptionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: 'widgets.horizontalChart.energy.consumption',
            graphKeys: ['from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            buttons: [
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'rambunctious-waffle',
            devPath:
              'community-energy->administrator->consumer->horizontalChart',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rambunctious-lighthouse',
            devPath: 'community-energy->administrator->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'quirksome-vortex',
            devPath:
              'community-energy->administrator->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'waggish-glove',
            devPath:
              'community-energy->administrator->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'hysterical-glove',
            devPath: 'community-energy->administrator->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'nutty-giraffe',
            devPath: 'community-energy->administrator->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'waggish-cabbage',
            devPath:
              'community-energy->administrator->producer->productionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'production',
            rankingKpiName: 'widgets.horizontalChart.energy.production',
            graphKeys: ['production_to_battery', 'to_shared', 'to_grid'],
            combinedKeys: ['to_shared', 'to_grid'],
            buttons: [
              'widgets.horizontalChart.energy.to_shared',
              'widgets.horizontalChart.energy.to_grid',
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'frisky-pencil',
            devPath:
              'community-energy->administrator->producer->horizontalChart',
          },
        ],
        consumer_producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rampaging-kiwi',
            devPath:
              'community-energy->administrator->consumer_producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'playful-nacho',
            devPath:
              'community-energy->administrator->consumer_producer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'daring-firecracker',
            devPath:
              'community-energy->administrator->consumer_producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.consumer.key_1',
            devID: 'zealous-igloo',
            devPath:
              'community-energy->administrator->consumer_producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'unruly-ukulele',
            devPath:
              'community-energy->administrator->consumer_producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'luminous-zeppelin',
            devPath: 'community-energy->administrator->consumer_producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'eccentric-cactus',
            devPath:
              'community-energy->administrator->consumer_producer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'bizarre-kangaroo',
            devPath:
              'community-energy->administrator->consumer_producer->productionStackBar',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'frisky-vortex',
            devPath:
              'community-energy->administrator->consumer_producer->horizontalChartDouble',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'angry-octopus',
            devPath: 'community-energy->administrator->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.communityStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'maniacal-obelisk',
            devPath:
              'community-energy->administrator->prosumer->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProductionWeb',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.communityStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'angry-tangerine',
            devPath:
              'community-energy->administrator->prosumer->communityStatsOverviewProductionWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.prosumer',
            devID: 'witty-spoon',
            devPath:
              'community-energy->administrator->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.default',
            devID: 'golden-espresso',
            devPath:
              'community-energy->administrator->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'wondrous-lollipop',
            devPath: 'community-energy->administrator->prosumer->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'mystical-butterfly',
            devPath:
              'community-energy->administrator->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'giddy-kiwi',
            devPath:
              'community-energy->administrator->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'sharedGrid'],
            graphKeys: ['consumption', 'production', 'total_shared'],
            devID: 'turbulent-lemonade',
            devPath:
              'community-energy->administrator->prosumer->areaChartSelfConsumptionSite',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'silly-espresso',
            devPath:
              'community-energy->administrator->prosumer->horizontalChartDouble',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'playful-yogurt',
            devPath: 'community-energy->administrator->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            values: [
              {
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
                totalPercentage: 'self_consumption_direct',
                id: 'self_consumption_direct',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: 'self-consumption',
                  },
                ],
              },
              {
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_battery',
                totalPercentage: 'self_consumption_battery',
                id: 'self_consumption_battery',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'self_consumption_battery',
                    color: 'battery',
                  },
                ],
              },
              {
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
                totalPercentage: 'from_grid',
                id: 'from_grid',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'self_consumption_battery',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'from_grid',
                    color: 'consumption',
                  },
                ],
              },
            ],
            devID: 'eccentric-shoe',
            devPath:
              'community-energy->administrator->prosumage->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'whacky-buffet',
            devPath:
              'community-energy->administrator->prosumage->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'nutty-cat',
            devPath: 'community-energy->administrator->prosumage->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'mirthful-pencil',
            devPath:
              'community-energy->administrator->prosumage->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.communitySharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.communitySharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'hysterical-pajamas',
            devPath:
              'community-energy->administrator->prosumage->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'bizarre-avocado',
            devPath:
              'community-energy->administrator->prosumage->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'peculiar-vortex',
            devPath:
              'community-energy->administrator->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'ludicrous-buffet',
            devPath:
              'community-energy->administrator->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'boisterous-lollipop',
            devPath: 'community-energy->administrator->prosumage->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'zany-lighthouse',
            devPath:
              'community-energy->administrator->prosumage->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'zany-gumball',
            devPath:
              'community-energy->administrator->prosumage->horizontalChartDouble',
          },
        ],
        prosumer_battery: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'nocturnal-cabbage',
            devPath:
              'community-energy->administrator->prosumer_battery->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'clumsy-cat',
            devPath:
              'community-energy->administrator->prosumer_battery->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'peculiar-glove',
            devPath:
              'community-energy->administrator->prosumer_battery->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community',
            devID: 'quirky-giraffe',
            devPath: 'community-energy->administrator->prosumer_battery->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'oddball-avocado',
            devPath:
              'community-energy->administrator->prosumer_battery->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.default',
            devID: 'boisterous-brick',
            devPath:
              'community-energy->administrator->prosumer_battery->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'daring-obelisk',
            devPath:
              'community-energy->administrator->prosumer_battery->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'ferocious-kiwi',
            devPath:
              'community-energy->administrator->prosumer_battery->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'whacky-xylophone',
            devPath:
              'community-energy->administrator->prosumer_battery->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'clumsy-buffet',
            devPath:
              'community-energy->administrator->prosumer_battery->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'total_shared'],
            colors: ['consumption', 'production', 'sharedGrid'],
            devID: 'cannibal-nacho',
            devPath:
              'community-energy->administrator->prosumer_battery->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'wild-avocado',
            devPath:
              'community-energy->administrator->prosumer_battery->horizontalChartDouble',
          },
        ],
        consumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'jubilant-iceberg',
            devPath:
              'community-energy->administrator->consumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'ludicrous-tangerine',
            devPath:
              'community-energy->administrator->consumer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'golden-narwhal',
            devPath:
              'community-energy->administrator->consumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'giddy-jigsaw',
            devPath:
              'community-energy->administrator->consumer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'boisterous-saxophone',
            devPath: 'community-energy->administrator->consumer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'whacky-marshmallow',
            devPath:
              'community-energy->administrator->consumer_short->consumptionStackBar',
          },
        ],
        producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'witty-marshmallow',
            devPath:
              'community-energy->administrator->producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'glowing-yogurt',
            devPath:
              'community-energy->administrator->producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'whimsical-xylophone',
            devPath:
              'community-energy->administrator->producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'glowing-hamburger',
            devPath:
              'community-energy->administrator->producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'zealous-lollipop',
            devPath: 'community-energy->administrator->producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'quirky-brick',
            devPath:
              'community-energy->administrator->producer_short->productionStackBar',
          },
        ],
        consumer_producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'mystical-kiwi',
            devPath:
              'community-energy->administrator->consumer_producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'daring-rhinoceros',
            devPath:
              'community-energy->administrator->consumer_producer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'eccentric-lighthouse',
            devPath:
              'community-energy->administrator->consumer_producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.consumer.key_1',
            devID: 'daring-flamingo',
            devPath:
              'community-energy->administrator->consumer_producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'waggish-tangerine',
            devPath:
              'community-energy->administrator->consumer_producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'playful-xylophone',
            devPath:
              'community-energy->administrator->consumer_producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'vivacious-jellyfish',
            devPath:
              'community-energy->administrator->consumer_producer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'daring-giraffe',
            devPath:
              'community-energy->administrator->consumer_producer_short->productionStackBar',
          },
        ],
        prosumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'glowing-waffle',
            devPath:
              'community-energy->administrator->prosumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.communityStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'happy-hamburger',
            devPath:
              'community-energy->administrator->prosumer_short->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.communityStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'spritely-tangerine',
            devPath:
              'community-energy->administrator->prosumer_short->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'waggish-ferret',
            devPath:
              'community-energy->administrator->prosumer_short->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'rampaging-cat',
            devPath:
              'community-energy->administrator->prosumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'eccentric-nacho',
            devPath:
              'community-energy->administrator->prosumer_short->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'rowdy-kangaroo',
            devPath:
              'community-energy->administrator->prosumer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'rambunctious-avocado',
            devPath:
              'community-energy->administrator->prosumer_short->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'impish-dinosaur',
            devPath:
              'community-energy->administrator->prosumer_short->areaChartSelfConsumptionSite',
          },
        ],
        prosumage_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rowdy-xylophone',
            devPath:
              'community-energy->administrator->prosumage_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                precentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'impish-ferret',
            devPath:
              'community-energy->administrator->prosumage_short->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community-energy',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'nocturnal-spoon',
            devPath:
              'community-energy->administrator->prosumage_short->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'daring-gumball',
            devPath: 'community-energy->administrator->prosumage_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'rowdy-igloo',
            devPath:
              'community-energy->administrator->prosumage_short->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.communitySharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.communitySharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'witty-firecracker',
            devPath:
              'community-energy->administrator->prosumage_short->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1:
                'widgets.communityPollutionCard.description.prosumer.line_1',
              line_2:
                'widgets.communityPollutionCard.description.prosumer.line_2',
            },
            devID: 'mirthful-hedgehog',
            devPath:
              'community-energy->administrator->prosumage_short->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'wondrous-dragon',
            devPath:
              'community-energy->administrator->prosumage_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'angry-zeppelin',
            devPath:
              'community-energy->administrator->prosumage_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'ludicrous-lollipop',
            devPath:
              'community-energy->administrator->prosumage_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'total_shared'],
            colors: ['consumption', 'production', 'sharedGrid'],
            devID: 'wondrous-banana',
            devPath:
              'community-energy->administrator->prosumage_short->areaChartSelfConsumption',
          },
        ],
        prosumer_battery_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'jazzy-hedgehog',
            devPath:
              'community-energy->administrator->prosumer_battery_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'mystical-zeppelin',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                precentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'ferocious-obelisk',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'rambunctious-mango',
            devPath:
              'community-energy->administrator->prosumer_battery_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'whacky-hedgehog',
            devPath:
              'community-energy->administrator->prosumer_battery_short->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'silly-dragon',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'rowdy-tangerine',
            devPath:
              'community-energy->administrator->prosumer_battery_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'zany-espresso',
            devPath:
              'community-energy->administrator->prosumer_battery_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'luminous-cat',
            devPath:
              'community-energy->administrator->prosumer_battery_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'rampaging-shoe',
            devPath:
              'community-energy->administrator->prosumer_battery_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'total_sahred'],
            colors: ['consumption', 'production', 'sharedGrid'],
            devID: 'kooky-espresso',
            devPath:
              'community-energy->administrator->prosumer_battery_short->areaChartSelfConsumption',
          },
        ],
      },
      supervisor: {
        consumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'unruly-firecracker',
            devPath: 'community-energy->supervisor->consumer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'zippy-vortex',
            devPath:
              'community-energy->supervisor->consumer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'golden-saxophone',
            devPath: 'community-energy->supervisor->consumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'nocturnal-mango',
            devPath: 'community-energy->supervisor->consumer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'daring-penguin',
            devPath: 'community-energy->supervisor->consumer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'wild-yogurt',
            devPath:
              'community-energy->supervisor->consumer->consumptionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: 'widgets.horizontalChart.energy.consumption',
            graphKeys: ['from_shared', 'from_grid'],
            combinedKeys: ['from_shared', 'from_grid'],
            buttons: [
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'golden-enchilada',
            devPath: 'community-energy->supervisor->consumer->horizontalChart',
          },
        ],
        producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'bizarre-obelisk',
            devPath: 'community-energy->supervisor->producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'rampaging-enchilada',
            devPath:
              'community-energy->supervisor->producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'daring-butterfly',
            devPath: 'community-energy->supervisor->producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'giddy-saxophone',
            devPath: 'community-energy->supervisor->producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'oddball-enchilada',
            devPath: 'community-energy->supervisor->producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'quirky-jellyfish',
            devPath:
              'community-energy->supervisor->producer->productionStackBar',
          },
          {
            type: 'horizontalChart',
            gradientBorder: false,
            subtype: 'horizontalChart',
            id: 'horizontalChart',
            context: 'energy',
            rankingKpi: 'production',
            rankingKpiName: 'widgets.horizontalChart.energy.production',
            graphKeys: ['production_to_battery', 'to_shared', 'to_grid'],
            combinedKeys: ['to_shared', 'to_grid'],
            buttons: [
              'widgets.horizontalChart.energy.to_shared',
              'widgets.horizontalChart.energy.to_grid',
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'vivacious-enchilada',
            devPath: 'community-energy->supervisor->producer->horizontalChart',
          },
        ],
        consumer_producer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rampaging-lemonade',
            devPath:
              'community-energy->supervisor->consumer_producer->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'happy-jigsaw',
            devPath:
              'community-energy->supervisor->consumer_producer->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'mirthful-lighthouse',
            devPath:
              'community-energy->supervisor->consumer_producer->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.consumer.key_1',
            devID: 'oddball-enchilada',
            devPath:
              'community-energy->supervisor->consumer_producer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'bizarre-pencil',
            devPath:
              'community-energy->supervisor->consumer_producer->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'angry-butterfly',
            devPath: 'community-energy->supervisor->consumer_producer->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'luminous-shoe',
            devPath:
              'community-energy->supervisor->consumer_producer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'peculiar-obelisk',
            devPath:
              'community-energy->supervisor->consumer_producer->productionStackBar',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'jubilant-obelisk',
            devPath:
              'community-energy->supervisor->consumer_producer->horizontalChartDouble',
          },
        ],
        prosumer: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'mirthful-obelisk',
            devPath: 'community-energy->supervisor->prosumer->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.communityStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'angry-avocado',
            devPath:
              'community-energy->supervisor->prosumer->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProductionWeb',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.communityStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'ludicrous-kangaroo',
            devPath:
              'community-energy->supervisor->prosumer->communityStatsOverviewProductionWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.prosumer',
            devID: 'oddball-brick',
            devPath:
              'community-energy->supervisor->prosumer->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.default',
            devID: 'zesty-obelisk',
            devPath: 'community-energy->supervisor->prosumer->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'giddy-brick',
            devPath: 'community-energy->supervisor->prosumer->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'luminous-lemonade',
            devPath:
              'community-energy->supervisor->prosumer->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'eccentric-enchilada',
            devPath:
              'community-energy->supervisor->prosumer->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'sharedGrid'],
            graphKeys: ['consumption', 'production', 'total_shared'],
            devID: 'happy-butterfly',
            devPath:
              'community-energy->supervisor->prosumer->areaChartSelfConsumptionSite',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              ['self_consumption_direct', 'from_shared', 'from_grid'],
              ['self_consumption_direct', 'to_shared', 'to_grid'],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'vivacious-penguin',
            devPath:
              'community-energy->supervisor->prosumer->horizontalChartDouble',
          },
        ],
        prosumage: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'hysterical-brick',
            devPath: 'community-energy->supervisor->prosumage->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            values: [
              {
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
                totalPercentage: 'self_consumption_direct',
                id: 'self_consumption_direct',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: 'self-consumption',
                  },
                ],
              },
              {
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_battery',
                totalPercentage: 'self_consumption_battery',
                id: 'self_consumption_battery',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'self_consumption_battery',
                    color: 'battery',
                  },
                ],
              },
              {
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
                totalPercentage: 'from_grid',
                id: 'from_grid',
                progress: [
                  {
                    value: 'self_consumption_direct',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'self_consumption_battery',
                    color: '#F0F0F0',
                  },
                  {
                    value: 'from_grid',
                    color: 'consumption',
                  },
                ],
              },
            ],
            devID: 'wild-octopus',
            devPath:
              'community-energy->supervisor->prosumage->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'quirksome-nacho',
            devPath:
              'community-energy->supervisor->prosumage->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'wondrous-waffle',
            devPath: 'community-energy->supervisor->prosumage->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'cannibal-rhinoceros',
            devPath:
              'community-energy->supervisor->prosumage->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.communitySharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.communitySharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'zany-pencil',
            devPath:
              'community-energy->supervisor->prosumage->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'clumsy-hedgehog',
            devPath:
              'community-energy->supervisor->prosumage->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'zealous-octopus',
            devPath:
              'community-energy->supervisor->prosumage->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'glowing-gumball',
            devPath:
              'community-energy->supervisor->prosumage->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'impish-xylophone',
            devPath: 'community-energy->supervisor->prosumage->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'self_consumption'],
            colors: ['consumption', 'production', 'shared'],
            devID: 'maniacal-spoon',
            devPath:
              'community-energy->supervisor->prosumage->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'ferocious-cactus',
            devPath:
              'community-energy->supervisor->prosumage->horizontalChartDouble',
          },
        ],
        prosumer_battery: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'happy-kiwi',
            devPath:
              'community-energy->supervisor->prosumer_battery->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'zealous-kiwi',
            devPath:
              'community-energy->supervisor->prosumer_battery->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'unruly-octopus',
            devPath:
              'community-energy->supervisor->prosumer_battery->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community',
            devID: 'eccentric-hedgehog',
            devPath: 'community-energy->supervisor->prosumer_battery->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'fantastic-vortex',
            devPath:
              'community-energy->supervisor->prosumer_battery->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.default',
            devID: 'ludicrous-giraffe',
            devPath:
              'community-energy->supervisor->prosumer_battery->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'spritely-butterfly',
            devPath:
              'community-energy->supervisor->prosumer_battery->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'mirthful-narwhal',
            devPath:
              'community-energy->supervisor->prosumer_battery->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'rampaging-nacho',
            devPath:
              'community-energy->supervisor->prosumer_battery->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'whacky-igloo',
            devPath:
              'community-energy->supervisor->prosumer_battery->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'total_shared'],
            colors: ['consumption', 'production', 'sharedGrid'],
            devID: 'oddball-saxophone',
            devPath:
              'community-energy->supervisor->prosumer_battery->areaChartSelfConsumption',
          },
          {
            type: 'horizontalChartDouble',
            gradientBorder: false,
            subtype: 'horizontalChartDouble',
            id: 'horizontalChartDouble',
            context: 'energy',
            rankingKpi: 'consumption',
            kpiNameLabels: ['consumption', 'production'],
            rankingKpiName: [
              'widgets.horizontalChart.energy.consumption',
              'widgets.horizontalChart.energy.production',
            ],
            graphKeys: [
              [
                'self_consumption_direct',
                'self_consumption_battery',
                'from_shared',
                'from_grid',
              ],
              [
                'self_consumption_direct',
                'production_to_battery',
                'to_shared',
                'to_grid',
              ],
            ],
            combinedKeys: [
              ['from_shared', 'from_grid'],
              ['to_shared', 'to_grid'],
            ],
            buttons: [
              'widgets.horizontalChart.energy.self_consumption_direct',
              'widgets.horizontalChart.energy.self_consumption_battery',
              'widgets.horizontalChart.energy.from_shared',
              'widgets.horizontalChart.energy.from_grid',
            ],
            tooltipColors: [
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '',
                },
                {
                  color: 'consumption',
                  colorShade: '60',
                },
              ],
              [
                {
                  color: 'self-consumption',
                  colorShade: '',
                },
                {
                  color: 'battery',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '',
                },
                {
                  color: 'production',
                  colorShade: '60',
                },
              ],
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '',
              },
              {
                color: 'sharedGrid',
                colorShade: '60',
              },
            ],
            title: 'widgets.horizontalChart.energy.title',
            devID: 'whimsical-rhinoceros',
            devPath:
              'community-energy->supervisor->prosumer_battery->horizontalChartDouble',
          },
        ],
        consumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'zesty-narwhal',
            devPath: 'community-energy->supervisor->consumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'oddball-zeppelin',
            devPath:
              'community-energy->supervisor->consumer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description: 'widgets.sharedEnergyCard.description.consumer.key_1',
            devID: 'zealous-ferret',
            devPath:
              'community-energy->supervisor->consumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.consumer.line_1',
              line_2: 'widgets.pollutionCard.description.consumer.line_2',
            },
            devID: 'wondrous-iceberg',
            devPath:
              'community-energy->supervisor->consumer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'frisky-marshmallow',
            devPath: 'community-energy->supervisor->consumer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'silly-gumball',
            devPath:
              'community-energy->supervisor->consumer_short->consumptionStackBar',
          },
        ],
        producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'luminous-saxophone',
            devPath: 'community-energy->supervisor->producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'daring-banana',
            devPath:
              'community-energy->supervisor->producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'keyValue',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.producer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'kooky-kiwi',
            devPath:
              'community-energy->supervisor->producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'nocturnal-penguin',
            devPath:
              'community-energy->supervisor->producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'maniacal-kangaroo',
            devPath: 'community-energy->supervisor->producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: false,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: false,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'mystical-gumball',
            devPath:
              'community-energy->supervisor->producer_short->productionStackBar',
          },
        ],
        consumer_producer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'peculiar-flamingo',
            devPath:
              'community-energy->supervisor->consumer_producer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalConsumptionCard',
            id: 'totalConsumptionCard',
            totalValue: 'consumption',
            icon: 'house_import',
            descriptionValue: 'consumption',
            descriptionType: 'label',
            description: 'widgets.totalConsumptionCard.description.consumer',
            devID: 'turbulent-xylophone',
            devPath:
              'community-energy->supervisor->consumer_producer_short->totalConsumptionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'totalProductionCard',
            id: 'totalProductionCard',
            totalValue: 'production',
            icon: 'house_export',
            descriptionValue: 'production',
            descriptionType: 'label',
            description: 'widgets.totalProductionCard.description.producer',
            devID: 'wild-spoon',
            devPath:
              'community-energy->supervisor->consumer_producer_short->totalProductionCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'label',
            description:
              'widgets.communitySharedEnergyCard.description.consumer.key_1',
            devID: 'mirthful-espresso',
            devPath:
              'community-energy->supervisor->consumer_producer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.producer.line_1',
              line_2: 'widgets.pollutionCard.description.producer.line_2',
            },
            devID: 'cannibal-giraffe',
            devPath:
              'community-energy->supervisor->consumer_producer_short->pollutionCard',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            context: 'community-energy',
            devID: 'mirthful-waffle',
            devPath:
              'community-energy->supervisor->consumer_producer_short->map',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumptionShared',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'happy-octopus',
            devPath:
              'community-energy->supervisor->consumer_producer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'productionShared',
                colorShade: '',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'peculiar-zeppelin',
            devPath:
              'community-energy->supervisor->consumer_producer_short->productionStackBar',
          },
        ],
        prosumer_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'nutty-obelisk',
            devPath: 'community-energy->supervisor->prosumer_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.communityStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'happy-cat',
            devPath:
              'community-energy->supervisor->prosumer_short->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.communityStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.communityStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'wondrous-obelisk',
            devPath:
              'community-energy->supervisor->prosumer_short->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'selfSufficiencyCard',
            id: 'selfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description: 'widgets.selfSufficiencyCard.description.prosumer',
            devID: 'silly-buffet',
            devPath:
              'community-energy->supervisor->prosumer_short->selfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'sharedEnergyCard',
            id: 'sharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.sharedEnergyCard.description.prosumer.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'zany-hedgehog',
            devPath:
              'community-energy->supervisor->prosumer_short->sharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'pollutionCard',
            id: 'pollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.pollutionCard.description.prosumer.line_1',
              line_2: 'widgets.pollutionCard.description.prosumer.line_2',
            },
            devID: 'luminous-lemonade',
            devPath:
              'community-energy->supervisor->prosumer_short->pollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'whacky-kangaroo',
            devPath:
              'community-energy->supervisor->prosumer_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'giddy-dragon',
            devPath:
              'community-energy->supervisor->prosumer_short->productionStackBar',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumptionSite',
            title: 'widgets.areaChartSelfConsumptionSite.title',
            type: 'areachart',
            subtype: 'areachart',
            gradientBorder: false,
            colors: ['consumption', 'production', 'self-consumption'],
            graphKeys: ['consumption', 'production', 'self_consumption'],
            devID: 'maniacal-nacho',
            devPath:
              'community-energy->supervisor->prosumer_short->areaChartSelfConsumptionSite',
          },
        ],
        prosumage_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'waggish-igloo',
            devPath: 'community-energy->supervisor->prosumage_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'community',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                precentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.communityStatsOverviewConsumption.title',
            devID: 'angry-xylophone',
            devPath:
              'community-energy->supervisor->prosumage_short->communityStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'communityStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'community-energy',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                percentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.communityStatsOverviewProduction.title',
            devID: 'maniacal-cabbage',
            devPath:
              'community-energy->supervisor->prosumage_short->communityStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'nutty-octopus',
            devPath: 'community-energy->supervisor->prosumage_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'rampaging-giraffe',
            devPath:
              'community-energy->supervisor->prosumage_short->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySharedEnergyCard',
            id: 'communitySharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.communitySharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.communitySharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'eccentric-brick',
            devPath:
              'community-energy->supervisor->prosumage_short->communitySharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communityPollutionCard',
            id: 'communityPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1:
                'widgets.communityPollutionCard.description.prosumer.line_1',
              line_2:
                'widgets.communityPollutionCard.description.prosumer.line_2',
            },
            devID: 'witty-waffle',
            devPath:
              'community-energy->supervisor->prosumage_short->communityPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'happy-spoon',
            devPath:
              'community-energy->supervisor->prosumage_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'hysterical-xylophone',
            devPath:
              'community-energy->supervisor->prosumage_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'community',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'rampaging-dragon',
            devPath:
              'community-energy->supervisor->prosumage_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'total_shared'],
            colors: ['consumption', 'production', 'sharedGrid'],
            devID: 'nutty-tangerine',
            devPath:
              'community-energy->supervisor->prosumage_short->areaChartSelfConsumption',
          },
        ],
        prosumer_battery_short: [
          {
            type: 'headerWeb',
            id: 'headerWeb',
            data: {
              label: 'community-energy',
              breadCrumbs: true,
              navigation: true,
              calendar: true,
              download: false,
              searchBar: true,
              profile: true,
            },
            devID: 'rampaging-firecracker',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->headerWeb',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewConsumption',
            totalValue: 'consumption',
            color: 'consumption',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'consumption_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_direct',
              },
              {
                key: 'self_consumption_battery',
                percentage: 'consumption_self_consumption_battery_rate',
                label:
                  'widgets.memberStatsOverviewConsumption.self_consumption_battery',
              },
              {
                key: 'from_grid',
                percentage: 'consumption_from_grid_rate',
                label: 'widgets.memberStatsOverviewConsumption.from_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'consumption'],
            title: 'widgets.memberStatsOverviewConsumption.title',
            devID: 'witty-kangaroo',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->memberStatsOverviewConsumption',
          },
          {
            env: 'web',
            type: 'statsOverviewWeb',
            subtype: 'statsOverviewWeb',
            id: 'memberStatsOverviewProduction',
            totalValue: 'production',
            color: 'production',
            view: 'member',
            graphDataKeys: [
              {
                key: 'self_consumption_direct',
                percentage: 'production_self_consumption_direct_rate',
                label:
                  'widgets.memberStatsOverviewProduction.self_consumption_direct',
              },
              {
                key: 'production_to_battery',
                precentage: 'production_to_battery_rate',
                label:
                  'widgets.memberStatsOverviewProduction.production_to_battery',
              },
              {
                key: 'to_grid',
                percentage: 'production_to_grid_rate',
                label: 'widgets.memberStatsOverviewProduction.to_grid',
              },
            ],
            graphColors: ['self-consumption', 'battery', 'production'],
            title: 'widgets.memberStatsOverviewProduction.title',
            devID: 'zany-flamingo',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->memberStatsOverviewProduction',
          },
          {
            env: 'web',
            type: 'map',
            gradientBorder: false,
            id: 'map',
            devID: 'daring-octopus',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->map',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'communitySelfSufficiencyCard',
            id: 'communitySelfSufficiencyCard',
            totalValue: 'self_sufficiency_rate',
            value: 'self_consumption',
            icon: 'self_sufficiency',
            descriptionType: 'label+value+unit',
            description:
              'widgets.communitySelfSufficiencyCard.description.default',
            devID: 'nutty-cactus',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->communitySelfSufficiencyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberSharedEnergyCard',
            id: 'memberSharedEnergyCard',
            totalValue: 'total_shared',
            icon: 'shared_energy',
            descriptionType: 'web:label+value|label+value',
            description: [
              {
                key: 'widgets.memberSharedEnergyCard.description.key_1',
                value: 'from_shared',
              },
              {
                key: 'widgets.memberSharedEnergyCard.description.key_2',
                value: 'to_shared',
              },
            ],
            devID: 'bizarre-xylophone',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->memberSharedEnergyCard',
          },
          {
            env: 'web',
            type: 'card',
            gradientBorder: false,
            detail: false,
            subtype: 'memberPollutionCard',
            id: 'memberPollutionCard',
            totalValue: 'co2_offset_kgs',
            trees: 'co2_offset_trees',
            icon: 'pollution',
            descriptionValue: 'co2_offset_trees',
            descriptionType: '{{trees}}',
            description: {
              line_1: 'widgets.memberPollutionCard.description.prosumer.line_1',
              line_2: 'widgets.memberPollutionCard.description.prosumer.line_2',
            },
            devID: 'mirthful-lollipop',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->memberPollutionCard',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            subtype: 'consumptionStackBar',
            id: 'consumptionStackBar',
            totalValue: 'consumption',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'self_consumption_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'consumption',
                combined: true,
              },
              {
                key: 'from_grid',
                color: 'consumptionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '',
              },
              {
                color: 'consumption',
                colorShade: '60',
              },
            ],
            title: 'widgets.consumptionStackBar.title',
            devID: 'cannibal-igloo',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->consumptionStackBar',
          },
          {
            type: 'graphs',
            gradientBorder: false,
            env: 'web',
            subtype: 'productionStackBar',
            id: 'productionStackBar',
            totalValue: 'production',
            graphKeys: [
              {
                key: 'self_consumption_direct',
                color: 'self-consumption',
                combined: false,
              },
              {
                key: 'production_to_battery',
                color: 'battery',
                combined: false,
              },
              {
                key: 'total_shared',
                color: 'production',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            combinedKeys: [
              {
                key: 'total_shared',
                color: 'productionShared',
                combined: true,
              },
              {
                key: 'to_grid',
                color: 'productionShared',
                combined: true,
              },
            ],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
              {
                color: 'battery',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '',
              },
              {
                color: 'production',
                colorShade: '60',
              },
            ],
            title: 'widgets.productionStackBar.title',
            devID: 'frisky-hamburger',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->productionStackBar',
          },
          {
            id: 'batteryChart',
            title: 'widgets.batteryChart.title',
            type: 'batteryChart',
            gradientBorder: false,
            context: 'member',
            buttons: ['widgets.batteryChart.self_consumption'],
            colors: [
              {
                color: 'self-consumption',
                colorShade: '',
              },
            ],
            db_fields: ['battery_net_flow'],
            devID: 'nocturnal-quokka',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->batteryChart',
          },
          {
            env: 'web',
            id: 'areaChartSelfConsumption',
            title: 'widgets.areaChartSelfConsumptionCommunity.title',
            type: 'areaChart',
            subtype: 'areaChart',
            gradientBorder: false,
            view: 'community',
            graphKeys: ['consumption', 'production', 'total_sahred'],
            colors: ['consumption', 'production', 'sharedGrid'],
            devID: 'ludicrous-dinosaur',
            devPath:
              'community-energy->supervisor->prosumer_battery_short->areaChartSelfConsumption',
          },
        ],
      },
    },
  },
];
