
import { httpService } from "@flexo/general";
import { AlertTypes, Community } from "@flexo/providers";
import { METADATA, store } from "@flexo/reducers";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { EntitiesData, Member, StructuredData } from "./interfaces";

const ENV = (import.meta as any).env;

export function structureData(data: EntitiesData): any {
  const communityMap = new Map<string, Community|any>();
  const memberMap = new Map<string, Member|any>();
  const assignedMemberIds = new Set<string>();
  const assignedSiteIds = new Set<string>();
  const assignedEvIds = new Set<string>();
  const assignedWallboxIds = new Set<string>();


  if (!data || Object.keys(data)?.length === 0) {
    return;
  }

  // Create community map
  (data.communities || data.community ).forEach((community) => {
    communityMap.set(community.communityId, { ...community, members: [] as any });
  });

  // Create member map
  (data.members|| data.member).forEach((member) => {
    memberMap.set(member.memberId, { ...member, sites: [], evs: [], wallboxes: [] });

    // Associate members with communities directly if they have a communityId
    if (member?.communityId && communityMap.has(member?.communityId)) {
      const community = communityMap.get(member?.communityId);
      community?.members.push(memberMap.get(member?.memberId));
      assignedMemberIds?.add(member?.memberId);
    }
  });

  // Associate sites with members and track assigned site IDs
  (data.sites|| data.site).forEach((site: any) => {
    if (memberMap.has(site.memberId)) {
      const member = memberMap.get(site.memberId);
      member.sites.push(site);
      assignedSiteIds.add(site.siteExternalId);

      // Associate members with communities via sites if not already associated
      if (!member.communityId && communityMap.has(site.communityId)) {
        const community = communityMap.get(site.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Associate EVs with members and track assigned EV IDs
  data?.ev.forEach((ev) => {
    if (memberMap.has(ev.memberId)) {
      const member = memberMap.get(ev.memberId);
      member.evs.push(ev);
      assignedEvIds.add(ev.evId);

      // Associate members with communities via EVs if not already associated
      if (!member.communityId && communityMap.has(ev.communityId)) {
        const community = communityMap.get(ev.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Associate Wallboxes with members and track assigned Wallbox IDs
  data?.wallbox.forEach((wallbox) => {
    if (memberMap.has(wallbox.memberId)) {
      const member = memberMap.get(wallbox.memberId);
      member.wallboxes.push(wallbox);
      assignedWallboxIds.add(wallbox.wallboxId);

      // Associate members with communities via Wallboxes if not already associated
      if (!member.communityId && communityMap.has(wallbox.communityId)) {
        const community = communityMap.get(wallbox.communityId);
        if (!community.members.some((m) => m.memberId === member.memberId)) {
          community.members.push(member);
          assignedMemberIds.add(member.memberId);
        }
      }
    }
  });

  // Structure the data
  const structuredData: StructuredData = {
    communities: [],
    unassignedMembers: [],
    unassignedSites: [],
    unassignedEVs: [],
    unassignedWallboxes: [],
  };

  communityMap.forEach((community) => {
    structuredData.communities.push(community);
  });

  memberMap.forEach((member) => {
    if (!assignedMemberIds.has(member.memberId)) {
      structuredData.unassignedMembers.push(member);
    }
  });

  data?.sites?.forEach((site) => {
    if (!assignedSiteIds.has(site.siteExternalId)) {
      structuredData.unassignedSites.push(site);
    }
  });

  data?.ev?.forEach((ev) => {
    if (!assignedEvIds.has(ev.evId)) {
      structuredData.unassignedEVs.push(ev);
    }
  });

  data?.wallbox?.forEach((wallbox) => {
    if (!assignedWallboxIds.has(wallbox.wallboxId)) {
      structuredData.unassignedWallboxes.push(wallbox);
    }
  });

  return structuredData;
}


export const SetSelectedEntityAsync = createAsyncThunk(
  "metadata/SetSelectedEntityAsync",
  async function ( data: any) {
    return {
      ...data,
    };
  })

export const SetEntitiesAsync = createAsyncThunk(
  "metadata/SetEntitiesAsync",
  async function (data: any) {

    const { idToken, setAlertStore } = data;
    const dispatch = store.dispatch;
    
    const [entities] = await Promise.all([
          httpService
            .get({
              url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/entities`,
              idToken,
              mock: false,
            })
            .then((res: any) => {
              if (res.status === 200) {
    
                // TO DO: remove this atrocious work around
    
                return {
                  ...res.data,
                  sites: res.data.site,
                  communities: res.data.community,
                  members: res.data.member,
                };
              } else {
                // console.warn({
                //   status: res.status,
                //   message: res.statusText,
                // });
    
                setAlertStore({
                  type: AlertTypes.SetAlert,
                  payload: {
                    value: {
                      type: 'ALERT_SESSION_EXPIRED'
                    },
                  },
                })
    
                return null;
              }
            })
            .catch((err: any) => {
                        
              setAlertStore({
                type: AlertTypes.SetAlert,
                payload: {
                  value: {
                    type: 'ALERT_UNEXPECTED_ERROR'
                  },
                }
              })
    
    
              return null;
            }),
    ]);
    
    dispatch( METADATA.SetSortedEntities( structureData(entities) ) )

    return entities;
  })

export const SetMembersAsync = createAsyncThunk(
  "metadata/SetMembersAsync",
  async function (data: any) {

    const { idToken, setAlertStore, COMMUNITY_ID } = data;
    const dispatch = store.dispatch;
    
     const [members] = await Promise.all([
          httpService
            .get({
              url: `${ENV.VITE_APP_BASE_URL}/hive/api/catalog/v1_3/community/${COMMUNITY_ID}/members`,
              // /hive/api/catalog/v1_3/community/{communityId}/members
              idToken,
            })
            .then((res: any) => {
              if (res.status === 200) {
                return res.data;
              } else {
                // console.warn({
                //   status: res.status,
                //   message: res.statusText,
                // });
                return null;
              }
            })
            .catch((err: any) => {
              // console.warn(err);
    
              setAlertStore({
                type: AlertTypes.SetAlert,
                payload: {
                  value: {
                    type: 'ALERT_UNEXPECTED_ERROR'
                  },
                }
              })
    
              return null;
            }),
        ]);
    
        dispatch(METADATA.SetFilteredMembers(members))

    return members;
  })