

import queryString from 'query-string';
import * as luxon from 'luxon';

import {
  IonApp,
  IonContent,
  setupIonicReact,
} from '@ionic/react';

import * as Sentry from '@sentry/react';

import { useCallback, useEffect, useState } from 'react';
import {
  AlertProvider,
  ModalProvider,
} from '@flexo/providers';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import i18next from 'i18next';
import jwt_decode from 'jwt-decode';

import { Modules } from '@flexo/templates';

// import * as JAIL from '@swernimont/capacitor-jailbreak-root-detection';

import './app.module.scss';
import '../assets/fonts.scss';
import '../assets/typography.scss';
import '../assets/colors.scss';
// eslint-disable-next-line
import { default as COLORS } from '../assets/colors';
import '@ionic/react/css/core.css';

import { default as MODULES } from './app.modules';
import { LeftDrawer, RightDrawer } from '@flexo/molecules';
import { FirebaseService, IconWrapper, auth,  } from '@flexo/general';
import { ThemeProvider } from '@flexo/providers';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDAR, USER } from '@flexo/reducers';
import { _navigate } from '@flexo/atoms';


const firebaseService = new FirebaseService()

const ENV = (import.meta as any).env;

export interface ILoginFlow {
  logInState: 'LOGIN' | 'REGISTER' | 'ACCOUNT_CREATION' | 'FORGOT_PASSWORD';
}

setupIonicReact(); //initiates ionic with react

function App() {

  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);
  const calendar = useSelector((state: any) => state.calendar);

  const { selectedDate } = calendar.hiveCalendar;

  const [logInFlow, setLogInFlow] = useState<ILoginFlow['logInState']>('LOGIN');
  const [isBlurred, setIsBlurred] = useState(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [availableModules, setAvailableModules] = useState([]);

  const location = window.location;
  const browserLanguage = navigator.language.split('-')[0] || null;

  const changeLanguage = useCallback(async () => {


    await i18next.changeLanguage(
      `${ENV.VITE_APP_NAME}_${browserLanguage || ENV.VITE_APP_LANGUAGE}`
    );
  }, []);

  useEffect(() => {
    const unsubscribe = firebaseService.onAuthStateChange((user) => {

      setShowSplashScreen(true);


      if (user) {
        let tokenContent: any = {};

        const pathName = location.pathname
        const pathNameSplit = location.pathname.split('/')[1];
        const searchParams = location.search;

        try {
          tokenContent = jwt_decode(user?.user?.accessToken || user?.stsTokenManager?.accessToken);
        } catch (error) {
          // console.error(error);
        }

        dispatch(USER.SetUserAsync({ ...user, tokenContent }) as any)
        // Sentry.setUser({
        //   id: user.uid,
        //   email: user.email,
        // });

        if (MODULES.find((module: any) => module.name === pathNameSplit)?.needsAuth === true) {
          setTimeout(() => {
            _navigate(`${pathName}${searchParams}`)
          }, 1000)
        }
        // else {
        //   setTimeout(() => _navigate(`/${ENV.VITE_APP_FIRST_PAGE}`), 1000 )

        // }

        

        // Sentry.startSession();
      } else {
        // Clear user context in Sentry when user is logged out
        // Sentry.setUser(null);
        // Sentry.endSession();
      }

      setTimeout( () => setShowSplashScreen(false), 1000 )
      
    });

    // Clean up the listener when the component is unmounted
    // return () => unsubscribe();
  }, [document.readyState]);
  


  useEffect(() => {
    // TO DO: dinamicaly change language based on device language
    i18next.changeLanguage(`${ENV.VITE_APP_NAME}_${browserLanguage || ENV.VITE_APP_LANGUAGE}`);


  }, [changeLanguage]);

  // useEffect(() => {
  //   CapApp.addListener('appStateChange', async ({ isActive }) => {
  //     if (!isActive) {
  //       setIsBlurred(true);
  //     } else {
  //       setIsBlurred(false);
  //     }
  //   });

  //   CapApp.addListener('backButton', ({ canGoBack }) => {
  //     //alert( 'back' )
  //   });
  // }, []);



  useEffect(() => {

    let _modules: any = MODULES.filter((module: any) => module.active?.default === true);

    if (user?.tokenContent || user?._tokenResponse) {

      _modules = _modules
        .filter((module: any) => module?.needsAuth === true)

      // _modules.push({
      //   ...(MODULES.find((module: any) => module.name === ENV.VITE_APP_FIRST_PAGE) as any),
      //   name: '*'
      // })

      
    } else {
      _modules = _modules
        .filter((module: any) => module?.needsAuth === false)

      // _modules.push({
      //   ...MODULES.find((module: any) => module.name === 'login') as any,
      //   name: '*'
      // })

    }

    setAvailableModules(_modules)


  }, [
    user, showSplashScreen
  ])





  useEffect(() => {

    (async () => {
      if (user?.logOut === true) {

        await firebaseService.signOut();


        dispatch(USER.ResetUserAsync() as any)
      }
    })()

  }, [user?.logOut])

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const hasKeys = (obj, ...keys) => keys.every(key => key in obj);
    if (Object.keys(params).length > 0 && hasKeys(params, 'dateTo', 'dateFrom', 'timeSet') === true) {
      
      const _startDate = new Date(params.dateFrom as string);
      const _endDate = new Date(params.dateTo as string);

      if ((`${_startDate} ${_endDate}`).toLowerCase()?.includes('invalid')) { return; }

      if (
        (luxon.DateTime.fromJSDate(_startDate).toMillis() === luxon.DateTime.fromJSDate(selectedDate[0]).toMillis()) &&
        (luxon.DateTime.fromJSDate(_endDate).toMillis() === luxon.DateTime.fromJSDate(selectedDate[1]).toMillis())
      ) {
        return;
      }


      dispatch(CALENDAR.setHiveDate({
        timeSet: params.timeSet,
        date: [ _startDate, _endDate ],
        setQueryParams: false,
      } as any))
    }
  }, [])

  



  return (

    showSplashScreen
      ? <div
        className="flex flex-align-center flex-justify-center flex-column"
        style={{ height: '100vh', width: '100vw' }}
      >
        <IconWrapper
          iconName={ENV.VITE_APP_NAME === 'admin' ? 'logo_wide' : 'logo'}
        />
      </div>
      : <AlertProvider>



        :<IonApp className={`Web__APP ${isBlurred ? 'APP_Blurred' : ''}`}>

          <ThemeProvider>

            {
              <IonContent scrollY={false} className='IonScroll'>
                <ModalProvider>

                  <LeftDrawer />
                  <BrowserRouter>
                    {/* <MobileModal /> */}
                    {/* <div>

                          module { JSON.stringify(availableModules) }

                        </div> */}
                    {/* { JSON.stringify(auth) } */}
                    {
                      user?.SetUserAsyncStatus !== 'PENDING' &&
                      <Routes>

                        {
                          availableModules
                            .map((module: any) => {
                              return <Route key={module.name} path={module?.needsID ? `${module.name}/:id` : module.name} element={<Modules MODULE={JSON.stringify(module)} COLORS={COLORS} />} />

                            })
                          }
                          
                          {
                            (auth?.currentUser)
                              ? < Route path='*' element={ <Navigate to={ `/${ENV.VITE_APP_FIRST_PAGE}`} />} />
                              : < Route path='*' element={ <Navigate to={`/login`} />} />
                          }


                      </Routes>
                    }
                    <RightDrawer />
                  </BrowserRouter>


                </ModalProvider>

              </IonContent>
            }

          </ThemeProvider>

        </IonApp>
      </AlertProvider>

  );
}

export default Sentry.withProfiler(App);