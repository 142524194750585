import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './graph-container.module.scss';
import {
  CombinedBatteryChartMobile,
  NewAreaChart,
  Stackbar,
} from '@flexo/molecules';
import { KpiApiContext, ThemeContext } from '@flexo/providers';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ChartSkeleton, Shimmer } from '@flexo/atoms';
const ENV = (import.meta as any).env;

const GraphContainer = React.memo((props: any) => {
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];

  const { ThemeStore } = useContext(ThemeContext);
  const [graphValues, setGraphValues] = useState([]);

  const { site, community, member } = kpi;

  let detailKpiData = site?.['1hour'] || null;
  let LOADING_STATE = kpi?.KpiDataSiteAsyncStatus;

  if (pathSegment?.includes('community')) {
    detailKpiData = community?.['1hour'];
    LOADING_STATE = kpi?.KpiDataCommunityAsyncStatus;
  }
  if (pathSegment?.includes('member')) {
    detailKpiData = member?.['1hour'];
    LOADING_STATE = kpi?.KpiDataMemberAsyncStatus;
  }

  useEffect(() => {
    const filteredKeys = props?.graphKeys?.filter((el) =>
      detailKpiData?.[0]?.kpi.some(
        (kpiItem) => kpiItem.name === (typeof el === 'string' ? el : el?.key)
      )
    );
    setGraphValues(filteredKeys);
  }, [props.graphKeys, detailKpiData]);

  const getTranformDataAreachart = (data, graphKeys) => {
    return data?.map((entry) => {
      const kpiMap = {};
      entry?.kpi?.forEach((kpiItem) => {
        graphKeys?.forEach((key) => {
          if (kpiItem.name === key) {
            const formattedKey = `widgets.${props.subtype}.${key}`;
            kpiMap[formattedKey] = kpiItem.value;
          }
        });
      });

      return {
        date: entry.timestamp,
        ...kpiMap,
      };
    });
  };

  const transformData = (data, graphKeys) => {
    return data?.map((entry) => {
      const kpiMap = {};
      entry?.kpi?.forEach((kpiItem) => {
        graphKeys?.forEach((key) => {
          if (kpiItem.name === key.key) {
            const formattedKey = `widgets.${props.subtype}.${key.key}`;
            kpiMap[formattedKey] = kpiItem.value;
          }
        });
      });

      return {
        date: entry.timestamp,
        ...kpiMap,
      };
    });
  };

  const transformedData = transformData(
    detailKpiData ?? detailKpiData,
    graphValues
  );

  const getColors = () => {
    try {
      if (typeof props.colors[0] === 'string') {
        return props.colors?.[0].startsWith('#')
          ? props.colors
          : props.colors.map((color) => ThemeStore?.colors[color]);
      }
      return props.colors.map(
        (color) => ThemeStore?.colors[color?.color] + (color?.colorShade || '')
      );
    } catch (err) {
      return [...Array(props.buttons?.length || 0)].map(
        (_el, i) => `#000000${(i + 1) * 10}`
      );
    }
  };

  const getGraphButtons = (keys) => {
    return keys?.map((key) => `widgets.${props.subtype}.${key.key}`);
  };

  const getUnit = () => {
    const key =
      (typeof (graphValues as any)?.[0] === 'string'
        ? (graphValues as any)?.[0]
        : (graphValues as any)?.[0]?.key) || null;

    if (!key) {
      // console.warn('no key found');
      return '';
    }
    return detailKpiData?.[0]?.kpi?.filter((kpiItem) => {
      return kpiItem.name.includes(key);
    })?.[0]?.unit;
  };

  const getGraphButtonsAreaChart = (keys) => {
    const graphButtons = keys?.map((key) => {
      return `widgets.${props.subtype}.${key}`;
    });

    return graphButtons;
  };

  const getTotalValue = (data) => {
    if (!props.totalValue) {
      return null;
    }
    return data?.map((entry) => {
      return {
        date: entry.timestamp,
        totalValue: entry.kpi.find(
          (kpiItem) => kpiItem.name === props.totalValue
        )?.value,
        unit: entry.kpi.find((kpiItem) => kpiItem.name === props.totalValue)
          ?.unit,
      };
    });
  };
  const totalValue = getTotalValue(detailKpiData ?? detailKpiData);

  const ref = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const updateWidth = () => {
      if (ref.current) {
        setContainerWidth(ref.current.offsetWidth);
      }
    };

    // Run updateWidth initially to get the first size
    updateWidth();

    // Use ResizeObserver to listen for changes
    const observer = new ResizeObserver(() => updateWidth());
    observer.observe(ref.current);

    // Cleanup function to disconnect observer
    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  const getGraph = () => {
    switch (props.subtype) {
      case 'consumptionStackBar':
      case 'productionStackBar':
      case 'savingsStackbar':
      case 'savingsStackBar':
        return (
          <Stackbar
            _data={transformedData}
            keys={getGraphButtons(graphValues)}
            combinedKeys={props.combinedKeys}
            graphKeys={props.graphKeys}
            colors={getColors()}
            title={props.title}
            unit={getUnit()}
            chartValues={graphValues ?? null}
            subtype={props.subtype}
            totalValue={totalValue ?? null}
          />
        );
      case 'areachart':
      case 'areaChart':
        return (
          <NewAreaChart
            colors={getColors()}
            title={props.title}
            data={getTranformDataAreachart(detailKpiData, props.graphKeys)}
            keys={getGraphButtonsAreaChart(props.graphKeys)}
            unit={getUnit()}
            containerWidth={containerWidth}
          />
        );
      case 'batteryChartMobile':
        return <CombinedBatteryChartMobile {...props} />;
      default:
        return (
          <Stackbar
            _data={transformedData}
            keys={getGraphButtons(graphValues)}
            combinedKeys={props.combinedKeys}
            colors={getColors()}
            title={props.title}
            unit={getUnit()}
            graphKeys={props.graphKeys}
            chartValues={graphValues ?? null}
            subtype={props.subtype}
            totalValue={totalValue ?? null}
          />
        );
    }
  };

  return (
    <div
      className={`${styles.GraphContainer} ${
        props.gradientBorder && styles.GraphContainer__GradientBorder
      } graph-container`}
      style={{ position: 'relative', height: '100%' }}
      ref={ref}
    >
      <div
        className={
          ENV.VITE_APP_NAME === 'admin'
            ? styles.GraphContainer__Web
            : styles.GraphContainer
        }
      >
        {LOADING_STATE === 'PENDING' && <Shimmer />}

        {LOADING_STATE === 'PENDING' &&
        (detailKpiData?.length === 0 || !detailKpiData) ? (
          // (LOADING_STATE !== 'PENDING' && detailKpiData?.length !== 0)
          <ChartSkeleton title={props.title} />
        ) : (
          <div className={styles.Graph}>{getGraph()}</div>
        )}
      </div>
    </div>
  );
});

export default GraphContainer;
