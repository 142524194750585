import { useDispatch, useSelector } from 'react-redux';
import * as luxon from 'luxon';

import styles from './hive-calendar.module.scss';
import { CALENDAR } from '@flexo/reducers';
import { useTranslation } from 'react-i18next';
import { checkIfDayIsAvailable, getAllDaysOfMonthByWeek } from './helpers';

export function HiveCalendarContentDay(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setShowCalendar } = props;

  const dayNames: any = t('calendar.dayNamesShort', { returnObjects: true });
  const calendar = useSelector((state: any) => state.calendar);
  const hiveCalendar = calendar.hiveCalendar;

  const { calendarDate, selectedDate, selectedTimeSet } = hiveCalendar;

  const _selectedDate = calendarDate || selectedDate;

  function getPreviousDays(index: number) {
    const weekStartDay = weeks[0][0];
    return luxon.DateTime.fromJSDate(weekStartDay)
      .minus({ days: index })
      .toJSDate();
  }

  function getFollowingDays(
    index: number,
    week_index: number,
    lastDayIndex: number
  ) {
    const weekEndDay = weeks[week_index][lastDayIndex];
    return luxon.DateTime.fromJSDate(weekEndDay)
      .plus({ days: index })
      .toJSDate();
  }

  function checkIfDayIsSelected(day: Date) {
    if (selectedTimeSet !== 'day') {
      return false;
    }

    if (selectedDate instanceof Array) {
      return (
        luxon.DateTime.fromJSDate(selectedDate[0]).startOf('day').toMillis() ===
        luxon.DateTime.fromJSDate(day).startOf('day').toMillis()
      );
    }

    return (
      luxon.DateTime.fromJSDate(selectedDate).toISODate() ===
      luxon.DateTime.fromJSDate(day).toISODate()
    );
  }

  function setDay(day: Date) {
    if (day > luxon.DateTime.fromJSDate(new Date()).startOf('day').toJSDate()) {
      return;
    }

    const nextDay = luxon.DateTime.fromJSDate(day).plus({ days: 1 }).toJSDate();

    dispatch(
      CALENDAR.setHiveDate({
        date: [day, nextDay],
        timeSet: 'day',
      } as any)
    );
    setShowCalendar(false);
  }

  const weeks = getAllDaysOfMonthByWeek(_selectedDate);

  return (
    <div className={styles.HiveCalendar__Calendar__Content__Day}>
      <div
        className={`${styles.HiveCalendar__Calendar__Content__Day__Week} ${styles.HiveCalendar__Calendar__Content__Day__Week__Heading}`}
      >
        {dayNames.map((day: string, index: number) => {
          return (
            <div
              key={`Calendar__Content__Day__Week__Day__${index}__Label`}
              className={`${styles.HiveCalendar__Calendar__Content__Day__Week__Day} 
            ${styles.HiveCalendar__Calendar__Content__Day__Week__Day__Label} ${styles.cursor_default}`}
            >
              {day}
            </div>
          );
        })}
      </div>

      {weeks.map((week: Array<Date>, week_index: number, weeks_array: any) => {
        return (
          <div
            key={`Calendar__Content__Day__Week_${week_index}`}
            className={styles.HiveCalendar__Calendar__Content__Day__Week}
          >
            {week?.length < 7 &&
              week_index === 0 &&
              Array.from({ length: 7 - week.length })
                .map((_, i) => {
                  return (
                    <div
                      key={getPreviousDays(i + 1).toISOString()}
                      className={` 
                  ${styles.HiveCalendar__Calendar__Content__Day__Week__Day} 
                  ${
                    styles.HiveCalendar__Calendar__Content__Day__Week__Day__OutOfMonth
                  } 
                  ${
                    checkIfDayIsSelected(getPreviousDays(i + 1) as any)
                      ? styles.HiveCalendar__Calendar__Content__Day__Week__Day__Selected
                      : ''
                  }`}
                    >
                      {getPreviousDays(i + 1).getDate()}
                    </div>
                  );
                })
                .reverse()}

            {week.map((day: Date) => {
              return (
                <div
                  key={day.toISOString()}
                  className={`
                ${styles.HiveCalendar__Calendar__Content__Day__Week__Day} 
                ${
                  checkIfDayIsAvailable(day)
                    ? ''
                    : styles.HiveCalendar__Calendar__Content__Day__Week__Day__Disabled
                }
                ${
                  checkIfDayIsSelected(day)
                    ? styles.HiveCalendar__Calendar__Content__Day__Week__Day__Selected
                    : ''
                }
              `}
                  onClick={() =>
                    checkIfDayIsAvailable(day) ? setDay(day) : null
                  }
                >
                  {day.getDate()}
                </div>
              );
            })}

            {week?.length < 7 &&
              week_index === weeks_array?.length - 1 &&
              Array.from({ length: 7 - week.length }).map((_, i) => {
                return (
                  <div
                    key={getFollowingDays(
                      i + 1,
                      week_index,
                      week.length - 1
                    ).toISOString()}
                    className={`
                ${styles.HiveCalendar__Calendar__Content__Day__Week__Day}
                ${
                  styles.HiveCalendar__Calendar__Content__Day__Week__Day__OutOfMonth
                }
                ${
                  checkIfDayIsSelected(
                    getFollowingDays(i + 1, week_index, week.length - 1)
                  )
                    ? styles.HiveCalendar__Calendar__Content__Day__Week__Day__Selected
                    : ''
                }`}
                  >
                    {getFollowingDays(
                      i + 1,
                      week_index,
                      week.length - 1
                    ).getDate()}
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
}
