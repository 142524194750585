

import styles from './skeleton.module.scss';

export function MapSkeleton(props: any) {

  const { radius, mapHeight } = props;


  return (
    <div className={styles.MapSkeleton} style={{ height: mapHeight, borderRadius: radius }} >
      

    </div>
  );
}