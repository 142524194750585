import { useContext, useEffect } from 'react';

import {
  AlertContext,
  AlertTypes,
} from '@flexo/providers';


import {
  FirebaseService,
} from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { KPI, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';

const firebaseService = new FirebaseService();

export const MemberRankingResolver = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const metadata = useSelector((state: any) => state.metadata);
  const calendar = useSelector((state: any) => state.calendar);
  const pathSegment = location.pathname.split('/')[1]; 

  
  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet } = hiveCalendar

  async function init() {

    if (!location.pathname.includes('member')) { return; }

    const MEMBER_ID = params?.id || metadata?.selectedEntity?.memberId || null;

    if ( !MEMBER_ID ) { return; }

    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED'
          },
        },
      })

      
      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   }
      // })

      dispatch(USER.SetLogout());

      return;
    }

    dispatch(KPI.SetKpiDataMemberRankingAsync({MEMBER_ID, idToken, selectedDate, selectedTimeSet}) as any )


  }

  useEffect(() => {
      init();
  }, []);

  useEffect(() => {
      init();
  }, [selectedDate, params?.id, pathSegment]);

  return <></>;
};
