//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import { useContext, useEffect } from 'react';

import { AlertContext, AlertTypes } from '@flexo/providers';
import {
  FirebaseService,
} from '@flexo/general';
import { KPI, USER } from '@flexo/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const KpiSiteResolver = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const metadata = useSelector((state: any) => state.metadata);
  const calendar = useSelector((state: any) => state.calendar);
  
  const pathSegment = location.pathname.split('/')[1]; 
  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet} = hiveCalendar

  async function init() {
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    const SITE_ID = params?.id || metadata?.selectedEntity?.siteId || null;


    if (!SITE_ID) {
      return;
    }

    dispatch(KPI.ResetNamedKpiFields([
      'community',
      'communityRanking',
      'member',
      'memberRanking',
    ] as any))
    
    dispatch(KPI.SetKpiDataSiteAsync({ SITE_ID, idToken, selectedDate, selectedTimeSet }) as any)

    
   
  }

  useEffect(() => {
    init();
  }, [params.id, metadata?.selectedEntity, selectedDate, selectedTimeSet, pathSegment]);

  return <></>;
};
