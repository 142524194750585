let globalNavigate: any = null;

export const setNavigate = (navigateFn) => {
  globalNavigate = navigateFn;
};

export const _navigate = (path) => {
  if (globalNavigate) {
    globalNavigate(
      path
    );
  } else {
    console.error("Navigate function not set!");
  }
};