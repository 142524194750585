import { useContext, useEffect, useRef, useState } from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@flexo/providers';
import { formatDate } from '../helpers';
import styles from './battery-chart.module.scss';
import { useSelector } from 'react-redux';
import { BatterySkeleton, Shimmer } from '@flexo/atoms';
import { useLocation } from 'react-router-dom';

function CombinedBatteryChart(props: any) {
  const { t } = useTranslation();

  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];
  const pathContext = pathSegment.split('-')[0];
  const kpi = useSelector((state: any) => state.kpi);
  const calendar = useSelector((state: any) => state.calendar);
  const { selectedTimeSet } = calendar.hiveCalendar;

  const { ThemeStore } = useContext(ThemeContext);

  const [unit, setUnit] = useState('');
  const [chartData, setChartData] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState<string | null>(null);
  const [activeDataSets, setActiveDataSets] = useState<any[]>([]);
  const [isNullStateActive, setNullStateActive] = useState<boolean>(false);
  const [innerTimeSet, setInnerTimeSet] = useState<string>(selectedTimeSet);
  
  const chartRef = useRef<any>(null);
  const xRef = useRef<any>(null);

  const rawData = kpi?.[pathContext]?.['1hour'] || [];
  let LOADING_STATE = kpi?.KpiDataSiteAsyncStatus;

  if (pathContext?.includes('member')) {
    LOADING_STATE = kpi?.KpiDataMemberAsyncStatus;
  }

  if (pathContext?.includes('community')) {
    LOADING_STATE = kpi?.KpiDataCommunityAsyncStatus;
  }

  function getGreyScaledColor(color: string) {
    let _color = color;

    if (color.includes('#')) {
      const red = parseInt(color.substr(1, 2), 16);
      const green = parseInt(color.substr(3, 2), 16);
      const blue = parseInt(color.substr(5, 2), 16);
      // Calculate the grayscale value
      const grayscale = Math.round(0.299 * red + 0.587 * green + 0.114 * blue);
      // Convert the grayscale value back to a hex string
      const grayscaleHex = grayscale.toString(16).padStart(2, '0');
      // Return the grayscale hex string with '#' prefix
      _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex;
    }
    return _color;
  }

  const CustomizedDot = (_props) => {
    const { cx, cy, payload, index } = _props;

    return (
      <circle
        key={`dot-${payload.timestamp}`}
        cx={cx}
        cy={cy}
        r={activeIndex !== null && parseInt(activeIndex) === index ? 8 : 5} // se stiamo hoverando, r=5, altrimenti 1
        fill={
          activeIndex !== null && parseInt(activeIndex) === index
            ? `${ThemeStore?.colors[props?.colors?.[1]?.color]}${
                (props?.colors?.[1]?.colorShade || '')
              }`
            : 'transparent'
        }
        stroke="none"
        onMouseEnter={() => setActiveIndex(`${index}`)}
        onMouseLeave={() => setActiveIndex(null)}
        style={{ transition: 'r 0.2s ease' }}
      />
    );
  };

  // Handle mouse hover to set active bar
  const handleMouseMove = (index) => {
    setActiveIndex(`${index}`); // Only set active index if no bar is selected
  };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Reset active index on mouse leave
  };

  // Click handler to set the selected bar
  const handleBarClick = (index) => {
    setActiveIndex(`${index}`); // Only update if a new bar is selected
  };

  function formatButtonValue(
    fieldName: string | null,
    buttonString: string,
    value: number | null
  ) {
    let formattedValue = `${buttonString}`;

    if (value === null && buttonString) {
      if (fieldName === 'soc_battery') {
        return `${buttonString} (%)`;
      }
      return formattedValue;
    }

    switch (fieldName) {
      case 'battery_net_flow':
        formattedValue = `${buttonString} | ${value} ${unit}`;
        break;
      case 'soc_battery':
        formattedValue = `${buttonString} | ${value} %`;
        break;
      default:
        break;
    }

    return formattedValue;
  }

  function cleanArray(arr: any) {
    const uniqueSet = new Set();
    const result: any = [];

    for (let num of arr) {
      // Normalizza 0 e -0 a 0
      if (Object.is(num, -0)) {
        num = 0;
      }

      // Aggiungi solo se non è già stato visto
      if (!uniqueSet.has(num)) {
        uniqueSet.add(num);
        result.push(num);
      }
    }

    return result;
  }

  function getSymmetricTicks(data: any): number[] {
    // Estrai tutti i valori di battery_net_flow
    const values = data.map((d) => d?.[props.db_fields?.[0] || 0]);

    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);

    const maxAbs = Math.max(Math.abs(maxValue), Math.abs(minValue));

    const roundedMaxAbs = Number(maxAbs.toFixed(2));

    return cleanArray([
      roundedMaxAbs,
      Number((roundedMaxAbs / 2).toFixed(2)),
      0,
      Number((-roundedMaxAbs / 2).toFixed(2)),
      -roundedMaxAbs,
    ]);
  }

  function getFillColor(index: any, colorIndex: number): string {
    if (activeIndex === null) {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}${
        props.colors[colorIndex].colorShade
      }`;
    } else if (parseInt(activeIndex) === parseInt(index)) {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}${
        props.colors[colorIndex].colorShade
      }`;
    } else {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}60`;
    }
  }

  useEffect(() => {
    // Crea i dati per Recharts
    const _chartData = rawData.map((item: any) => {
      const formattedData: any = {
        timestamp: formatDate(item.timestamp, innerTimeSet),
      };

      props?.db_fields.forEach((field, index) => {
        const kpiValue = item?.kpi.find((datum: any) => datum.name === field);
        if (kpiValue) {
          formattedData[field] = kpiValue.value;
        }
      });

      return formattedData;
    });

    setChartData(_chartData);

    const nullCheck = JSON.parse(JSON.stringify(_chartData || []))
    .map((item) => {
      if (item['date']) {
        delete item['date'];
      }
      if (item['timestamp']) {
        delete item['timestamp'];
      }
        return Object(item);
      })
      .reduce(
        (acc, item) => [
          ...acc,
          ...Object.entries(item).map((entry) => entry[1]),
        ],
        []
      )
      .every((item) => item === null);

    setNullStateActive(nullCheck);

    // Imposta l'unità di misura
    if (props?.db_fields?.length > 0) {
      const firstField = props?.db_fields[0];
      const firstItem = rawData?.[0];
      if (firstItem) {
        const kpiValue = firstItem.kpi.find(
          (datum: any) => datum.name === firstField
        );
        setUnit(kpiValue?.unit || '');
      }

      if (innerTimeSet === 'day' && props?.context === 'site') {
        setActiveDataSets(
          Array.from({ length: props?.db_fields?.length }, (_) => true)
        );
      }
    }
  }, [kpi?.[pathContext]?.['1hour'], props.db_fields, innerTimeSet]);

  useEffect(() => {
    if (LOADING_STATE !== 'PENDING' && innerTimeSet !== selectedTimeSet) {
      setInnerTimeSet(selectedTimeSet);
    }
  }, [LOADING_STATE]);

  function toggleDataSets(index: number) {
    if (activeDataSets?.length < 2) {
      return;
    }

    if (
      activeDataSets.length === 2 &&
      activeDataSets.includes(false) &&
      activeDataSets.indexOf(true) === index
    ) {
      // setActiveDataSets([true, true]);
      return;
    }

    const newActiveDataSets = [...activeDataSets];
    newActiveDataSets[index] = !newActiveDataSets[index];
    setActiveDataSets(newActiveDataSets);
  }

  const getInterval = () => {
    switch (innerTimeSet) {
      case 'day':
      case 'week':
      case 'month':
        return 6;
      case 'year':
        return 2;
      default:
        return 0;
    }
  };

  const handleTouchMove = (e) => {
    // const clampedIndex: any = Math.max(0, Math.min(index, chartData.length - 1));
    setActiveIndex(`${e.activeTooltipIndex}`);
  };

  const handleTouchEnd = () => {
    setActiveIndex(null);  // Resetta l'indice attivo quando il tocco termina
  };

  

  return (
    <div
      id="CombinedBatteryChart__Wrapper"
      className={styles.CombinedBatteryChart__Wrapper__Web}
    >
      {LOADING_STATE === 'PENDING' && <Shimmer />}

      {(
        <>
          <div
            className={
              activeIndex
                ? styles.CombinedBatteryChart__Title__Active
                : styles.CombinedBatteryChart__Title
            }
          >
            {activeIndex && rawData?.[activeIndex]?.timestamp && (
              <div className={styles.CombinedBatteryChart__TimeStamp}>
                {formatDate(rawData?.[activeIndex]?.timestamp, innerTimeSet)}
              </div>
            )}

            {t(props.title)}

            {/* {(activeIndex && chartData?.[activeIndex]?.[props?.db_fields?.[0]]) &&
  <div
    key={`BatteryChart__Total`}
    className={styles.CombinedBatteryChart__Total}
  >
    {chartData?.[activeIndex]?.[props?.db_fields?.[0]]} { unit}
  </div>
} */}
          </div>

          
          <div className={styles.CombinedBatteryChart__Buttons__Wrapper}>
            {
              (LOADING_STATE === 'PENDING' && chartData.length === 0)
                ? <></>
                : props.buttons.map((button: any, index: number) => {
                  if (index > 0 && innerTimeSet !== 'day') {
                    return '';
                  }
  
                  return (
                    <div
                      key={`BatteryChart__${index}__button__${button}}`}
                      className={styles.CombinedBatteryChart__Button}
                      onClick={() => toggleDataSets(index)}
                      style={{
                        opacity:
                          activeDataSets[index] || activeDataSets?.length === 0
                            ? 1
                            : 0.5,
                        cursor:
                          activeDataSets?.length < 2 ? 'default' : 'pointer',
                      }}
                    >
                      {formatButtonValue(
                        props?.db_fields?.[index],
                        t(button),
                        chartData?.[activeIndex as any]?.[
                          props.db_fields?.[index]
                        ] || null
                      )}
  
                      <div
                        className={styles.CombinedBatteryChart__Button__Underline}
                        style={{
                          backgroundColor:
                          activeDataSets[index] || activeDataSets?.length === 0
                          ? `${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}`
                          : getGreyScaledColor(`${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}`),
                        }}
                      ></div>
                    </div>
                  );
                
              })
            }
            
          </div>

            {isNullStateActive
              ? <BatterySkeleton isNull={ isNullStateActive && LOADING_STATE !== 'PENDING'} />
          :    (
              <div className={styles.CombinedBatteryChart__Chart__Wrapper}
                
              >
              <div
                className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Left} ${styles.CombinedBatteryChart__Chart__Unit__Left__Web}`}
              >
                {unit}
              </div>
              <ResponsiveContainer width="100%" height={305} >
                <ComposedChart
                  data={chartData}
                  margin={{ top: 25, right: (innerTimeSet === 'day' ? -15 : 10), left: 15, bottom: 0 }}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={(e) => handleTouchMove(e)}
                >
                  {/* <CartesianGrid stroke="#f5f5f5" vertical={false} /> */}
                  <XAxis
                    dataKey="timestamp"
                    tick={{
                      fontSize: 12,
                      fontFamily: 'Flexo',
                      fill: ThemeStore?.colors?.greyscale,
                      opacity: 1,
                      fontWeight: 300
                    }}
                    ticks={chartData.map((_, index) =>
                      formatDate(rawData?.[index]?.timestamp, innerTimeSet)
                    )}
                    tickFormatter={(tick) => tick}
                    interval={getInterval()}
                    axisLine={false}
                    tickLine={false}
                    tickMargin={12}
                    dy={-6}
                  />
                  <CartesianGrid
                    strokeDasharray="0"
                    vertical={false}
                    horizontalValues={[0]}
                    stroke={ThemeStore?.colors?.gridColor}
                    strokeWidth={0.5}
                  />
                  <YAxis
                    yAxisId="left"
                    orientation="left"
                    tick={{
                      fontSize: 12,
                      fontFamily: 'Flexo',
                      fill: ThemeStore?.colors?.greyscale,
                      opacity: 1,
                      fontWeight: 300,
                      dy: -4
                    }}
                    axisLine={true}
                    tickLine={false}
                    ticks={getSymmetricTicks(chartData)}
                    tickFormatter={(tick) => (tick).toFixed(1)}
                    tickMargin={8}
                    dx={0}
                    strokeWidth={1}
                      stroke={ThemeStore?.colors?.gridColor}
                      opacity={0.2}
                  />

                  {innerTimeSet === 'day' && props?.context === 'site' && (
                      <YAxis
                        
                      yAxisId="right"
                      orientation="right"
                      tick={{
                        fontSize: 12,
                        fontFamily: 'Flexo',
                        fill: ThemeStore?.colors?.greyscale,
                        opacity: 1,
                        fontWeight: 300,
                        dy: -4
                      }}
                      domain={[0, 100]}
                      axisLine={true}
                      tickLine={false}
                      tickMargin={8}
                      dx={0}
                      strokeWidth={1}
                        stroke={ThemeStore?.colors?.gridColor}
                        opacity={0.2}
                    />
                  )}
                  {/* <Tooltip /> */}
                  <Bar
                    yAxisId="left"
                    dataKey={props.db_fields[0]}
                    // fill={getFillColor()}
                    hide={
                      activeDataSets.length === 0
                        ? false
                        : !activeDataSets?.[0] || false
                    }
                    onClick={(_, i) => handleBarClick(i)} // Click handler for bar
                    onMouseMove={(_, i) => handleMouseMove(i)} // Handle mouse hover
                    radius={[4, 4, 4, 4]}
                  >
                    {chartData.map((entry, index) => (
                      <Cell
                        key={`cell-${entry.timestamp}}`}
                        fill={getFillColor(index, 0)}
                      />
                    ))}
                  </Bar>
                  {innerTimeSet === 'day' && props?.context === 'site' && (
                    <Line
                      hide={!activeDataSets[1]}
                      yAxisId="right"
                      type="monotone"
                      dataKey={props.db_fields[1]}
                      stroke={`${ThemeStore?.colors[props.colors[1].color]}${
                        props.colors[1].colorShade
                        }`}
                      strokeWidth={3}
                        dot={CustomizedDot}
                        strokeLinecap='round'
                    />
                  )}
                </ComposedChart>
              </ResponsiveContainer>
              <div
                className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Right} ${styles.CombinedBatteryChart__Chart__Unit__Right__Web}`}
              >
                {innerTimeSet === 'day' && props?.context === 'site'
                  ? '%'
                  : ''}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CombinedBatteryChart;
