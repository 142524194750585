import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@flexo/providers';

import styles from './horizontal-chart.module.scss';
import HorizontalChart from './horizontal-chart';
import { HorizontalChartDouble } from '@flexo/molecules';
import { navigateToMember, navigateToSite, RankingSkeleton, Shimmer } from '@flexo/atoms';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function HorizontalChartWrapper(props: any) {

  const { t } = useTranslation();
  const location = useLocation()
  const pathSegment = location.pathname.split('/')[1];
  const metadata = useSelector((state: any) => state.metadata);
  const kpi = useSelector((state: any) => state.kpi);


  const { ThemeStore } = useContext(ThemeContext)

  const chartWrapperRef = React.useRef<HTMLDivElement>(null);

  const [activeKeys, setActiveKeys] = useState<string[]>(props?.graphKeys || []);
  const [data, setData] = useState<any>([]);
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const [rendered, setRendered] = useState(false);

  let rawData = kpi?.communityRanking || null;
  let LOADING_STATE = kpi?.KpiDataCommunityRankingAsyncStatus

  if (pathSegment?.includes('member')) {
    rawData = kpi?.memberRanking || null;
    LOADING_STATE = kpi?.KpiDataMemberRankingAsyncStatus
  }

  function handleNavigation(line: any) {

    // if (pathSegment?.includes('member')) {
      navigateToSite(
        props?.context === 'energy' ? 'site-energy' : props?.context === 'economics' ? 'site-economics' : 'member',
        line?.siteId,
      )
    // } else {
      // navigateToMember(
      //   props?.context === 'energy' ? 'site-energy' : props?.context === 'economics' ? 'member-economics' : 'site',
      //   line?.memberId,
      // )
    // }

  }

  const getItemsList = () => {

    const sitesList = ((metadata?.entities as any)?.site) || [];

    if (props?.context === 'energy') {

      if (
        rawData?.consumptionRanking?.[0]?.site &&
        rawData?.productionRanking?.[0]?.site
      ) {
        let sites = rawData?.consumptionRanking?.[0]?.site
          .map((_site: any) => ({
            ..._site,
            ...sitesList.find((entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
          }))
          .sort((a, b) => a?.ranking > b?.ranking ? -1 : 1)
          .filter((_, i) => i < 5)


        sites = sites.map((site: any) => (
          {
            ...site,
            kpi: [
              site.kpi,
              rawData?.productionRanking?.[0]?.site?.find((prodSite: any) => prodSite?.siteId === site?.siteId)?.kpi || []
            ]
          }))

        return sites;

      }

    } else if (props?.context === 'economics') {

      return rawData?.economicsRanking?.[0]?.site
        .map((_site: any) => ({
          ..._site,
          ...sitesList.find((entitiesSsite: any) => entitiesSsite.siteId === _site.siteId),
        }))
        .sort((a, b) => a?.ranking > b?.ranking ? -1 : 1)
        .filter((_, i) => i < 5)

    }

    return []
  }

  function getKpiValue(item: any, index: number | null = 0) {

    let kpi;

    if (index !== null) {
      kpi = item?.kpi[index]?.find((datum: any) => props?.kpiNameLabels[index] === datum.name);
    } else {
      kpi = item?.kpi?.find((datum: any) => props?.rankingKpi === datum.name);
    }


    const value = kpi?.value || 0;
    const unit = kpi?.unit || '';
    return props?.context === 'economics' ? `${unit} ${value}` : `${value} ${unit}`;
  }


  // const handleButtonClick = (key: string) => {
  //   if (props.combinedKeys?.includes(key)) {
  //     // Se fa parte delle combinedKeys, attiva/disattiva entrambe
  //     const combinedKeyIndex = props.combinedKeys.indexOf(key);
  //     const combinedKey = props.combinedKeys[combinedKeyIndex + 1];

  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key && k !== combinedKey)
  //         : [...prev, key, combinedKey]
  //     );
  //   } else {
  //     // Se non fa parte delle combinedKeys, attiva/disattiva normalmente
  //     setActiveKeys((prev) =>
  //       prev.includes(key)
  //         ? prev.filter((k) => k !== key)
  //         : [...prev, key]
  //     );
  //   }
  // };

  const handleButtonClick = (key: string) => {

    if (typeof activeKeys[0] !== 'string') {

      const index = props.buttons.indexOf(key);

      const firstKey = props.graphKeys[0][index];
      const secondKey = props.graphKeys[1][index];

      if ((activeKeys[0] as any).length === 1 && (activeKeys[0] as any).includes(firstKey)) {
        return;
      }

      if ((activeKeys[0] as any).length === props?.combinedKeys[0]?.length && (activeKeys[0] as any)?.includes(firstKey) && props?.combinedKeys[0]?.includes(firstKey)) {
        return;
      }

      const newActiveKeys: any = [
        [],
        []
      ];


      if (props.combinedKeys[0].includes(firstKey)) {
        const firstCombinedKeyGroup = props.combinedKeys[0];
        const secondCombinedKeyGroup = props.combinedKeys[1];

        const firstAreAllKeysActive = firstCombinedKeyGroup.every(k => (activeKeys[0] as any).includes(k));
        const secondAreAllKeysActive = secondCombinedKeyGroup.every(k => (activeKeys[1] as any).includes(k));

        newActiveKeys[0] = (firstAreAllKeysActive
          ? (activeKeys[0] as any).filter(k => !firstCombinedKeyGroup.includes(k))
          : [...(activeKeys[0] as any), ...firstCombinedKeyGroup.filter(k => !(activeKeys[0] as any).includes(k))])
          .filter(x => x);

        newActiveKeys[1] = (secondAreAllKeysActive
          ? (activeKeys[1] as any).filter(k => !secondCombinedKeyGroup.includes(k))
          : [...(activeKeys[1] as any), ...secondCombinedKeyGroup.filter(k => !(activeKeys[1] as any).includes(k))])
          .filter(x => x);

      } else if ((activeKeys[0] as any).includes(firstKey) && activeKeys[1].includes(secondKey)) {

        (activeKeys[0] as any).forEach((k: any, i: number) => {


          if (k !== firstKey) {
            newActiveKeys[0].push(k)
            newActiveKeys[1].push(activeKeys[1][i])
          }
        })

      } else if (!(activeKeys[0] as any).includes(firstKey) && !activeKeys[1].includes(secondKey)) {
        newActiveKeys[0] = [...(activeKeys[0] as any), firstKey];
        newActiveKeys[1] = [...(activeKeys[1] as any), secondKey];
      }


      setActiveKeys(newActiveKeys);




    } else {
      if (activeKeys.length === 1 && activeKeys.includes(key)) {
        return;
      }

      if (activeKeys.length === props?.combinedKeys?.length && activeKeys?.includes(key) && props?.combinedKeys?.includes(key)) {
        return;
      }

      if (props.combinedKeys.includes(key)) {
        const combinedKeyGroup = props.combinedKeys;

        const areAllKeysActive = combinedKeyGroup.every(k => activeKeys.includes(k));

        setActiveKeys((prev) =>
          (areAllKeysActive
            ? prev.filter(k => !combinedKeyGroup.includes(k))
            : [...prev, ...combinedKeyGroup.filter(k => !prev.includes(k))])
            .filter(x => x)
        );
      } else {

        setActiveKeys((prev) =>
          (prev.includes(key)
            ? prev.filter((k) => k !== key)
            : [...prev, key])
            .filter(x => x)
        );
      }
    }



  };

  useEffect(() => {

    if (!rawData) { setData([]); return; }
    const _data = getItemsList() || [];

    setData(_data);



  }, [rawData]);

  useEffect(() => {

    if (chartWrapperRef.current) {

      const _layouts = props?.layouts?.lg || [];
      const newGridHeight = Math.ceil(chartWrapperRef.current?.getBoundingClientRect().height / 8) + 16;

      // if (newGridHeight > 53) {

      const newConfig = {
        lg: _layouts?.map((widget: any) => {

          if (widget.i === props?.id) {
            return {
              ...widget,
              h: newGridHeight
            }
          }
          return widget;
        })
      }

      if (rendered === true) {
        props?.setStoredLayouts(newConfig)
      }


      // }


    }
  }, [chartWrapperRef.current?.getBoundingClientRect().height, rendered]);


  return (
    <div id="HorizontalChart__Wrapper" className={styles.HorizontalChart__Wrapper} ref={chartWrapperRef}>

      {LOADING_STATE === 'PENDING' && <Shimmer />}

      {
        (LOADING_STATE === 'PENDING' && data?.length === 0 || !data)
          ? <RankingSkeleton title={props.title} context={props?.context} />
          : data?.map(datum => datum.ranking).every(rank => rank === null)
            ? <>
              <div className={styles.HorizontalChart__Title}>
                {t(props.title)}

              </div>
              <div className={styles.HorizontalChart__Buttons__Wrapper} style={{ marginBottom: 10 }}>
                {
                  props.buttons.map((button: any, index: number) => {
                    return (
                      <div
                        key={`HorizontalChart__${index}__button__${button}`}
                        className={`
                          ${styles.HorizontalChart__Button}
                          ${props?.combinedKeys[0].includes(props.graphKeys[0][index])
                            ? styles.HorizontalChart__Button__Touch
                            : ''
                          }
                          `}
                      >
                        {t(button)}
                        <div className={styles.HorizontalChart__Button__Underline} style={{ backgroundColor: `${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}` }}></div>
                      </div>
                    )


                  })
                }
              </div>
              <RankingSkeleton title={props.title} context={props?.context} hideTitle={true} />
            </>
            : <>

              <div className={styles.HorizontalChart__Title}>
                {t(props.title)}

              </div>


              <div className={styles.HorizontalChart__Buttons__Wrapper}>
                {
                  props.buttons.map((button: any, index: number) => {
                    return (
                      <div
                        key={`HorizontalChart__${index}__button__${button}`}
                        className={`
                    ${styles.HorizontalChart__Button}
                    ${props?.combinedKeys[0].includes(props.graphKeys[0][index])
                            ? styles.HorizontalChart__Button__Touch
                            : ''
                          }
                    ${!activeKeys[0]?.includes(props.graphKeys[0][index])
                            ? styles.HorizontalChart__Button__Disabled
                            : ''
                          }
                  `}
                        onClick={() => handleButtonClick(typeof props.graphKeys[0] === 'string' ? props.graphKeys[index] : button)}
                      >
                        {t(button)}
                        <div className={styles.HorizontalChart__Button__Underline} style={{ backgroundColor: `${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}` }}></div>
                      </div>
                    )


                  })
                }
              </div>

              <div ref={chartWrapperRef}>
                {
                  data.length === 0
                    ? <></>
                    : props?.context === 'energy'
                      ?
                      (data || []).map((line: any, lineIndex: number, lineArray: any) => (
                        <div key={`energy_horzontal_chart_${lineIndex}`} className={styles.HorizontalChart__Wrapper__Line}
                          style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column' }}
                        >

                          <div className={styles.HorizontalChart__Wrapper__Line__Label} onClick={() => handleNavigation(line)}>
                            {
                              line?.pod || 'undefined pod'
                            }
                          </div>
                          {
                            (line?.siteType?.[0]?.siteType === 'producer' || line?.siteType?.type === 'producer')
                              ? <></>
                              : <div className={styles.HorizontalChart__Wrapper__Line__Rank}>
                                <div className={styles.HorizontalChart__Wrapper__Line__Rank__Value__Name__Wrapper}>
                                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Value}>{getKpiValue(line, 0)}</p>
                                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Name}> {t(props?.rankingKpiName[0])}</p>

                                </div>

                                <HorizontalChartDouble data={{
                                  kpi: line?.kpi[0],
                                  ...props,
                                  graphKeys: props?.graphKeys[0],
                                  rankingKpiName: props?.rankingKpiName[0],
                                  activeKeys: activeKeys[0],
                                  activeTooltip,
                                  setActiveTooltip: (data) => setActiveTooltip(data),
                                  key: `energy_horzontal_chart_${lineIndex}_0`,
                                  tooltipColors: props?.tooltipColors[0],
                                  ...( (lineIndex === lineArray.length - 1 && ( line?.siteType?.[0]?.siteType === 'consumer' || line?.siteType?.type === 'consumer') ) ? { setRendered } : {})
                                }} />
                              </div>
                          }
                          {
                            (line?.siteType?.[0]?.siteType === 'consumer' || line?.siteType?.type === 'consumer')
                              ? <></>
                              : <div className={styles.HorizontalChart__Wrapper__Line__Rank} style={{ marginTop: 8 }}>

                                <div className={styles.HorizontalChart__Wrapper__Line__Rank__Value__Name__Wrapper}>
                                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Value}>{getKpiValue(line, 1)}</p>
                                  <p className={styles.HorizontalChart__Wrapper__Line__Rank__Name}> {t(props?.rankingKpiName[1])}</p>

                                </div>
                                <HorizontalChartDouble data={{
                                  kpi: line?.kpi[1],
                                  ...props,
                                  // props: {
                                  //   ...props,
                                  //   data: {
                                  //     ...props.data,
                                  //     graphKeys:
                                  //   }
                                  // },
                                  graphKeys: props?.graphKeys[1],
                                  rankingKpiName: props?.rankingKpiName[1],
                                  activeKeys: activeKeys[1],
                                  activeTooltip,
                                  setActiveTooltip: (data) => setActiveTooltip(data),
                                  key: `energy_horzontal_chart_${lineIndex}_1`,
                                  tooltipColors: props?.tooltipColors[1],
                                  ...( lineIndex === lineArray.length - 1 ? { setRendered } : {})

                                }} />
                              </div>
                          }

                        </div>

                      ))

                      : props?.context === 'economics'
                        ? (data || []).map((item: any, index: number, itemArray: any) => {
                          return <div key={`economics_horzontal_chart_${index}`} className={styles.HorizontalChart__Wrapper__Line}>
                            <div className={styles.HorizontalChart__Wrapper__Line__Label} onClick={() => handleNavigation(item)}>
                              {
                                item?.pod
                              }

                              {props?.context === 'economics' && <span className="font-flexo-light">&nbsp; {item?.siteName}</span>}
                            </div>
                            <div className={styles.HorizontalChart__Wrapper__Line__Rank}>
                              <div className={styles.HorizontalChart__Wrapper__Line__Rank__Value__Name__Wrapper}>
                                <p className={styles.HorizontalChart__Wrapper__Line__Rank__Value}>{getKpiValue(item)}</p>
                                <p className={styles.HorizontalChart__Wrapper__Line__Rank__Name}> {t(props?.rankingKpiName)}</p>

                              </div>
                              <HorizontalChart data={{
                                kpi: item?.kpi,
                                ...props,
                                activeKeys,
                                ...( index === itemArray.length - 1 ? { setRendered } : {})

                              }} />
                            </div>

                          </div>
                        })
                        : <></>
                }
              </div>
            </>
      }





    </div>
  )

}


export default React.memo(HorizontalChartWrapper);