
import { TableCell, Table, TableContainer, TableHead, TableRow, TableBody } from '@mui/material';
import styles from './skeleton.module.scss';

export function TableSkeleton(props: any) {

  const { rows, title = null, context, hasShimmer = false } = props;

  const contextClass = context === 'members'
    ? styles.TableSkeleton__Table__Members
    : context === 'communities'
      ? styles.TableSkeleton__Table__Communities
      : {}

  return (
    <div className={`${styles.TableSkeleton}  ${ hasShimmer ? styles.TableSkeleton__Pulse : '' }`} >

      <div className={styles.TableSkeleton__Title}>
        { title && title}
      </div>

      

      <TableContainer className={`${styles.TableSkeleton__TableContainer}`}>
        <Table className={`${styles.TableSkeleton__Table} ${contextClass}`} >
          <TableHead className={styles.TableSkeleton__TableHead}>
            <TableRow className={styles.TableSkeleton__TableHead__Row}>
              {rows.map((col: any, index: number) => (
                <TableCell
                  key={`TableSkeleton__Head__Row__Cell_${index}`}
                  className={styles.TableSkeleton__TableHead__Row__Cell}>
                    {col.title}
                  </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ position: 'relative' }} className={hasShimmer ? styles.TableSkeleton__TableBody__Pulse : ''}>

            {Array.from({ length: 4 })
              .map((_, index) => (
            <TableRow key={`TableSkeleton__Body__Row_${index}`} className={styles.TableSkeleton__TableBody__Row}>
              {rows.map((col: any, _index: number) => (
                  <TableCell
                    key={`TableSkeleton__Body__Row__${index}__Cell_${_index}`}
                  className={styles.TableSkeleton__TableBody__Row__Cell}>
                  <div>
                      <div className={styles.TableSkeleton__TableBody__Row__Cell__Span}></div>
                      <div className={styles.TableSkeleton__TableBody__Row__Cell__Span}></div>
                  </div>
                    </TableCell>
                ))}
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


    </div>
  );
}