//save as site: {
// 1day:{}
//1hour:{}
// }
//event driven development
import { useContext, useEffect } from 'react';

import { AlertContext, AlertTypes } from '@flexo/providers';

import { FirebaseService } from '@flexo/general';
import { useDispatch, useSelector } from 'react-redux';
import { KPI, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const KpiCommunityResolver = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const metadata = useSelector((state: any) => state.metadata);
  const calendar = useSelector((state: any) => state.calendar);

  const { setAlertStore } = useContext(AlertContext);

  const { hiveCalendar } = calendar;
  const { selectedDate, selectedTimeSet } = hiveCalendar;
  const pathSegment = location.pathname.split('/')[1];

  async function init() {
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    const COMMUNITY_ID = params?.id;

    if (!COMMUNITY_ID) {
      return;
    }

    if (metadata?.entities.length === 0) return;

    dispatch(
      KPI.ResetNamedKpiFields(['site', 'member', 'memberRanking'] as any)
    );

    dispatch(
      KPI.SetKpiDataCommunityAsync({
        COMMUNITY_ID,
        idToken,
        selectedDate,
        selectedTimeSet,
        metadata,
      }) as any
    );
  }

  useEffect(() => {
    init();
  }, [params.id, selectedDate, pathSegment, metadata?.entities]);

  return <></>;
};
