import styles from './skeleton.module.scss';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';

const ENV = (import.meta as any).env;

export function ChartSkeleton(props: any) {
  const { title, isNull = false } = props;
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.ChartSkeleton} ${
        ENV.VITE_APP_NAME !== 'admin' && styles.ChartSkeleton__Mobile
      } 
      
     `}
      style={ENV.VITE_APP_NAME !== 'admin' && !isNull ? { width: '100%' } : {}}
    >
      {isNull === false && (
        <>
          <div className={styles.ChartSkeleton__Title}>{t(title)}</div>
          <div className={styles.ChartSkeleton__Header}>
            <div className={styles.ChartSkeleton__Header__Label}></div>
            <div className={styles.ChartSkeleton__Header__Label}></div>
            <div className={styles.ChartSkeleton__Header__Label}></div>
          </div>
        </>
      )}

      <div
        className={styles.ChartSkeleton__Content}
        style={isNull ? { marginTop: -72 } : {}}
      >
        <div className={styles.ChartSkeleton__Content__Unit}></div>
        <div className={styles.ChartSkeleton__Content__Axes}>
          <IconWrapper
            iconName={isNull ? 'no_data_chart' : 'loading'}
            style={{ marginBottom: 8 }}
          />
          <div className={styles.Skeletons__Label}>
            {isNull
              ? 'Data unavailable, please check again later'
              : 'Loading...'}
          </div>
        </div>
        <div className={styles.ChartSkeleton__Content__TimeSets}>
          <div
            className={styles.ChartSkeleton__Content__TimeSets__TimeSet}
          ></div>
          <div
            className={styles.ChartSkeleton__Content__TimeSets__TimeSet}
          ></div>
          <div
            className={styles.ChartSkeleton__Content__TimeSets__TimeSet}
          ></div>
          <div
            className={styles.ChartSkeleton__Content__TimeSets__TimeSet}
          ></div>
          <div
            className={styles.ChartSkeleton__Content__TimeSets__TimeSet}
          ></div>
        </div>
      </div>
    </div>
  );
}
