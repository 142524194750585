import React, { useContext, useEffect, useRef, useState } from 'react';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import { useTranslation } from 'react-i18next';

import styles from './map.module.scss';
import { IconWrapper } from '@flexo/general';
import { navigateToSite } from '../resolvers/site-overview-resolver';
import { timeFormat } from 'd3-time-format';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { RIGHT_DRAWER } from '@flexo/reducers';
import { MapSkeleton } from '../skeletons/map-skeleton';
import Shimmer from '../shimmer/shimmer';
import { navigateToMember } from '../resolvers/member-resolver';
import { ReactSVG } from 'react-svg';
import { ThemeContext } from '@flexo/providers';


export function EsriMapComponent(props) {

  const { ThemeStore } = useContext(ThemeContext);


  const { wrapper = null } = props;
  const dispatch = useDispatch();
  const metadata = useSelector((state: any) => state.metadata);
  const location = useLocation();
  const params = useParams();
  const pathSegment = location.pathname.split('/')[1];
  const paramsId = params?.id || location.pathname.split('/')[2];



  const formatDate = timeFormat('%d/%m/%Y');
  const { t } = useTranslation();
  const LOADING_STATE = metadata?.EntitiesAsyncStatus;

  // Riferimento al div che contiene la mappa
  const mapDiv: any = useRef<HTMLDivElement>(null);
  const mapDivLarge: any = useRef<HTMLDivElement>(null);

  const mapRef = useRef<__esri.Map | null>(null);
  const viewRef = useRef<__esri.MapView | null>(null);
  const layerRef: any = useRef<__esri.GraphicsLayer | null>(null);


  const [shownSite, setShownSite] = useState<any>(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const [shownSites, setShownSites] = useState<any>(null);

  let mapHeight = wrapper === 'drawer' ? 'calc( 100vh + 16px )' : 216;
  let radius = 16;


  let sites = (metadata?.entities as any)?.sites || null;
  let singleSite: any = null;
  const context = pathSegment || '';

  if (context?.includes('site')) {
    singleSite = (metadata as any)?.entities?.site?.find(
      (s: any) => s.siteId === paramsId
    ) ||
      null;
    sites = [singleSite];
  }

  if (context?.includes('community')) {
    sites = [
      ...((metadata as any)?.entities?.site?.filter(
        (site: any) => site.communityId === paramsId
      ) || []),
    ];


  }

  if (context?.includes('member')) {
    sites = (metadata?.entities as any)?.site?.filter(
      (site: any) => site.memberId === paramsId
    );
  }

  sites = (sites || []).map(site => ({
    ...site,
    member: metadata?.entities?.member?.find((_m: any) => _m.memberId === site?.memberId)
  }));


  if (['site-energy', 'site-economics', 'community-energy', 'community-economics', 'member-energy']?.includes(context)) {
    mapHeight = 400;
    radius = 0;

    const sitesTypes = sites?.map((site: any) => site?.siteType?.[0]?.siteType || site?.type || 'default') || [];
    if (
      singleSite?.siteType?.[0]?.siteType === 'consumer' ||
      singleSite?.type === 'consumer' ||
      singleSite?.siteType?.[0]?.siteType === 'producer' ||
      singleSite?.type === 'producer'
    ) {
      radius = 16;
      mapHeight = 216;
    } else if (sitesTypes?.every((el) => el === 'consumer' || el === 'producer') && context === 'member-energy') {
      radius = 16;
      mapHeight = 216;
    }
  }

  if (wrapper === 'drawer') {
    radius = 0;
    mapHeight = '100vh';
  }


  function goToSite(site: any) {

    if (wrapper === 'drawer') {
      dispatch(RIGHT_DRAWER.ResetDrawer())
    }
    setShownSite(null);

    if (site?.siteId === paramsId) { return; }

    let siteContext = 'site'

    if (context?.includes('energy')) {
      siteContext = 'site-energy';
    } else if (context?.includes('economics')) {
      siteContext = 'site-economics';
    }

    navigateToSite(siteContext, site?.siteId);
  }

  function goToMember(member: any) {

    if (wrapper === 'drawer') {
      dispatch(RIGHT_DRAWER.ResetDrawer())
    }
    setShownSite(null);

    if (member?.memberId === paramsId) { return; }

    let siteContext = 'member'

    if (context?.includes('energy')) {
      siteContext = 'member-energy';
    } else if (context?.includes('economics')) {
      siteContext = 'member-economics';
    }

    navigateToMember(siteContext, member?.memberId);
  }

  function expandMap() {

    showSite(null);

    dispatch(RIGHT_DRAWER.SetDrawer({
      ...props,
      type: 'EXPANDED_MAP',
    }))

  }

  function closeDrawer() {
    setShownSite(null);
    dispatch(RIGHT_DRAWER.ResetDrawer())

  }

  function showSite(siteData: any) {
    setShownSite(siteData);
  }

  console.log(ThemeStore, 'ThemeStore?.colors');

  // On mount, create the map, view, and teardown

  const simpleMarkerSymbol = {
    type: 'simple-marker',
    color: ThemeStore?.colors?.primary,
    size: 15,
    outline: {
      color: [255, 255, 255], // White
      width: 2,
    },
    cursor: 'pointer',
  };

  const disabledSimpleMarkerSymbol = {
    type: 'simple-marker',
    color: [222, 222, 222],
    size: 15,
    outline: {
      color: [255, 255, 255], // White
      width: 2,
    },
    cursor: 'pointer',
  };

  useEffect(() => {

    setMapInitialized(false);

    if (LOADING_STATE === 'PENDING' && document.readyState === "complete") { return; }

    const mainMap = new ArcGISMap({
      basemap: 'streets-navigation-vector',
    });

    const graphicsLayer = new GraphicsLayer()

    const view = new MapView({
      container: wrapper === 'drawer' ? mapDivLarge?.current : mapDiv.current,
      map: mainMap,
      center: [0, 0],
      zoom: 13,
      padding: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
      }
    })

    mainMap.add(graphicsLayer);

    if (wrapper !== 'drawer') {
      view.on('drag', () => {
        setShownSite(null);
      })
    }


    view.on('click', (event) => {

      view.hitTest(event).then((response) => {

        if (response.results.length > 0) {

          const graphicResult = response.results.find((r: any) => r.graphic?.layer === graphicsLayer);
          if (graphicResult) {

            const siteData = (graphicResult as any).graphic.attributes;
            const geometry = (graphicResult as any).graphic.geometry;

            let mapSitesList = sites.filter((s: any) => `${s.siteLatitude}` === `${siteData.siteLatitude}` && `${s.siteLongitude}` === `${siteData.siteLongitude}`)
            const screenPoint = view.toScreen(geometry as any);

            if (mapSitesList.length === 0) {
              mapSitesList = [siteData];
            }


            showSite({
              sitesList: mapSitesList,
              screenX: screenPoint.x,
              screenY: screenPoint.y
            });

          } else {
            
            if (wrapper === 'drawer') {
              setShownSite({
                sitesList: sites,
              })
            } else {
              setShownSite(null);
            }

          }
        }
      });
    });

    if (wrapper !== 'drawer') {
      view.on('mouse-wheel', () => setShownSite(null));
    }

    view?.when(() => {
      if (graphicsLayer && graphicsLayer.graphics.length > 0) {
        view?.goTo(graphicsLayer.graphics.toArray(), { animate: true });
      }
    });

    mapRef.current = mainMap;
    viewRef.current = view;
    layerRef.current = graphicsLayer;

    setTimeout(() => {
      setMapInitialized(true);
      setShownSite(null)
    }, 1500);

    return () => {
      view && view.destroy();
    };

  }, [LOADING_STATE, document.readyState, params.id])

  useEffect(() => {

    if (!viewRef?.current || !layerRef.current || mapInitialized === false) { return }

    layerRef.current.removeAll();

    (sites || []).forEach((site: any) => {

      if (site?.siteId && (!site?.siteLatitude || !site?.siteLongitude)) {
        const coords = (metadata?.entities as any)?.site?.find((_s: any) => _s.siteId === site.siteId);
        site = {
          ...site,
          ...coords,
        };
      }
      if (!site?.siteId || !site?.siteLongitude || !site?.siteLatitude) {
        return; // skip
      }

      const pointGraphic = new Graphic({
        geometry: {
          type: 'point',
          longitude: site.siteLongitude,
          latitude: site.siteLatitude,
        },
        symbol: !shownSite?.sitesList ? simpleMarkerSymbol : shownSite?.sitesList?.some((_s) => _s.siteId === site?.siteId) ? simpleMarkerSymbol : disabledSimpleMarkerSymbol,
        attributes: site,
      } as any);

      layerRef.current?.add(pointGraphic);
    });

    if (JSON.stringify(shownSites) !== JSON.stringify({ sitesList: sites })) {
      viewRef?.current?.goTo(layerRef?.current?.graphics.toArray(), { animate: true });
      setShownSites({
        sitesList: sites
      });
    }

    if (wrapper === 'drawer' && mapInitialized && JSON.stringify(shownSites) !== JSON.stringify({ sitesList: sites })) {
      setShownSite({
        sitesList: sites,
      })
    }

  }, [sites, mapInitialized, params.id, shownSites]);

  useEffect(() => {
    setShownSite(null);
  }, [params.id, pathSegment]);

  return (
    <div className={styles.Map__Wrapper__Background} style={{ borderRadius: radius }}>
      {
        (LOADING_STATE === 'PENDING') &&
        <Shimmer />
      }
      {
        (!sites || sites?.length === 0)
          ? <MapSkeleton radius={radius} mapHeight={mapHeight} />
          : <div className={styles.Map__Wrapper} style={{ borderRadius: radius }}>

            {wrapper !== 'drawer' && shownSite && (
              <div
                className={styles.Map__Shown__Site}
                style={{ left: shownSite.screenX, top: shownSite.screenY }}

              >
                <div className={styles.Map__Shown__Site__Wrapper} >
                  {/* onMouseLeave={() => setShownSite(null)} */}
                  {
                    shownSite?.sitesList?.map((site: any, siteIndex: number) => (
                      <div key={`site_${site.siteId}`} className={styles.Map__Shown__Site__Item}>
                        <p
                          className={styles.Map__Shown__Site__Pod}
                          onClick={() => goToSite(shownSite.sitesList[siteIndex])}
                        >
                          {site?.pod}
                        </p>
                        <p className={styles.Map__Shown__Site__Name}>{site?.siteName}</p></div>
                    ))
                  }
                </div>


              </div>
            )}

            {wrapper === 'drawer'
              ? <div ref={mapDivLarge} id="mapViewNodeLarge" style={{ width: '100%', height: mapHeight, ...(wrapper === 'drawer' ? { marginTop: 32 } : {}) }} />
              : <div ref={mapDiv} id="mapViewNode" style={{ width: '100%', height: mapHeight, ...(wrapper === 'drawer' ? { marginTop: 32 } : {}) }} />
            }


            {wrapper !== 'drawer' && (
              <div className={styles?.Map__Button__Expand} onClick={() => expandMap()}>
                <IconWrapper iconName="expand" />
              </div>
            )}


            {
              wrapper === 'drawer' && (
                <div className={styles?.Map__Shown__Site__Expanded__Wrapper}>
                  <div className={styles?.Map__Expanded__Wrapper__Scroller}>
                    {shownSite?.sitesList?.map((site: any, siteIndex: number) => (
                      <div
                        key={`site_expand_${site.siteId}`}
                        className={styles?.Map__Shown__Site__Expanded}
                        style={{
                          opacity: wrapper === 'drawer' && shownSite ? 1 : 0,
                          height: wrapper === 'drawer' && shownSite ? 'auto' : 0,
                        }}
                      >
                        <p
                          className={styles?.Map__Shown__Site__Expanded__Pod}
                          onClick={() => goToSite(site)}
                        >
                          <span>{site?.pod}</span>
                          {site?.siteName}
                        </p>
                        <p className={styles?.Map__Shown__Site__Expanded__Member}
                          onClick={() => goToMember(site?.member)}>
                          <span>
                            {site?.member?.memberFirstName} {site?.member?.memberLastName}
                          </span>
                          {site?.member.memberExternalId || '-'}
                        </p>
                        <p className={styles?.Map__Shown__Site__Expanded__Address}>
                          <span>{site?.member?.address}</span>
                          {site?.member?.city}, {site?.member?.postcode}
                        </p>
                        <p className={styles?.Map__Shown__Site__Expanded__Type}>
                          {t(`widgets.map.${site?.siteType?.[0]?.siteType || 'default'}`)}
                        </p>
                        <p className={styles?.Map__Shown__Site__Expanded__Owned}>
                          <span>{t('widgets.map.owned')}:</span>&nbsp;
                          {site?.joinDate
                            ? formatDate(new Date(site.joinDate))
                            : 'Unknown join date'}{' '}
                          -
                        </p>
                      </div>
                    ))}
                  </div>

                </div>
              )
            }




          </div>
      }
      {wrapper === 'drawer' ? (
        <div key={'closeDrawerButton'} className={styles?.Map__Button__Close} onClick={() => closeDrawer()}>
          <ReactSVG src={"icons/cross.svg"} />
        </div>
      ) : <></>}
    </div>
  );
}