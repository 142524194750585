import { useContext, useEffect, useState } from 'react';
import { Button, Textfield } from '@flexo/atoms';
import {
  FirebaseService,
  IconWrapper,
  REGEX,
  httpService,
} from '@flexo/general';
import styles from './log-in.module.scss';
import { AlertContext, AlertTypes } from '@flexo/providers';
import { Player } from '@lottiefiles/react-lottie-player';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { USER } from '@flexo/reducers';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ENV = (import.meta as any).env;
const firebaseService = new FirebaseService();

let _authData = {
  email: '',
  password: '',
  createPassword: '',
};

/* eslint-disable-next-line */
export interface WebLogInProps {
  logInData: any;
  setLogInFlow: any;
  bgColor?: string;
}

export function WebLogIn({ setLogInFlow }: WebLogInProps) {
  const { LogIn__Inputs, LogIn__Footer, LogIn__Footer__Button } = styles;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [authData, setAuthData] = useState({
    email: '',
    password: '',
    createPassword: '',
  });

  const [logInClicked, setLogInClicked] = useState(false);
  const { alertStore, setAlertStore } = useContext(AlertContext);
  const [logInError, setLogInError] = useState(false);
  const [networkStatus, setNetworkStatus] = useState(false);
  const { t } = useTranslation();
  const [inputType, setInputType] = useState({
    email: 'text',
    password: 'password',
    confirmPassword: 'password',
  });

  const { title, inputs, buttonLabel } = t('pages.login.data', {
    returnObjects: true,
  }) as any;

  const loader = '/loaderLottie.json';

  const handleInputChange = (event: any) => {
    _authData = {
      ...authData,
      [event.target.name]: event.target.value,
    };
    setAuthData({ ...authData, [event.target.name]: event.target.value });
  };

  const signIn = async (authData: any) => {
    setLogInClicked(true);
    setLogInError(false);
    try {
      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: start`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'info' as any,
      });

      // First, get the user token.
      const response: any = await httpService.post({
        url: `${ENV.VITE_APP_BASE_URL}/auth/v1_1/generate-signin-token`,
        data: {
          email: authData.email,
          password: authData.password,
          // tenant_id: ENV.VITE_APP_TENANT,
          // org_id: ENV.VITE_APP_ORG_ID,
        },
      });

      // Use the token from the response to sign in.
      const firebaseResult = await firebaseService.signInWithToken(
        response?.data?.token
      );

      try {
        const userRole = JSON.parse(
          (firebaseResult.user as any)?.reloadUserInfo?.customAttributes
        );
        if (
          userRole?.user_role !== 'administrator' &&
          userRole?.user_role !== 'supervisor'
        ) {
          setAlertStore({
            type: AlertTypes.SetAlert,
            payload: {
              value: {
                type: 'ALERT_NOT_ALLOWED',
              } as any,
            },
          });

          firebaseService.signOut();

          _authData = {
            email: '',
            password: '',
            createPassword: '',
          };

          setAuthData(_authData);

          return;
        }
      } catch (error) {
        // console.warn(error);
      }

      // dispatch(MODULES.ActivateModuleByName(ENV.VITE_APP_FIRST_PAGE))
      dispatch(USER.SetUserAsync(firebaseResult as any) as any);


      return firebaseResult;
    } catch (error: any) {
      if (error.message === 'ERR_NETWORK') {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_UNEXPECTED_ERROR',
            } as any,
          },
        });
      } else {
        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_WRONG_LOGIN',
            } as any,
          },
        });
      }

      _authData = {
        ...authData,
        password: '',
        createPassword: '',
      };

      setAuthData(_authData);

      setLogInError(true);

      Sentry.captureEvent({
        // event_id: 'fetchData_no_data',
        message: `Performed request through firebase: error`,
        release: `${ENV.VITE_APP_VERSION}(${ENV.VITE_APP_BUILD})`,
        level: 'warning' as any,
        extra: {
          error,
        },
      });

      // console.error(error);
    } finally {
      // Reset logInClicked state after attempt.
      setLogInClicked(false);
    }
  };

  const togglePasswordVisibility = (name: string) => {
    setInputType((prevState) => ({
      ...prevState,
      [name]: prevState[name] === 'password' ? 'text' : 'password',
    }));
  };

  function handleDescriptionClick(inputName: string) {
    if (inputName === 'password') {
      navigate('/forgot-password');
    }

    return null;
  }

  function focusChild(event: any) {
    try {
      const parent = event.target;
      const child = parent.querySelector('input');
      child.focus();
    } catch (error) {
      console.warn(error);
    }
  }

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
  
        if (
          _authData.password === '' ||
          !REGEX.email.test(_authData.email) ||
          !logInClicked &&
          !logInError
        ) {
          signIn(_authData);
        }
      }
    }
  
    // Aggiungi il listener quando il componente si monta
    window.addEventListener('keydown', handleKeyDown);
  
    // Rimuovi il listener quando il componente si smonta o lo stato cambia
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [logInClicked, logInError]);

  return (
    <div className={styles.LogIn}>
      <div className={styles.LogIn__Logo}>
        <IconWrapper iconName="logo_wide" />
      </div>
      <div className={styles.Login__Content}>
        <div className={styles.LogIn__Header__Title}>{title}</div>

        <form className={LogIn__Inputs}>
          {inputs?.map((input: any, index: number) => (
            <div key={input.name} onClick={(e) => focusChild(e)}>
              <Textfield
                additionalStyles={{ margin: '0px!important' }}
                containedLabel={false}
                name={input.name}
                value={authData[input.name]}
                placeholder={input.placeholder}
                label={input.label}
                onChange={(event) => handleInputChange(event)}
                onPaste={(e) => false}
                onCopy={(e) => false}
                // type={input.type}
                description={input.description}
                type={inputType[input.name]}
                onIconClick={togglePasswordVisibility}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
                autoComplete="off"
                webError={logInError}
                {...(input?.description
                  ? {
                      onDescriptionClick: () =>
                        handleDescriptionClick(input.name),
                    }
                  : {})}
                {...(input?.descriptionFault && logInError
                  ? {
                      descriptionFault: input.descriptionFault,
                    }
                  : {})}
                {...(input.name === 'password'
                  ? {
                      icon:
                        inputType[input.name] === 'password' ? 'show' : 'hide',
                    }
                  : {})}
                underline={logInError === true ? 'visible' : 'hidden'}
                //FIX ME --> this is commented out for testing purposes on plenitude
              />
            </div>
          ))}
        </form>
        <div className={LogIn__Footer}>
          <Button
            className={LogIn__Footer__Button}
            onClick={() => signIn(_authData)}
            label={
              logInClicked && !logInError ? (
                <Player
                  autoplay
                  loop
                  src={loader}
                  style={{ height: 20, transform: 'scale(0.7)' }}
                />
              ) : (
                buttonLabel
              )
            }
            variant="primary"
            color="secondary"
            disabled={
              authData.password === '' ||
              !REGEX.email.test(authData.email) ||
              // !REGEX.password.test( authData.password ) ||
              (logInClicked && !logInError) ||
              networkStatus
            }
          />
        </div>
      </div>
    </div>
  );
}

export default WebLogIn;
