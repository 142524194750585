import { useContext, useEffect } from 'react';

import { Calendar, HiveCalendar } from '@flexo/molecules';
import styles from './header.module.scss';
import { ModalContext, ModalTypes } from '@flexo/providers';
import { IconWrapper } from '@flexo/general';
import React from 'react';
import {
  CommunityDropdownMobile,
  Dropdown,
  Search,
  SitesDropDown,
  SitesDropDownMobile,
} from '@flexo/atoms';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Header(props) {
  const {
    Header__Title,
    Header__Actions,
    Header__Actions__Button,
    Header__Title__Text,
    Header__Heading,
    Header__Calendar,
  } = styles;

  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];
  const metadata = useSelector((state: any) => state.metadata);

  const { modalStore, setModalStore } = useContext(ModalContext);

  const onClickProfile = () => {
    setModalStore({
      type: ModalTypes.OpenModal,
      payload: {
        value: {
          type: 'PROFILE',
          visible: true,
          show: true,
          props: { ...props },
          content: 'PROFILE',
        } as any,
      },
    });
  };

  const visibleModule = pathSegment;

  useEffect(() => {
    const fetchInsets = async () => {
      try {
        const { insets } = await SafeArea.getSafeAreaInsets();
        // setInsets(insets);
      } catch (error) {
        console.error('Error fetching safe area insets:', error);
      }
    };

    fetchInsets();
  }, []);
  return (
    <div
      className={` ion-safe-area ${styles.Header} ${
        pathSegment !== 'household' ? styles.Header__Community : ''
      } 
     `}
    >
      <div className={Header__Heading}>
        {/* {JSON.stringify(metaDataApiStore.selectedEntity)}
        {JSON.stringify(userStore.user)} */}

        <div className={`${Header__Title} heading1`}>
          {(metadata.selectedEntity && visibleModule === 'household') ||
          visibleModule === '' ? (
            <SitesDropDownMobile />
          ) : (
            <CommunityDropdownMobile />
          )}
        </div>

        <div className={Header__Actions}>
          {/* <button
            className={Header__Actions__Button}
            onClick={onClickNotifications}
          >
            <IconWrapper iconName="notifications" />
          </button> */}
          <button
            className={Header__Actions__Button}
            onClick={() => onClickProfile()}
            disabled={(modalStore?.modal as any).open === true}
          >
            <IconWrapper
              iconName="user_solid"
              style={{ marginRight: '-8px' }}
            />
          </button>
        </div>
      </div>
      <div className={Header__Calendar}>
        <HiveCalendar />
      </div>
    </div>
  );
}

export default React.memo(Header);
