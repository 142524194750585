import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// visx
import { ParentSize } from '@visx/responsive';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { Line } from '@visx/shape';
import { localPoint } from '@visx/event';
import { bisector, sort } from 'd3-array';
import styles from './area-chart.module.scss';

// visx tooltip
import {
  useTooltip,
  useTooltipInPortal,
  TooltipWithBounds,
  defaultStyles,
} from '@visx/tooltip';

// d3-shape
import { area, curveMonotoneX } from 'd3-shape';

// react-spring
import { useSpring, animated } from 'react-spring';
import { formatDate } from '../helpers';
import { DateTime } from 'luxon';
import { dot } from 'node:test/reporters';
import { Haptics } from '@capacitor/haptics';
import { set } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { ChartSkeleton } from '@flexo/atoms';

/** Replace with your own date formatting logic. */

const ENV = (import.meta as any).env || {};
ENV.VITE_APP_NAME = ENV.VITE_APP_NAME || 'admin';

/**
 * A custom AnimatedAreaClosed component, which animates the area path
 * from one valid path string to another, avoiding the "AnimatedValue vs. AnimatedString" error.
 */

function AnimatedAreaClosed<T>({
  data,
  x,
  y,
  y0,
  fill,
  stroke,
  fillOpacity,
  curve = curveMonotoneX,
  yScale,
}: {
  data: T[];
  x: (d: T, i: number) => number;
  y: (d: T, i: number) => number;
  y0: (d: T, i: number) => number;
  yScale: any;
  fill?: string;
  stroke?: string;
  fillOpacity?: number;
  curve?: any;
}) {
  // Compute the current area path

  const pathString = useMemo(() => {
    if (!data || !data.length) return 'M0,0';

    const areaGenerator = area<T>().x(x).y0(y0).y1(y).curve(curve);

    return areaGenerator(data) || 'M0,0';
  }, [data, x, y, y0, curve, yScale]);

  // A ref to store the previous path so we can morph
  const prevPathRef = useRef(pathString);

  // Use a ref to detect the first render
  const isFirstRender = useRef(true);

  // Create our spring
  const [springs, api] = useSpring(() => ({
    path: pathString,
    config: { tension: 170, friction: 26 },
  }));

  useEffect(() => {
    // If there's no data, you can decide what to do—maybe animate to a baseline, or skip:
    if (!data || data.length === 0) {
      // If you want to animate to baseline, you can do something like:
      const emptyPath =
        area<T>()
          .x(x)
          .y0(() => yScale(0))
          .y1(() => yScale(0))
          .curve(curve)([]) || 'M0,0';

      api.start({
        from: { path: prevPathRef.current },
        to: { path: emptyPath },
      });

      // Update prevPathRef to the empty path
      prevPathRef.current = emptyPath;
      return;
    }

    // On the first render, start from baseline → current shape
    // On subsequent renders, morph from old shape → new shape
    const fromPath = isFirstRender.current
      ? area<T>()
          .x(x)
          .y0(() => yScale(0))
          .y1(() => yScale(0))
          .curve(curve)(data) || 'M0,0'
      : prevPathRef.current;

    const toPath = pathString || 'M0,0';

    // Avoid re‐starting the same animation if nothing changed
    // console.log('prevPathRef.current', prevPathRef.current, toPath);
    // if (prevPathRef.current === toPath && !isFirstRender.current) return;

    // Animate from -> to
    api.start({
      from: { path: fromPath },
      to: { path: toPath },
      config: { tension: 170, friction: 26 },
    });

    // Update references for the next render
    prevPathRef.current = toPath;
    isFirstRender.current = false;
  }, [pathString, api, data, x, yScale, curve]);

  // Animated <path />
  const AnimatedPath = animated('path');

  return (
    <AnimatedPath
      d={springs.path}
      fill={fill}
      fillOpacity={fillOpacity}
      stroke={stroke}
    />
  );
}

/**
 * Main chart with:
 * - Animated non-stacked areas from baseline=0.
 * - Interpolated dot positions but discrete nearest tooltip values.
 * - Use of onTouch
onMouse* events for cross-platform interactivity.
 * - Integrated visx Tooltip.
 */
export const NewAreaChart = ({
  colors = [],
  data = [],
  keys = [] as string[],
  title = '',
  unit = '',
  margin = { top: 10, right: 35, bottom: 30, left: 25 },
  containerWidth,
}) => {
  const { t } = useTranslation();

  // Redux
  const calendar = useSelector((state: any) => state.calendar);
  const { selectedTimeSet } = calendar?.hiveCalendar || {
    selectedTimeSet: 'day',
  };
  const kpi = useSelector((state: any) => state.kpi);
  const location = useLocation();

  const pathSegment = location.pathname.split('/')[1];

  const [_selectedTimeSet, setSelectedTimeSet] = useState(selectedTimeSet);
  const [pendingSelectedTimeSet, setPendingSelectedTimeSet] =
    useState(selectedTimeSet);

  const [touching, setTouching] = useState(false); // 🔥 NEW STATE

  let LOADING_STATE = kpi?.KpiDataSiteAsyncStatus;
  if (pathSegment?.includes('community')) {
    LOADING_STATE = kpi?.KpiDataCommunityAsyncStatus;
  }
  if (pathSegment?.includes('member')) {
    LOADING_STATE = kpi?.KpiDataMemberAsyncStatus;
  }

  // Detect when selectedTimeSet changes in Redux (new date selected)
  useEffect(() => {
    if (selectedTimeSet !== _selectedTimeSet) {
      setPendingSelectedTimeSet(selectedTimeSet);
    }
  }, [selectedTimeSet]);

  // Ensure selectedTimeSet updates **only after** loading is complete
  useEffect(() => {
    if (
      LOADING_STATE === 'SUCCESS' &&
      pendingSelectedTimeSet !== _selectedTimeSet
    ) {
      setSelectedTimeSet(pendingSelectedTimeSet);
    }
  }, [LOADING_STATE, pendingSelectedTimeSet, _selectedTimeSet]);

  // Sort data ascending by date
  const sortedData = useMemo(() => {
    if (!data || data.length === 0) return [];

    // Clone the data to avoid mutating the original array
    const cleanedData = [...data];

    // Iterate from the last index to the first
    let hasNonNullValue = false;
    for (let i = cleanedData.length - 1; i >= 0; i--) {
      const entry = cleanedData[i];

      // Check if all values (except date) are null
      const values = Object.values(entry).slice(1); // Exclude the date field
      const allNull = values.every((val) => val === null);

      // If all values are null and no non-null values found ahead, remove the object
      if (allNull && !hasNonNullValue) {
        cleanedData.splice(i, 1);
      } else {
        hasNonNullValue = hasNonNullValue || !allNull;
      }
    }

    // Sort by date after filtering
    return cleanedData.sort(
      (a, b) =>
        new Date((a as any).date).getTime() -
        new Date((b as any).date).getTime()
    );
  }, [data]);

  function checkNullValues(arr) {
    return arr.every((obj) => {
      return Object.keys(obj).every((key) => {
        if (key === 'date') return true; // Ignore date field
        return obj[key] === 0 || obj[key] === null;
      });
    });
  }

  // Visibility toggles
  const [visibleKeys, setVisibleKeys] = useState<Record<string, boolean>>({});

  const toggleKeyVisibility = (key: string) => {
    const visibleCount = Object.values(visibleKeys).filter(Boolean).length;
    // Prevent hiding the last one
    if (visibleKeys[key] && visibleCount === 1) return;
    setVisibleKeys((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // We'll keep a continuous x (hoverX) for the dot to move smoothly,
  // plus a discrete row (closestRow) for real tooltip values.
  const [hoverX, setHoverX] = useState<number | null>(null);
  const [closestRow, setClosestRow] = useState<any | null>(null);
  const [width, setWidth] = useState(containerWidth);

  const innerWidth = width - margin.left - margin.right;

  // visx tooltip setup
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    showTooltip,
    hideTooltip,
    tooltipOpen,
  } = useTooltip<any>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal();

  const containerHeight = ENV.VITE_APP_NAME === 'admin' ? 290 : 245;

  // Bisector to find the closest data point
  const getDate = (d: any) => new Date(d.date).getTime();
  const dateBisector = bisector((d: any) => getDate(d)).left;

  // For the chart header date
  const getTitleDetail = (): string => {
    if (!closestRow) return '';
    return formatDate(closestRow.date, selectedTimeSet);
  };
  // Compute y domain
  const [yMin, yMax] = useMemo(() => {
    let minVal = 0;
    let maxVal = 0;
    let hasData = false;
    sortedData.forEach((row) => {
      keys.forEach((k) => {
        if (!visibleKeys[k]) return;
        const val = row[k] ?? 0;
        if (!hasData) {
          minVal = val;
          maxVal = val;
          hasData = true;
        } else {
          if (val < minVal) minVal = val;
          if (val > maxVal) maxVal = val;
        }
      });
    });
    if (!hasData) {
      return [0, 0];
    }
    if (minVal > 0) {
      minVal = 0;
    }
    return [minVal, maxVal];
  }, [sortedData, keys, visibleKeys]);
  // x scale
  const xMin = data.length ? getDate(data[0]) : 0;
  const xMax = data.length ? getDate(data[data.length - 1]) : 1;

  const xMinTooltip = sortedData.length ? getDate(sortedData[0]) : 0;
  const xMaxTooltip = sortedData.length
    ? getDate(sortedData[sortedData.length - 1])
    : 1;

  const xScale = scaleTime<number>({
    domain: [xMin, xMax],
    range: [margin.left, innerWidth + margin.left],
  });

  const xScaleAxis = scaleTime<number>({
    domain: [xMin, xMax],
    range: [margin.left, innerWidth], // Adjust to align properly
  });

  const xScaleTooltip = scaleTime<number>({
    domain: [xMinTooltip, xMaxTooltip],
    range: [margin.left, innerWidth + margin.left],
  });
  // y scale
  const yScale = scaleLinear<number>({
    domain: [yMin, yMax],
    range: [containerHeight - 30, 10],
    nice: true,
  });

  // Interpolate the dot positions
  const interpolateValues = useCallback(
    (
      xValue: number,
      allKeys: string[]
    ): { key: string; x: number; y: number }[] => {
      if (!sortedData || !sortedData.length) return [];
      if (xValue <= getDate(sortedData[0])) {
        return allKeys.map((k) => ({
          key: k,
          x: xValue,
          y: sortedData[0][k] ?? 0,
        }));
      }
      if (xValue >= getDate(sortedData[sortedData.length - 1])) {
        return allKeys.map((k) => ({
          key: k,
          x: xValue,
          y: sortedData[sortedData.length - 1][k] ?? 0,
        }));
      }

      const idx = dateBisector(sortedData, xValue);
      const leftIdx = idx - 1;
      const rightIdx = idx;
      if (leftIdx < 0 || rightIdx >= sortedData.length) return [];

      const leftD = sortedData[leftIdx];
      const rightD = sortedData[rightIdx];
      const leftX = getDate(leftD);
      const rightX = getDate(rightD);
      const ratio = (xValue - leftX) / (rightX - leftX);

      return allKeys.map((k) => {
        const v1 = leftD[k] ?? 0;
        const v2 = rightD[k] ?? 0;
        const interY = v1 + (v2 - v1) * ratio;
        return { key: k, x: xValue, y: interY };
      });
    },
    [sortedData, dateBisector]
  );

  function getGreyScaledColor(color: string) {
    let _color = color;

    if (color?.includes('#')) {
      const red = parseInt(color.substr(1, 2), 16);
      const green = parseInt(color.substr(3, 2), 16);
      const blue = parseInt(color.substr(5, 2), 16);
      // Calculate the grayscale value
      const grayscale = Math.round(0.299 * red + 0.587 * green + 0.114 * blue);
      // Convert the grayscale value back to a hex string
      const grayscaleHex = grayscale.toString(16).padStart(2, '0');
      // Return the grayscale hex string with '#' prefix
      // if (color === '#A6AFB0' || color === '#4F6367') {
      //   _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex + '50';
      // } else
      _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex + '60';
    }
    return _color;
  }
  // Chart container height

  // Handler for updating states (shared by mouse & touch logic below)
  const updateInteraction = useCallback(
    (svgPoint: { x: number; y: number } | null, width: number, xScale: any) => {
      if (!svgPoint) {
        setHoverX(null);
        setClosestRow(null);
        hideTooltip();
        return;
      }

      const { x } = svgPoint;
      const xVal = xScale.invert(x).getTime();
      setHoverX(xVal);

      const idx = dateBisector(sortedData, xVal);
      let d0 = sortedData[idx - 1];
      let d1 = sortedData[idx];

      if (!d0) d0 = sortedData[0];
      if (!d1) d1 = sortedData[sortedData.length - 1];

      const diff0 = Math.abs(xVal - getDate(d0));
      const diff1 = Math.abs(xVal - getDate(d1));
      const nearest = diff0 < diff1 ? d0 : d1;
      setClosestRow(nearest);

      // Clamping tooltip position to stay within the chart bounds
      const tooltipX = Math.max(
        margin.left, // Minimum bound
        Math.min(x, innerWidth + margin.left + 100) // Maximum bound
      );
      const tooltipY = 0; // Adjust if needed

      if (tooltipX <= 25 || tooltipX >= width - 35) {
        hideTooltip();
        setHoverX(null);
        return;
      }
      showTooltip({
        tooltipLeft: tooltipX,
        tooltipTop: tooltipY,
        tooltipData: nearest,
      });
    },
    [
      dateBisector,
      sortedData,
      hideTooltip,
      showTooltip,
      margin.left,
      margin.right,
    ]
  );

  // MOUSE handlers
  const handleMouseMove = useCallback(
    (evt: React.MouseEvent<SVGRectElement>) => {
      evt.stopPropagation();
      if (!ENV.VITE_APP_IS_WEB) return;

      // if (touching) return; // 🔥 Ignore hover if touch is active

      const point = localPoint(evt);
      updateInteraction(point, width, xScaleTooltip);
      setTouching(true); // 🔥 Enable updates
    },
    [updateInteraction, width, xScaleTooltip, touching]
  );

  const handleMouseLeave = useCallback(() => {
    setHoverX(null);
    setTouching(false); // 🔥 Disable updates
    setClosestRow(null);
    hideTooltip();
  }, [hideTooltip]);

  // TOUCH handlers
  const handleTouchStart = useCallback(
    (evt: React.TouchEvent<SVGRectElement>) => {
      setTouching(true); // 🔥 Enable updates
      evt.stopPropagation();
      Haptics.vibrate({ duration: 10 });
      if (evt.touches.length > 0) {
        const point = localPoint(evt.target as any, evt.touches[0] as any);
        updateInteraction(point, width, xScaleTooltip);
      }
    },
    [updateInteraction, width, xScaleTooltip]
  );

  const handleTouchMove = useCallback(
    (evt: React.TouchEvent<SVGRectElement>) => {
      if (!touching) return; // 🔥 Prevent updates if touch has ended

      if (evt.changedTouches.length > 0) {
        const point = localPoint(
          evt.target as any,
          evt.changedTouches[0] as any
        );
        updateInteraction(point, width, xScaleTooltip);
      }
    },
    [updateInteraction, width, xScaleTooltip, touching]
  );

  const handleTouchEnd = useCallback(() => {
    setTouching(false);
    setHoverX(null);
    setClosestRow(null);
    hideTooltip();

    Haptics.vibrate({ duration: 10 });
  }, [hideTooltip]);

  const dotPositions = useMemo(() => {
    if (hoverX === null) return []; // 🔥 Prevents lingering old values
    return interpolateValues(hoverX, keys).filter(
      (pos) => visibleKeys[pos.key]
    );
  }, [hoverX, keys, visibleKeys]);

  useEffect(() => {
    if (keys.length > 0) {
      const init = keys.reduce((acc, k) => {
        acc[k] = true;
        return acc;
      }, {} as Record<string, boolean>);
      setVisibleKeys(init);
    }
  }, [keys]);

  useEffect(() => {
    const updateWidth = () => {
      if ((containerRef as any).current) {
        const newWidth = (containerRef as any).current.offsetWidth;
        setWidth(newWidth);
      }
    };

    // Attach event listener
    window.addEventListener('resize', updateWidth);

    // Cleanup
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [containerRef]);

  useEffect(() => {
    if (containerWidth > 0) {
      setWidth(containerWidth);
    }
  }, [containerWidth]);

  const generateTickValues = (xMin: number, xMax: number): number[] => {
    if (xMin === xMax) return [xMin]; // Edge case: if min and max are the same

    const step = (xMax - xMin) / 3; // Divide into 4 evenly spaced ticks

    return [xMin, xMin + step, xMin + 2 * step, xMax].map((tick) => {
      const date = new Date(tick);
      date.setMinutes(0, 0, 0); // Round to the nearest full hour
      return date.getTime();
    });
  };

  const tickValues = generateTickValues(xMin, xMax);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: containerHeight,
        margin: '16px',
      }}
      ref={containerRef}
    >
      {/* HEADER */}
      <div
        style={{
          marginBottom: '48px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {closestRow && (
            <div
              className="heading4"
              style={{ color: '#001117', marginRight: '.5em' }}
            >
              {getTitleDetail()}
            </div>
          )}
          <div
            style={{ color: '#001117' }}
            className={closestRow ? 'heading4L' : 'heading4'}
          >
            {t(title)}
          </div>
        </div>

        {/* Legend */}
        <div style={{ height: '40px' }}>
          {keys.map((k, i) => {
            const isVisible = visibleKeys[k];
            // Show real data from closestRow if any
            const activeValue = isVisible
              ? closestRow && closestRow[k] !== undefined
                ? closestRow[k]
                : undefined
              : undefined;

            return (
              <button
                key={k}
                style={{
                  border: 'none',
                  color: '#505558',
                  display: 'inline-block',
                  borderBottom: `4px solid ${
                    isVisible ? colors[i] : '#F7F7F7'
                  } `,
                  background: 'none',
                  marginRight: '1em',
                  textAlign: 'left',
                  cursor: 'pointer',
                  padding: '0',
                }}
                className={` detail `}
                onClick={() => toggleKeyVisibility(k)}
              >
                {t(k)}{' '}
                {activeValue !== undefined && touching ? (
                  <strong>
                    | {activeValue} {unit}
                  </strong>
                ) : (
                  ''
                )}
              </button>
            );
          })}
        </div>
      </div>

      {/* Main Chart */}
      {checkNullValues(sortedData) ? (
        <div style={(ENV.VITE_APP_IS_WEB === 'true' ? {
          marginTop: -44,
          height: '109%',
          width: '97%'
        } : {})}>
          <ChartSkeleton isNull={checkNullValues(sortedData)} />
        </div>
      ) : (
        <ParentSize>
          {({ width, height }) => {
            return (
              <svg
                width={innerWidth}
                height={300}
                style={{ overflow: 'visible' }}
              >
                {/* Axes */}
                <AxisBottom
                  top={height - 30}
                  left={margin.left - 10}
                  scale={xScaleAxis}
                  numTicks={6}
                  tickValues={tickValues}
                  stroke="#F7F7F7"
                  tickStroke="transparent"
                  tickLabelProps={() => ({
                    fontSize: 12,
                    fill: '#838B93',
                    textAnchor: 'middle',
                  })}
                  tickFormat={(value) => {
                    // Convert value to a Luxon DateTime if it's not already
                    const date = DateTime.fromJSDate(new Date(value as any));
                    return formatDate((date as any).toISO(), _selectedTimeSet);
                  }}
                  tickClassName="detail"
                />
                <AxisLeft
                  scale={yScale}
                  numTicks={4}
                  stroke="#F7F7F7"
                  tickStroke="transparent"
                  left={margin.left}
                  tickLabelProps={() => ({
                    fontSize: 12,
                    fill: '#838B93',
                    textAnchor: 'end',
                  })}
                  label={unit}
                  labelProps={{
                    x: 0,
                    y: -10,
                    fill: '#838B93',
                    fontSize: 12,
                    fontWeight: 600,
                    textAnchor: 'end',
                  }}
                  labelClassName={`detail ${styles['rotate']} ${styles['rotate__360']}`}
                  tickClassName="detail"
                />

                {/* Animated Areas */}
                {keys.map((k, i) => {
                  const dataForKey = visibleKeys[k]
                    ? sortedData
                    : sortedData.map((d: any) => ({
                        ...d,
                        // Force the data to zero for this key
                        [k]: 0,
                      }));
                  return (
                    <AnimatedAreaClosed
                      key={k}
                      data={dataForKey}
                      x={(d) => xScale(new Date((d as any).date))}
                      yScale={yScale}
                      y={(d) => yScale(d[k] ?? 0)}
                      y0={() => yScale(0)}
                      fill={
                        hoverX && touching
                          ? getGreyScaledColor(colors[i])
                          : colors[i]
                      }
                      fillOpacity={0.5}
                    />
                  );
                })}

                {/* Hover indicators */}

                {hoverX !== null && touching && dotPositions.length > 0 && (
                  <>
                    {/* Vertical line */}
                    <Line
                      from={{
                        x: xScale(new Date(hoverX)),
                        y: 10,
                      }}
                      to={{
                        x: xScale(new Date(hoverX)),
                        y: height - 30,
                      }}
                      stroke="white"
                      strokeWidth={2}
                      pointerEvents="none"
                    />
                    {(() => {
                      const seenPositions = new Map<string, boolean>();

                      return dotPositions.map((pos: any) => {
                        const i = keys.indexOf(pos.key as string);
                        const cx = Math.round(xScale(new Date(pos.x))); // Round to nearest integer
                        const cy = Math.round(yScale(pos.y)); // Round to nearest integer
                        const positionKey = `${cx},${cy}`;

                        // Check if this position already exists
                        const isDuplicate = seenPositions.has(positionKey);
                        seenPositions.set(positionKey, true);

                        return (
                          <circle
                            key={`dot-${pos.key}`}
                            cx={isDuplicate ? cx + 4 : cx}
                            cy={cy}
                            fill={colors[i]} // Mark duplicates in red
                            // stroke="#fff"
                            strokeWidth={1}
                            r={6}
                          />
                        );
                      });
                    })()}
                  </>
                )}

                {/* Transparent overlay for pointer/touch events */}
                <rect
                  x={0}
                  y={0}
                  width={innerWidth}
                  height={height}
                  fill="transparent"
                  style={{
                    touchAction: 'none', // prevent scroll
                    userSelect: 'none', // prevent text selection
                  }}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  onTouchStart={(event) => {
                    Haptics.vibrate({ duration: 10 });
                    handleTouchStart(event);
                  }}
                />
              </svg>
            );
          }}
        </ParentSize>
      )}
    </div>
  );
};

export default NewAreaChart;
