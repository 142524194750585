
import React from 'react';

import styles from './header-web.module.scss';
import { IconWrapper } from '@flexo/general';
import { useTranslation } from 'react-i18next';
import {  navigateToCommunity, ProfileButton, Search } from '@flexo/atoms';

import './header-web.module.scss';
import { Select } from '@mui/material';
import { Calendar, HiveCalendar } from '@flexo/molecules';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const {
  Header__Web__Left,
  Header__Web__Right,
  Logo,
  Navigation,
  Navigation__BreadCrumb,
  Header__Web__Center,
} = styles;

function CommunityDataVisNavigation(props: any) {

  const { t } = useTranslation()
  
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1]; 
  const metadata = useSelector((state: any) => state.metadata);
  const queryParams = window?.location?.search || '';
  

  const { navigation = null, calendar = null, download = null, searchBar = null, profile = null, breadCrumbs = null } = props;

  const communities = (metadata?.entities as any)?.communities || [];

  const [open, setOpen] = React.useState(false);

  function getCommunity() {
    const COMMUNITY_ID = params?.id|| 'missing_id';

    const foundCommunity = communities?.find((community: any) => (community?.communityId || community?.flexoCommunityId) === COMMUNITY_ID) || communities?.[0] || null;
    
    if ( !foundCommunity) {
      return communities?.[0];
    } else {
      return foundCommunity;
    }

  }

  function getCommunityName() {
    const foundCommunity = getCommunity();
    return (foundCommunity?.communityName) || communities?.[0]?.communityName || 'Community name not found';
  }

  function triggerClick() {
    setOpen( !open )
  }

  return (
<>
      <div className={Header__Web__Left}>
        <div className={Logo}>
          <IconWrapper iconName={'logo'} />
        </div>

       
        
        <div className={Navigation}>
        <label className={ `${styles.clickable}`} onClick={() => navigate(`/communities${queryParams}`)}>{t(`widgets.header.communities`)}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          <label className={ `${styles.clickable}`} onClick={() => navigateToCommunity('community', params.id as string )  } >{ getCommunityName()}</label>
          <span className={`${Navigation__BreadCrumb}`}><IconWrapper iconName='chevron-right' /></span>
          { pathSegment && <Select
            className='site-navigation-select'
            id="site-navigation-select"
            open={open}
            onOpen={() => setOpen( true )}     
            onClose={() => setOpen( false )}     
            MenuProps={{
              className: 'site-navigation-select-menu'
            }}
            value={pathSegment || null}
            // defaultValue={pathSegment || null}
            IconComponent={() => <div onClick={() => triggerClick()}><IconWrapper className={'site-navigation-select-icon'} iconName='chevron-down' /></div>}
          >
            <option
              disabled={ pathSegment === 'community-energy' }
              onClick={() => {
                navigateToCommunity( 'community-energy', params.id as string )
            }} value="community-energy">Energy</option>
            <option
              disabled={ pathSegment === 'community-economics' }
              onClick={() => {
                navigateToCommunity( 'community-economics', params.id as string )
            }} value="community-economics">Economics</option>
          </Select>}

          
      {/* {
            breadCrumbs &&
            <BreadCrumbsDropDown />
          } */}
      
    </div>


      </div>

      <div className={Header__Web__Center}>
        <HiveCalendar />
        {/* <Calendar /> */}
        </div>

      <div className={Header__Web__Right}>
        {
          searchBar &&
          <Search />
        }
        {
        profile && <ProfileButton />
      }
      </div>
    </>

    
  );
}
export default React.memo(CommunityDataVisNavigation);
