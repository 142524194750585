
export function getSiteTypes(sites: Array<any>) {

  const hasProsumage = sites.includes('prosumage');
  const hasProsumer = sites.includes('prosumer');
  const hasConsumer = sites.includes('consumer');
  const hasProducer = sites.includes('producer');

  const comboKey = [
    hasProsumage ? '1' : '0',
    hasProsumer ? '1' : '0',
    hasConsumer ? '1' : '0',
    hasProducer ? '1' : '0',
  ].join('');

  const combinationMap = {
    '1111': 'default',
    '0000': 'default',
    '0010': 'consumer',
    '0001': 'producer',
    '0100': 'prosumer',
    '0111': 'prosumer',
    '0101': 'prosumer',
    '0110': 'prosumer',
    '0011': 'consumer_producer',
    '1000': 'prosumer_battery',
    '1010': 'prosumer_battery',
    '1001': 'prosumer_battery',
    '1100': 'prosumer_battery',
    '1011': 'prosumer_battery',
    '1101': 'prosumer_battery',
    '1110': 'prosumer_battery',
  };

  return combinationMap[comboKey] ?? 'default';

}

export function getSiteLayoutHelper(metadata: any, pathSegment: string, params: any, activeModule, MODULE) {

  let siteType =
    (metadata.selectedEntity as any)?.siteType?.[0]?.siteType ||
    (metadata.selectedEntity as any)?.type ||
    (metadata.selectedEntity as any)?.siteType ||
    null;



  if (pathSegment === 'member-energy') {

    let member = metadata.selectedEntity || params.id;
    if (typeof member === 'string' || !member) {
      const _members = [...(metadata?.sortedEntities?.communities.reduce(
        (prev: any, next: any) => ([...prev, ...(next?.members || [])]), []) || []),
      ...(metadata?.sortedEntities?.unassignedMembers || [])
      ];

      member = _members.find((member: any) => member.memberId === (params.id));
    }


    if ((member as any)?.sites?.length === 1) {
      siteType = (member as any)?.sites[0]?.siteType?.[0]?.siteType
        || (member as any)?.sites[0]?.siteType
        || (member as any)?.sites[0]?.type
        || null;


      siteType = `${siteType}_short`;

    } else {

      const sitesTypes = (member as any)?.sites?.map((site: any) => site.siteType?.[0]?.siteType || site.siteType || site.type || null) || [];

      siteType = getSiteTypes(sitesTypes);


    }

  }

  if (pathSegment === 'member-economics') {

    let member = params.id;
    if (typeof member === 'string') {
      const _members = [...(metadata?.sortedEntities?.communities.reduce(
        (prev: any, next: any) => ([...prev, ...(next?.members || [])]), []) || []),
      ...(metadata?.sortedEntities?.unassignedMembers || [])
      ];

      member = _members.find((member: any) => member.memberId === (params.id || metadata?.selectedEntity));
    }



    if ((member as any)?.sites?.length === 1) {
      siteType = 'default_short'
    } else {
      siteType = 'default'
    }
  }

  if (pathSegment === 'community-energy') {

    let community = metadata.selectedEntity || params.id;
    if (typeof community === 'string' || !community) {
      community = (metadata?.entities?.community || [])?.find((community: any) => community.communityId === (params.id));
    }

    const communitySites = metadata?.entities?.site?.filter((site: any) => site.communityId === (community?.communityId || params.id)) || [];

    if ((communitySites as any)?.length === 1) {
      siteType = communitySites[0]?.siteType?.[0]?.siteType
        || communitySites[0]?.siteType
        || communitySites[0]?.type
        || null;


      siteType = `${siteType}_short`;

    } else {

      const sitesTypes = communitySites?.map((site: any) => site.siteType?.[0]?.siteType || site.siteType || site.type || null) || [];

      siteType = getSiteTypes(sitesTypes);


    }

  }

  if (pathSegment === 'community-economics') {

    const community = params.id;
    const communitySites = metadata?.entities?.site?.filter((site: any) => site.communityId === community) || [];

    if (communitySites?.length === 1) {
      siteType = 'default_short'
    } else {
      siteType = 'default'
    }
  }

  if (siteType === null && pathSegment?.includes('site')) {
    siteType =
      (metadata?.selectedEntity as any)?.siteType?.[0]?.siteType ||
      (metadata?.selectedEntity as any)?.siteType ||
      (metadata?.selectedEntity as any)?.type ||
      (metadata?.entities as any)?.site?.find(
        (site: any) => site.siteId === params.id
      )?.siteType?.[0]?.siteType ||
      null;
  }

  if (
    siteType === null &&
    (pathSegment?.includes('site') ||
      pathSegment === 'household' || pathSegment === '') &&
    (metadata?.entities as any)?.site?.length > 0
  ) {
    siteType =
      (metadata?.entities as any)?.site?.[0]?.siteType?.[0]
        ?.siteType ||
      (metadata?.entities as any)?.site?.[0]?.type ||
      null;
  }

  // siteType = 'prosumer';
  siteType = siteType || 'consumer';
  return siteType;

}
