import { useContext, useEffect, useRef, useState } from 'react';
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@flexo/providers';
import { formatDate } from '../helpers';
import styles from './battery-chart.module.scss';
import { useSelector } from 'react-redux';
import { BatterySkeleton, Shimmer } from '@flexo/atoms';
import { get } from 'http';

function getGreyScaledColor(color: string) {
  let _color = color;

  if (color.includes('#')) {
    const red = parseInt(color.substr(1, 2), 16);
    const green = parseInt(color.substr(3, 2), 16);
    const blue = parseInt(color.substr(5, 2), 16);
    // Calculate the grayscale value
    const grayscale = Math.round(0.299 * red + 0.587 * green + 0.114 * blue);
    // Convert the grayscale value back to a hex string
    const grayscaleHex = grayscale.toString(16).padStart(2, '0');
    // Return the grayscale hex string with '#' prefix
    _color = '#' + grayscaleHex + grayscaleHex + grayscaleHex;
  }
  return _color;
}


function CombinedBatteryChartMobile(props: any) {
  const { t } = useTranslation();

  const kpi = useSelector((state: any) => state.kpi);
  const calendar = useSelector((state: any) => state.calendar);
  const { selectedTimeSet } = calendar.hiveCalendar;

  const { ThemeStore } = useContext(ThemeContext);

  const [unit, setUnit] = useState('');
  const [chartData, setChartData] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState<string | null>(null);
  const [activeDataSets, setActiveDataSets] = useState<any[]>([]);
  const [isNullStateActive, setNullStateActive] = useState<boolean>(false);
  const [innerTimeSet, setInnerTimeSet] = useState<string>(selectedTimeSet);

  const chartRef = useRef<any>(null);
  const xRef = useRef<any>(null);



  const rawData = kpi?.[props?.context]?.['1hour'] || [];
  let LOADING_STATE = kpi?.KpiDataSiteAsyncStatus;

  if (props?.context?.includes('member')) {
    LOADING_STATE = kpi?.KpiDataMemberAsyncStatus;
  }

  if (props?.context?.includes('community')) {
    LOADING_STATE = kpi?.KpiDataCommunityAsyncStatus;
  }

  const CustomizedDot = (_props) => {
    const { cx, cy, payload, index } = _props;

    return (
      <circle
        key={`dot-${payload.timestamp}`}
        cx={cx - 2}
        cy={cy}
        r={activeIndex !== null && parseInt(activeIndex) === index ? 8 : 5} // se stiamo hoverando, r=5, altrimenti 1
        fill={
          activeIndex !== null && parseInt(activeIndex) === index
            ? `${ThemeStore?.colors[props.colors[2].color]}${props.colors[1].colorShade
            }`
            : 'transparent'
        }
        stroke="none"
        onMouseLeave={() => setActiveIndex(null)}
        onTouchEnd={handleTouchEnd}
        onTouchEndCapture={handleTouchEnd}
        style={{ transition: 'r 0.2s ease' }}
      />
    );
  };

  // Handle mouse hover to set active bar
  const handleMouseMove = (index) => {
    setActiveIndex(`${index}`); // Only set active index if no bar is selected
  };

  const handleMouseLeave = () => {
    setActiveIndex(null); // Reset active index on mouse leave
  };

  // Click handler to set the selected bar
  const handleBarClick = (index) => {
    setActiveIndex(`${index}`); // Only update if a new bar is selected
  };

  function formatButtonValue(
    fieldName: string | null,
    buttonString: string,
    value: number | null
  ) {
    let formattedValue = `${buttonString}`;

    if (value === null && buttonString) {
      if (fieldName === 'soc_battery') {
        return `${buttonString} (%)`;
      }
      return formattedValue;
    }

    switch (fieldName) {
      case 'soc_battery':
        formattedValue = `${buttonString} | ${value} %`;
        break;
      default:
        formattedValue = `${buttonString} | ${value} ${unit}`;
        break;
    }

    return formattedValue;
  }

  function cleanArray(arr: any) {
    const uniqueSet = new Set();
    const result: any = [];

    for (let num of arr) {
      // Normalizza 0 e -0 a 0
      if (Object.is(num, -0)) {
        num = 0;
      }

      // Aggiungi solo se non è già stato visto
      if (!uniqueSet.has(num)) {
        uniqueSet.add(num);
        result.push(num);
      }
    }

    return result;
  }

  function getSymmetricTicks(data: any): number[] {
    // Estrai tutti i valori di battery_net_flow
    const values = data
      .map((d) => [
        d?.[props.db_fields?.[0]] || 0,
        d?.[props.db_fields?.[1]] || 0,
      ])
      .flat();

    const maxValue = Math.max(...values);
    const minValue = Math.min(...values);

    const maxAbs = Math.max(Math.abs(maxValue), Math.abs(minValue));

    const roundedMaxAbs = Number(maxAbs.toFixed(2));

    return cleanArray([
      roundedMaxAbs,
      Number((roundedMaxAbs / 2).toFixed(2)),
      0,
      Number((-roundedMaxAbs / 2).toFixed(2)),
      -roundedMaxAbs,
    ]);
  }

  function getFillColor(index: any, colorIndex: number): string {
    if (activeIndex === null) {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}${props.colors[colorIndex].colorShade
        }`;
    } else if (parseInt(activeIndex) === parseInt(index)) {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}${props.colors[colorIndex].colorShade
        }`;
    } else {
      return `${ThemeStore?.colors[props.colors[colorIndex].color]}60`;
    }
  }

  useEffect(() => {
    // Crea i dati per Recharts
    const _chartData = rawData.map((item: any) => {
      const formattedData: any = {
        timestamp: formatDate(item.timestamp, innerTimeSet),
      };

      props?.db_fields.forEach((field, index) => {
        const kpiValue = item?.kpi.find((datum: any) => datum.name === field);
        if (kpiValue) {
          formattedData[field] =
            field === 'self_consumption_battery'
              ? kpiValue.value * -1
              : kpiValue.value;
        }
      });

      return formattedData;
    });

    const nullCheck = JSON.parse(JSON.stringify(_chartData || []))
      .map((item) => {
        if (item['date']) {
          delete item['date'];
        }
        if (item['timestamp']) {
          delete item['timestamp'];
        }
        return Object(item);
      })
      .reduce(
        (acc, item) => [
          ...acc,
          ...Object.entries(item).map((entry) => entry[1]),
        ],
        []
      )
      .every((item) => item === null);

    setNullStateActive(nullCheck);

    setChartData(_chartData);

    if (props?.db_fields?.length > 0) {
      const firstField = props?.db_fields[0];
      const firstItem = rawData?.[0];
      if (firstItem) {
        const kpiValue = firstItem.kpi.find(
          (datum: any) => datum.name === firstField
        );
        setUnit(kpiValue?.unit || '');
      }

      if (innerTimeSet === 'day' && props?.context === 'site') {
        setActiveDataSets(
          Array.from({ length: props?.db_fields?.length }, (_) => true)
        );
      }
    }
  }, [kpi?.[props?.context]?.['1hour'], props.db_fields, innerTimeSet]);

  useEffect(() => {
    if (LOADING_STATE !== 'PENDING' && innerTimeSet !== selectedTimeSet) {
      setInnerTimeSet(selectedTimeSet);
    }
  }, [LOADING_STATE]);

  function toggleDataSets(index: number) {
    if (activeDataSets?.length < 2) {
      return;
    }

    if (
      activeDataSets.length === 3 &&
      activeDataSets.includes(false) &&
      activeDataSets.indexOf(true) === index
    ) {
      // setActiveDataSets([true, true]);
      return;
    }

    const newActiveDataSets = [...activeDataSets];
    newActiveDataSets[index] = !newActiveDataSets[index];
    setActiveDataSets(newActiveDataSets);
  }

  const getInterval = () => {
    switch (innerTimeSet) {
      case 'day':
        case 'month':
          return 6;
      case 'week':
        return 1;
      case 'year':
        return 2;
      default:
        return 0;
    }
  };

  const handleTouchMove = (e) => {

    e.preventDefault();
    e.stopPropagation()

    if (chartRef.current === null) { return };
    if (!e.touches || e.touches.length === 0) return;

    const xReference = document.getElementsByClassName('recharts-bar')[0];
    if (!xReference) return;
    const touchX = e.touches[0].clientX;                   // posizione X del tocco sullo schermo

    let { left, right } = (xReference as any).getBoundingClientRect() // larghezza del grafico
    left = 50;
    right = innerTimeSet === 'day' ? right : right - 60;
    const relativeX = touchX - left; // posizione X relativa all'interno del grafico
    // Determina l'indice del punto dati più vicino alla posizione toccata
    const index = Math.round((relativeX / right) * (chartData.length - 1));

    // Limita l'indice tra 0 e data.length - 1
    const clampedIndex: any = Math.max(0, Math.min(index, chartData.length - 1));
    setActiveIndex(clampedIndex);
  };

  const handleTouchEnd = (e) => {
    e.preventDefault(); // Previene comportamenti predefiniti
    e.stopPropagation();
    setTimeout( () => setActiveIndex(null), 100 )
  };

  return (
    <div
      id="CombinedBatteryChart__Wrapper"
      className={styles.CombinedBatteryChart__Wrapper}
      style={{ height: '70%', padding: '16px' }}

    >
      {LOADING_STATE === 'PENDING' && (
        <Shimmer />

      )}

      {(
        <>
          <div
            className={
              activeIndex
                ? styles.CombinedBatteryChart__Title__Active
                : styles.CombinedBatteryChart__Title
            }
          >
            {activeIndex && rawData?.[activeIndex]?.timestamp && (
              <div className={styles.CombinedBatteryChart__TimeStamp}>
                {formatDate(rawData?.[activeIndex]?.timestamp, innerTimeSet)}
              </div>
            )}

            {t(props.title)}
          </div>
          <div className={styles.CombinedBatteryChart__Buttons__Wrapper__Mobile}>
            {
              (LOADING_STATE === 'PENDING' && chartData.length === 0)
                ? <></>
                : props.buttons.map((button: any, index: number) => {
                  if (index > 1 && innerTimeSet !== 'day') {
                    return '';
                  }
  
                  return (
                    <div
                      key={`BatteryChart__${index}__button__${button}}`}
                      className={styles.CombinedBatteryChart__Button}
                      onClick={() => toggleDataSets(index)}
                      style={{
                        opacity:
                          activeDataSets[index] || activeDataSets?.length === 0
                            ? 1
                            : 0.5,
                        cursor:
                          activeDataSets?.length < 3 ? 'default' : 'pointer',
                      }}
                    >
                      {formatButtonValue(
                        props?.db_fields?.[index],
                        t(button),
                        chartData?.[activeIndex as any]?.[
                        props.db_fields?.[index]
                        ] || null
                      )}
  
                      <div
                        className={styles.CombinedBatteryChart__Button__Underline}
                        style={{
                          backgroundColor:
                          activeDataSets[index] || activeDataSets?.length === 0
                          ? `${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}`
                          : getGreyScaledColor(`${ThemeStore?.colors[props.colors[index].color]}${props.colors[index].colorShade}`),
                            
                        }}
                      ></div>
                    </div>
                  );
              })
            }
            
          </div>

          {isNullStateActive
            ? <BatterySkeleton isNull={isNullStateActive} />
            : (
              <div className={styles.CombinedBatteryChart__Chart__Wrapper}

                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onTouchEndCapture={handleTouchEnd}
              >
                <div
                  className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Left}`}
                >
                  {unit}
                </div>
                <ResponsiveContainer width="100%" height={250} ref={chartRef} >
                  <ComposedChart
                    data={chartData}
                    margin={{ top: 20, right: ( innerTimeSet === 'day' ? -30 : 20 ), left: -25, bottom: 0 }}
                
                    stackOffset="sign"
                    width={300}
                    id={"combinedBatteryChart"}

                  >
                    {/* <CartesianGrid stroke="#f5f5f5" vertical={false} /> */}
                    <XAxis

                      onTouchEnd={handleTouchEnd}
                      onTouchEndCapture={handleTouchEnd}
                      id="XAxisLine"
                      dataKey="timestamp"
                      tick={{
                        fontSize: 12,
                        fontFamily: 'Flexo',
                        color: ThemeStore?.colors?.gridColor,
                        opacity: 0.8,
                      }}
                      ticks={chartData.map((_, index) =>
                        formatDate(rawData?.[index]?.timestamp, innerTimeSet)
                      )}
                      tickFormatter={(tick) => tick}
                      interval={getInterval()}
                      axisLine={false}
                      tickLine={false}
                      ref={xRef}
                    />
                    <CartesianGrid
                      strokeDasharray="0"
                      vertical={false}
                      horizontalValues={[0]}
                      stroke={ThemeStore?.colors?.gridColor}
                      strokeWidth={0.5}
                      onTouchEnd={handleTouchEnd}
                      onTouchEndCapture={handleTouchEnd}
                    />
                    <YAxis

                      onTouchEnd={handleTouchEnd}
                      onTouchEndCapture={handleTouchEnd}
                      yAxisId="left"
                      orientation="left"
                      tick={{
                        fontSize: 12,
                        fontFamily: 'Flexo',
                        color: ThemeStore?.colors?.gridColor,
                        opacity: 0.8,
                      }}
                      axisLine={true}
                      tickLine={false}
                      // domain={['auto', 'auto']}
                      // domain={getOppositeValues()}
                      tickFormatter={(tick) => (tick).toFixed(1)}

                      ticks={getSymmetricTicks(chartData)}
                      tickMargin={0}
                      strokeWidth={0.1}
                    />

                    {innerTimeSet === 'day' && props?.context === 'site' && (
                      <YAxis

                        onTouchEnd={handleTouchEnd}
                        onTouchEndCapture={handleTouchEnd}
                        yAxisId="right"
                        orientation="right"
                        tick={{
                          fontSize: 12,
                          fontFamily: 'Flexo',
                          color: ThemeStore?.colors?.gridColor,
                          opacity: 0.8,
                        }}
                        domain={[0, 100]}
                        axisLine={true}
                        tickLine={false}
                        strokeWidth={0.1}
                      />
                    )}
                    {/* <Tooltip /> */}
                    <Bar
                      stackId={'battery'}
                      yAxisId="left"
                      dataKey={props.db_fields[0]}
                      // fill={getFillColor()}

                      onTouchEnd={handleTouchEnd}
                      onTouchEndCapture={handleTouchEnd}
                      hide={
                        activeDataSets.length === 0
                          ? false
                          : !activeDataSets?.[0] || false
                      }
                      radius={[4, 4, 4, 4]}
                      strokeWidth={3}
                    >
                      {chartData.map((entry, index) => (
                        <Cell

                          onTouchEnd={handleTouchEnd}
                          onTouchEndCapture={handleTouchEnd}
                          key={`cell-${entry.timestamp}`}
                          fill={getFillColor(index, 0)}
                        // width={3}
                        />
                      ))}
                    </Bar>
                    <Bar

                      onTouchEnd={handleTouchEnd}

        onTouchEndCapture={handleTouchEnd}
                      stackId={'battery'}
                      yAxisId="left"
                      dataKey={props.db_fields[1]}
                      strokeWidth={3}
                      // fill={getFillColor()}

                      hide={
                        activeDataSets.length === 0
                          ? false
                          : !activeDataSets?.[1] || false
                      }
                      radius={[4, 4, 4, 4]}
                    >
                      {chartData.map((entry, index) => (
                        <Cell
                          key={`cell-${entry.timestamp}-negative`}
                          fill={getFillColor(index, 1)}
                        // width={5}
                        />
                      ))}
                    </Bar>

                    {innerTimeSet === 'day' && props?.context === 'site' && (
                      <Line

                        onTouchEnd={handleTouchEnd}
                        onTouchEndCapture={handleTouchEnd}
                        
                        connectNulls
                        hide={!activeDataSets[2]}
                        yAxisId="right"
                        type="monotone"
                        dataKey={props.db_fields[2]}
                        stroke={`${ThemeStore?.colors[props.colors[2].color]}${props.colors[2].colorShade
                          }`}
                        strokeWidth={3}
                        dot={CustomizedDot}
                        strokeLinecap='round'
                      />
                    )}
                  </ComposedChart>
                </ResponsiveContainer>
                <div
                  className={`${styles.CombinedBatteryChart__Chart__Unit} ${styles.CombinedBatteryChart__Chart__Unit__Right}`}
                >
                  {innerTimeSet === 'day' && props?.context === 'site'
                    ? '%'
                    : ''}
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default CombinedBatteryChartMobile;
