import React, { useContext, useEffect, useRef, useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';

import {
  ThemeTypes,
  ThemeContext,
  AlertContext,
  AlertTypes,
} from '@flexo/providers';

import { IonContent, RefresherEventDetail } from '@ionic/react';

import styles from './modules.module.scss';
import { Factory } from '../factory';
import { useSelector } from 'react-redux';
import { getMetaResolver, getResolver } from './resolvers.registry';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert, setNavigate, Inspector } from '@flexo/atoms';
import { getSiteLayoutHelper } from './helpers';
import { moduleFilter } from '@flexo/reducers';
import { auth } from '@flexo/general';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const ENV = (import.meta as any).env;

export const Modules = (props: any) => {
  const navigate = useNavigate();
  setNavigate(navigate);
  const params = useParams();

  const user = useSelector((state: any) => state.user);
  const metadata = useSelector((state: any) => state.metadata);

  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];
  const kpi = useSelector((state: any) => state.kpi);

  const { setThemeStore, ThemeStore } = useContext(ThemeContext);

  const { setAlertStore, alertStore } = useContext(AlertContext);
  const [activeModule, setActiveModule] = useState<any | null>(null);
  const [layouts, setLayouts] = useState<any | null>(null);
  const [widgets, setWidgets] = useState<any | null>(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [ shownInspector, setShownInspector ] = useState<any>(null);
  const [ devModeActive, setDevModeActive ] = useState<any>(false);

  const [isBlurred, setIsBlurred] = useState(false);

  const scrollRef = useRef<HTMLIonContentElement>(null);

  function scrollToTop() {
    if (ENV.VITE_APP_IS_WEB === 'true') {
      scrollRef.current?.scrollToTop(500);
    }
  }

  useEffect(() => {
    scrollToTop();
  }, [ location.pathname ])

  function updateBreakPoints(breakpoint: string) {
    if (breakpoint !== currentBreakpoint) {
      setCurrentBreakpoint(breakpoint);
      setThemeStore({
        type: ThemeTypes.SetBreakPoint,
        payload: {
          value: breakpoint,
        },
      });

      if (ENV.VITE_APP_DISABLE_SCREEN_SIZE_ALERT === 'true') {
        return;
      }

      if (['xl', 'lg', 'md'].includes(breakpoint)) {
        if (!isBlurred) return;

        setIsBlurred(false);

        if (alertStore.alert.type === 'ALERT_SCREEN_SIZE') {
          setAlertStore({
            type: AlertTypes.ResetAlert,
            payload: null as any,
          });
        }
      } else {
        if (isBlurred) return;

        setIsBlurred(true);

        setAlertStore({
          type: AlertTypes.SetAlert,
          payload: {
            value: {
              type: 'ALERT_SCREEN_SIZE',
            },
          },
        });
      }
    }
  }

  function getFilteredLayouts(activeModule: any) {

    let authContent: any = null;

    try {
      authContent = jwt_decode((auth as any)?.currentUser?.accessToken)
    } catch ( err: any ) {
      console.error(err);
    }

    const siteType: any = getSiteLayoutHelper(metadata, pathSegment, params, activeModule, props.MODULE) || 'consumer';
    const role = authContent?.user_role || user?.tokenContent?.user_role || 'default';
    // const _widgets = activeModule?.widgets?.[role]?.[siteType] || activeModule?.widgets?.[role] || activeModule?.widgets?.default;
    // const _layouts = activeModule?.layouts?.[role]?.[siteType] || activeModule?.layouts?.[role] || activeModule?.layouts?.default;

    const _module = moduleFilter([activeModule], role, siteType, null);

    // console.log('_module -> ', _module);

    const _layouts = _module?.[0]?.layouts;
    const _widgets = _module?.[0]?.widgets;


    const widgetIds = _widgets.map((widget) => widget.id);
    return {
      ...activeModule.layouts[role],
      [`${currentBreakpoint || 'lg'}`]: (
        _layouts?.[currentBreakpoint || 'lg'] ||
        _layouts?.lg ||
        []
      ).filter((layout) => widgetIds.includes(layout.i)),
    };
  }

  function getFilteredWidgets(activeModule) {

    let authContent: any = null;

    try {
      authContent = jwt_decode((auth as any)?.currentUser?.accessToken)
    } catch ( err: any ) {
      console.error(err);
    }

    const siteType: any = getSiteLayoutHelper(metadata, pathSegment, params, activeModule, props.MODULE) || 'consumer';
    const role = authContent?.user_role || user?.tokenContent?.user_role || 'default';
    // const _widgets = activeModule?.widgets?.[role]?.[siteType] || activeModule?.widgets?.[role] || activeModule.widgets?.default;
    // const _layouts = activeModule?.layouts?.[role]?.[siteType] || activeModule?.layouts?.[role] || activeModule?.layouts.default;

    const _module = moduleFilter([activeModule], role, siteType, null);
    const _layouts = _module[0]?.layouts;
    const _widgets = _module[0]?.widgets;


    const layoutIds = (
      _layouts?.[currentBreakpoint || 'lg'] ||
      _layouts?.lg ||
      []
    ).map((layout) => layout.i);
    return _widgets.filter((widget) => layoutIds.includes(widget.id));
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      // TODO: Z - There is basically almost never a good reason to have a timeout, it spawns a new thread and is not reliable

      // This is just to test the pull to refresh functionality, if it closes after 2 seconds or not.
      // Not the actual code XD

      // Any calls to load data go here

      //create function that check metadataapi store and calendar sotre and calls http service

      event.detail.complete();
    }, 2000);
  }

  function showInspector(widget: any) {

    if (ENV.VITE_APP_ALLOW_INSPECTION !== 'true') { return; }
    if ( devModeActive === false ) { return; }

    const _widget: any = {
      ...widget
    }

    delete _widget.layouts;
    delete _widget.setActiveModule;
    delete _widget.showInspector;

    setShownInspector(_widget);

  }

  const Resolvers = React.useMemo(() => {
    if (activeModule === null) return null;

    return activeModule?.apis?.default?.map((resolver: any) => {
      const Resolver = getResolver(resolver);
      return Resolver;
    });
  }, [activeModule]);

  const MetaResolvers = React.useMemo(() => {
    if (activeModule === null) return null;
    return activeModule?.metaResolvers?.default?.map((resolver: any) => {
      const MetaResolver = getMetaResolver(resolver);
      return MetaResolver;
    });
  }, [activeModule]);

  // setting colors
  useEffect(() => {
    if (Object.keys(ThemeStore?.colors)?.length > 0) return;
    setThemeStore({
      type: ThemeTypes.SetColors,
      payload: {
        value: props.COLORS,
      },
    });
  }, []);

  useEffect(() => {
    if (props.MODULE !== JSON.stringify(activeModule)) {
      const _module = JSON.parse(props.MODULE);
      setActiveModule(_module)
    }

  }, [props.MODULE]);
  

  useEffect(() => {
    if (activeModule === null) return;
    const _layouts = getFilteredLayouts(activeModule);
    const _widgets = getFilteredWidgets(activeModule);
    setWidgets(_widgets);
      setLayouts(_layouts);
  }, [activeModule, metadata])

  const getScroll = () => {
    if (ENV.VITE_APP_NAME === 'admin') return true;
    if (
      pathSegment === 'household' ||
      pathSegment === 'community' ||
      (pathSegment === '' && ENV.VITE_APP_NAME !== 'admin')
    )
      return true;
    return false;
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     alert('should scroll');
  //   document.body.scrollTo(0, 0);
  //   }, 2000 )
    
  // }, [ location.pathname])

  return (
    <>
      {
        ENV.VITE_APP_ALLOW_INSPECTION === 'true' && <Inspector { ...{
          setShownInspector,
          shownInspector,
          devModeActive,
          setDevModeActive,
        }} />
      }
      
      {activeModule && (
        <>
          {Resolvers?.map((Resolver: any) => (
            <Resolver key={Resolver} />
          ))}
          {MetaResolvers?.map((MetaResolver: any) => (
            <MetaResolver key={MetaResolver} />
          ))}
        </>
      )}

      { (activeModule && layouts && widgets)  ? (
        <IonContent
          className={`IonScroll ${isBlurred ? 'APP_Blurred' : ''}`}
          scrollY={getScroll()}
          ref={scrollRef}
        >
          <ResponsiveReactGridLayout
            className={`${styles.Modules} ${activeModule.name}`}
            key={`grit_item_${activeModule.name}`}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            breakpoints={{
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 0,
            }}
            containerPadding={
              ENV.VITE_APP_NAME === 'admin' ? [0, 16] : [0, 150]
            }
            style={
              ENV.VITE_APP_NAME === 'admin'
                ? { paddingBottom: 32, marginTop: 16 }
                : { marginTop: '-150px' }
            }
            margin={[0, 0]}
            rowHeight={8}
            compactType={'vertical'}
            preventCollision={true}
            autoSize={true}
            layouts={layouts}
            onBreakpointChange={(breakpoint, cols) =>
              updateBreakPoints(breakpoint)
            }
            // onLayoutChange={(layout, layouts) => { console.log('layout changed', layout) }}
          >
            {widgets.length > 0 &&
              widgets.map((widget: any, widget_i: number) => {
                const additionalStyles =
                  (layouts?.[currentBreakpoint] || []).find(
                    (item: any) => item?.i === widget?.id
                  )?.style || {};

                return Factory(
                  `${widget.id}_${uuidv4()}`,
                  'lg',
                  {
                    ...widget,
                    layouts: layouts,
                    setActiveModule,
                    showInspector,
                    setStoredLayouts: setLayouts
                  } as any,
                  additionalStyles
                );
              })}
          </ResponsiveReactGridLayout>
          {alertStore.alert.visible && <Alert />}
        </IonContent>
      ) : null}
    </>
  );
};

export default React.memo(Modules);
