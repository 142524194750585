import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { Autocomplete, Icon, ListItem, Popper, TextField } from '@mui/material';
import { IconWrapper } from '@flexo/general';

import { navigateToSite } from '../resolvers/site-overview-resolver';
import { navigateToCommunity } from '../resolvers/community-resolver';
import { navigateToMember } from '../resolvers/member-resolver';
import styles from './breadcrumbs-dropdown.module.scss';
import { KPI } from '@flexo/reducers';
import { Keyboard } from '@capacitor/keyboard';

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context: any = canvas.getContext('2d');
  context.font = font; // Specifica il font esatto (es. "16px Arial")
  return context.measureText(text).width;
}

function unFocusChild(childID: string) {
  try {
    const child = document.getElementById(childID);
    (child as any).blur();
  } catch (err: any) {
    console.warn(err);
  }
}

export function CommunitiesDropDown() {
  const { t } = useTranslation();
  const params = useParams();
  const metadata = useSelector((state: any) => state.metadata);

  let communities = (metadata?.entities as any)?.community || [];

  try {
    communities = (JSON.parse(JSON.stringify(communities)) || [])?.sort(
      (a, b) => (a?.communityName || '')?.localeCompare(b?.communityName || '')
    );
  } catch (err: any) {
    console.warn(err);
  }

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedCommunity, setSelectedCommunity] = useState<any>(null);

  useEffect(() => {
    setTimeout(() => {
      setSelectedCommunity(metadata?.selectedEntity);
    }, 300);
  }, [metadata?.selectedEntity]);

  return selectedCommunity ? (
    <Autocomplete
      disablePortal
      id="dropdown-communities"
      clearOnBlur={true}
      className={`${styles.BreadCrumb}`}
      options={communities || []}
      getOptionLabel={(option: any) => option.communityName}
      onClose={() => setSearchTerm('')}
      noOptionsText={
        <span className={`${styles.Autocomplete__List__Item}`}>
          {t('widgets.search.no_results')}
        </span>
      }
      popupIcon={
        <IconWrapper
          iconName={'chevron-right'}
          className={`${styles.BreadCrumb__Icon}`}
        />
      }
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          key={uuidv4()}
          className={`${
            option?.communityId === params.id
              ? styles.Autocomplete__List__Item__Active
              : styles.Autocomplete__List__Item
          }`}
          value={option.value}
        >
          <span
            style={{
              paddingLeft: 24,
              cursor: params.id !== option.communityId ? 'pointer' : 'default',
            }}
            onClick={(ev: any) => {
              return params?.id !== option.communityId
                ? (() => (
                    navigateToCommunity(`community`, option.communityId),
                    unFocusChild('dropdown-communities')
                  ))()
                : null;
            }}
          >
            {option?.communityName}
            <br />
            <small> {option?.communityExternalId} </small>
          </span>
        </ListItem>
      )}
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
            }}
            placement="left"
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => {
        return selectedCommunity ? (
          <TextField
            {...params}
            variant="standard"
            style={{
              width:
                getTextWidth(
                  searchTerm ||
                    (metadata?.selectedEntity as any)?.communityName ||
                    0,
                  '19px Flexo'
                ) +
                ((
                  searchTerm || (metadata?.selectedEntity as any)?.communityName
                )?.length > 15
                  ? 10
                  : 20),
            }}
            className={styles.BreadCrumb__Input}
            onChange={(ev: any) => setSearchTerm(ev.target.value)}
            placeholder={
              (metadata?.selectedEntity as any)?.communityName || '' //(t('dropdowns.communities') as string)
            }
            label={
              (metadata?.selectedEntity as any)?.communityName || '' //(t('dropdowns.communities') as string)
            }
          ></TextField>
        ) : (
          <></>
        );
      }}
    />
  ) : (
    <></>
  );
}

export function MembersDropDown() {
  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);
  const params = useParams();

  const _membersList = metadata?.members || [];
  const [membersList, setMembersList] = useState<any>(_membersList);

  const [searchTerm, setSearchTerm] = useState<string>('');

  function getMemberName(datum: any) {
    if (!datum) {
      datum = metadata?.entities?.member?.find(
        (member: any) => member?.memberId === params.id
      );
    }
    return `${datum?.memberFirstName} ${datum?.memberLastName}` || null;
  }

  const memberCommunityID = metadata?.entities?.member?.find(
    (member: any) => member?.memberId === params.id
  )?.communityId;

  let parsedList =
    metadata?.entities?.member?.filter(
      (member: any) => member?.communityId === memberCommunityID
    ) || [];

  try {
    parsedList = (JSON.parse(JSON.stringify(parsedList)) || [])?.sort((a, b) =>
      (`${a?.memberFirstName} ${a?.memberLastName}` || '')?.localeCompare(
        `${b?.memberFirstName} ${b?.memberLastName}` || ''
      )
    );
  } catch (err: any) {
    console.warn(err);
  }

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-members"
      clearOnBlur={true}
      className={`${styles.BreadCrumb}`}
      options={parsedList || []}
      // groupBy={(option: any) => option.timeSpan}
      onClose={() => setSearchTerm('')}
      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          width: 'auto',
          cursor: 'pointer',
          // minWidth: '200px', // Imposta una larghezza minima se necessario
        },
      }}
      getOptionLabel={(option: any) => getMemberName(option) as string}
      popupIcon={
        <IconWrapper
          iconName={'chevron-right'}
          className={`${styles.BreadCrumb__Icon}`}
        />
      }
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            id="breadcrumbs-popper"
            style={{
              width: 300,
              alignSelf: 'flex-start',
              borderRadius: 8,
            }}
            placement="left"
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) => {
        const memberName = getMemberName(null);

        return (
          <TextField
            {...params}
            variant="standard"
            onChange={(ev: any) => setSearchTerm(ev.target.value)}
            style={{
              width:
                getTextWidth(searchTerm || memberName, '20px Flexo bold') + 5,
              minWidth: 100,
              // maxWidth: 300,
            }}
            className={styles.BreadCrumb__Input}
            label={memberName || (t('widgets.dropdowns.members') as string)}
            placeholder={
              memberName || (t('widgets.dropdowns.members') as string)
            }
          ></TextField>
        );
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItem
            {...props}
            className={`${
              option?.memberId === params.id
                ? styles.Autocomplete__List__Item__Active
                : styles.Autocomplete__List__Item
            }`}
            value={option.value}
          >
            <span
              style={{ paddingLeft: 24, cursor: 'pointer' }}
              onClick={(ev: any) => (
                navigateToMember('member', option.memberId),
                unFocusChild('autocomplete-members')
              )}
            >
              {getMemberName(option)}
              <br />
              <small> {option?.memberExternalId} </small>
            </span>
          </ListItem>
        );
      }}
    />
  );
}

export function SitesDropDown() {
  const { t } = useTranslation();

  const params = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];
  const metadata = useSelector((state: any) => state.metadata);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const membersList = [
    ...((metadata?.sortedEntities as any)?.communities || []).reduce(
      (prev: any, next: any) => [...prev, ...(next?.members || [])],
      []
    ),
    ...((metadata as any)?.unassignedMembers || []),
  ]?.filter((member: any) =>
    member?.sites?.some((site: any) => site?.siteId === params.id)
  );

  const sitesList = membersList?.map((member: any) => member?.sites).flat();

  return (
    <Autocomplete
      disablePortal
      id="autocomplete-sites"
      clearOnBlur={true}
      className={`${styles.BreadCrumb}`}
      options={sitesList || []}
      // groupBy={(option: any) => option.timeSpan}
      sx={{
        width: 'auto',
        '& .MuiAutocomplete-input': {
          width: 'auto',
          minWidth: '300px', // Imposta una larghezza minima se necessario
          cursor: 'pointer',
        },
      }}
      disabled={sitesList.length <= 1}
      getOptionLabel={(option: any) => option.pod}
      popupIcon={
        sitesList.length <= 1 ? null : (
          <IconWrapper
            iconName={'chevron-right'}
            className={`${styles.BreadCrumb__Icon}`}
          />
        )
      }
      PopperComponent={({ children, ...props }) => {
        return (
          <Popper
            {...props}
            id="breadcrumbs-popper"
            style={{
              width: 300,
              borderRadius: 8,
            }}
            placement="left"
          >
            {children}
          </Popper>
        );
      }}
      renderInput={(params) =>
        (metadata?.selectedEntity as any)?.pod ? (
          <TextField
            {...params}
            variant="standard"
            onChange={(ev: any) => setSearchTerm(ev.target.value)}
            style={{
              width:
                (searchTerm?.length ||
                  (
                    (metadata?.selectedEntity as any)?.name ||
                    (metadata?.selectedEntity as any)?.siteName
                  )?.length) *
                  10 +
                  10 || 0,
              minWidth: 150,
              maxWidth: 300,
            }}
            className={styles.BreadCrumb__Input}
            label={
              (metadata?.selectedEntity as any)?.pod ||
              (t('widgets.dropdown.sites') as string)
            }
            placeholder={
              (metadata?.selectedEntity as any)?.pod ||
              (t('widgets.dropdown.sites') as string)
            }
          ></TextField>
        ) : (
          <></>
        )
      }
      renderOption={(props, option, { selected }) => (
        <ListItem
          {...props}
          className={`${
            option?.siteId === params.id
              ? styles.Autocomplete__List__Item__Active
              : styles.Autocomplete__List__Item
          }`}
          value={option.value}
        >
          <span
            style={{ paddingLeft: 24, cursor: 'pointer' }}
            onClick={(ev: any) => (
              navigateToSite(pathSegment, option.siteId),
              unFocusChild('autocomplete-sites')
            )}
          >
            {option?.pod}
            <br />
            <small> {option?.siteName} </small>
          </span>
        </ListItem>
      )}
    />
  );
}

export function SitesDropDownMobile() {
  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  const params = useParams();
  const siteID =
    params?.id ||
    metadata?.selectedEntity?.siteId ||
    metadata?.entities?.site?.[0]?.siteId ||
    null;

  let site: any = null;

  if (!siteID) {
    site = metadata?.entities?.site?.[0];
  } else {
    site = metadata?.entities?.site?.find(
      (_site: any) => _site.siteId === siteID
    );
  }

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<any>(
    (metadata as any)?.entities?.site?.find(
      (entity) =>
        entity.siteId === (params?.id || metadata?.selectedEntity?.siteId)
    ) ||
      metadata?.entities?.site?.[0] ||
      null
  );

  const [textWidth, setTextWidth] = useState(0);
  const canvasRef: any = useRef(null);

  useEffect(() => {
    if (canvasRef.current && selectedOption?.siteName) {
      const context = canvasRef.current.getContext('2d');

      // Match input font styles
      context.font = '24px Flexo bold'; // Adjust as per your input field font
      const fullTextWidth = context.measureText(
        searchTerm ? searchTerm : selectedOption.siteName
      ).width;

      setTextWidth(fullTextWidth + 10);
    }
  }, [selectedOption?.siteName, searchTerm]);

  const inputRef = useRef<HTMLInputElement>(null);

  const mobileSitesList = (metadata as any)?.entities?.site || [];
  const filteredSitesList = mobileSitesList.filter((site: any) =>
    site.siteName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option: any) => {
    setSearchTerm(''); // Update the input value
    setInputFocused(false);

    if (inputRef.current) {
      inputRef.current.blur(); // Remove focus from input
    }

    if (option.siteId === selectedOption.siteId) {
      return;
    } else {
      navigateToSite('household', option.siteId);
      setSelectedOption(option); // Update the selected option
      dispatch(KPI.ResetKpiApiStore());
      dispatch(metadata.SetPrevSelectedEntityId(null as any));
    }
  };

  useEffect(() => {
    if (!selectedOption?.siteId && metadata?.selectedEntity?.siteId) {
      setSelectedOption(metadata.selectedEntity);
    }
  }, [selectedOption?.siteId, metadata?.selectedEntity?.siteId]);

  useEffect(() => {
    Keyboard.setResizeMode({ mode: 'none' as any }); // Prevents shrinking
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1.5 }}>
        <Autocomplete
          id="autocomplete-sites-mobile"
          clearOnBlur={false}
          className={`${styles.BreadCrumb}`}
          options={filteredSitesList || []}
          sx={{
            width: 'auto',
            '& .MuiAutocomplete-input': {
              minWidth: '300px',
              width: '100%',
            },
          }}
          onBlur={() => setSearchTerm('')}
          value={selectedOption}
          onChange={(event, newValue) => {
            setSelectedOption(newValue ?? selectedOption);
            // If you also want to track typed text as soon as an option is chosen:
            setSearchTerm(newValue?.siteName || '');
          }}
          // This is the TEXT the user is typing
          inputValue={searchTerm}
          onInputChange={(event, newInput) => {
            setSearchTerm(newInput);
          }}
          disabled={mobileSitesList.length <= 1}
          getOptionLabel={(option: any) => {
            return option.siteName;
          }}
          PopperComponent={({ children, ...props }) => {
            return (
              <Popper
                {...props}
                placement="top-start"
                id="breadcrumbs-popper-mobile"
                style={{
                  width: 300,
                  alignSelf: 'flex-start',
                  borderRadius: 16,
                  paddingTop: -50,
                  marginTop: 50,
                  maxHeight: 400, // Increase this to display more options
                  overflowY: 'visible', // Enables scrolling if needed
                  boxShadow: '0px 0px 8px 0px rgba(80, 85, 88, 0.22)',
                }}
              >
                {children}
              </Popper>
            );
          }}
          renderInput={(params) => (
            <>
              {' '}
              <TextField
                {...params}
                variant="standard"
                inputRef={inputRef}
                onChange={(ev) => setSearchTerm(ev.target.value)}
                placeholder={selectedOption?.siteName || ''}
                className={`heading1 $ ${
                  mobileSitesList.length <= 1 &&
                  styles.BreadCrumb__Input__SingleValue
                } `}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  style: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '20px', // Reserve space for the icon
                    fontWeight: 'bold',
                    color: '#009E62',
                    maxWidth: mobileSitesList.length <= 1 ? '300px' : '250px',
                  },
                  endAdornment: mobileSitesList.length > 1 && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 0, // Always keep the icon at the end
                        pointerEvents: 'none',
                        display: mobileSitesList.length < 1 ? 'none' : 'flex',
                        alignItems: 'center',
                        // transition: 'all 0.2s ease-in-out',
                        left: inputFocused ? 'auto' : textWidth,
                      }}
                    >
                      <button
                        style={{ border: 'none', background: 'none' }}
                        onClick={() => {
                          if (inputFocused) {
                            setSearchTerm('');
                            setInputFocused(false);
                          } else return;
                        }}
                      >
                        {!inputFocused && <IconWrapper
                          iconName={inputFocused ? '' : 'chevron-down'}
                          style={{
                            fontSize: '16px',
                            color: 'green',
                            marginLeft: inputFocused ? 'auto' : '0', // Prevent shifting
                          }}
                          className={styles.BreadCrumb__Icon__Mobile}
                        />}
                      </button>
                    </div>
                  ),
                }}
                sx={{
                  width: 'fit-content', // Dynamically adjusts to content length
                  minWidth: '150px',
                  position: 'relative',
                }}
                onFocus={() => (setInputFocused(true), setSearchTerm(''))}
                onBlur={() => setInputFocused(false)}
                focused={inputFocused}
              />
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </>
          )}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              className={`heading2 ${
                option?.pod === (metadata?.selectedEntity as any)?.pod
                  ? styles.Autocomplete__List__Item__Active__Mobile
                  : styles.Autocomplete__List__Item__Mobile
              }`}
              style={{
                color:
                  option?.pod === (metadata?.selectedEntity as any)?.pod ||
                  option?.siteId === params.id
                    ? '#009E62'
                    : undefined,
                textTransform: 'capitalize',
              }}
              value={option.siteName}
              onClick={() => handleOptionClick(option)} // Call handleOptionClick
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{option?.siteName}</span>
                <span className="detail" style={{ color: '#687078' }}>
                  {option?.pod}
                </span>
              </div>
            </ListItem>
          )}
        />
      </div>
      {inputFocused && (
        <button
          style={{
            border: 'none',
            background: 'none',
            justifyContent: 'flex-end',
            justifySelf: 'flex-end',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconWrapper
            iconName="cross"
            style={{
              fontSize: '16px',
              color: 'green',
              marginLeft: 'auto',
              marginTop: '8px',
            }}
            className={styles.BreadCrumb__Icon__Mobile}
            onClick={() => {
              setSearchTerm('');
              setInputFocused(false);
            }}
          />
        </button>
      )}
    </div>
  );
}

export function CommunityDropdownMobile() {
  const { t } = useTranslation();
  const metadata = useSelector((state: any) => state.metadata);
  const params = useParams();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [inputFocused, setInputFocused] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState<any>(
    (metadata as any)?.entities?.community?.find((entity) => {
      return entity?.communityId === params.id;
    }) ||
      metadata?.entities?.community?.[0] ||
      null
  );
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const [textWidth, setTextWidth] = useState(0);
  const canvasRef: any = useRef(null);

  useEffect(() => {
    if (canvasRef.current && selectedOption?.communityName) {
      const context = canvasRef.current.getContext('2d');

      // Match input font styles
      context.font = '24px Flexo bold'; // Adjust as per your input field font
      const fullTextWidth = context.measureText(
        searchTerm ? searchTerm : selectedOption.communityName
      ).width;

      setTextWidth(fullTextWidth > 220 ? 230 : fullTextWidth);
    }
  }, [selectedOption?.communityName, searchTerm]);

  const mobileCommunityList = (metadata as any)?.entities?.community || [];
  const filteredSitesList = mobileCommunityList.filter((community: any) =>
    community.communityName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //.filter.count
  const handleOptionClick = (option: any) => {
    setSearchTerm(''); // Update the input value
    setInputFocused(false);
    if (inputRef.current) {
      inputRef.current.blur(); // Remove focus from input
    }

    if (option.communityId === selectedOption.communityId) return;
    else {
      navigateToCommunity('community', option.communityId);
      setSelectedOption(option); // Update the selected option
      dispatch(KPI.ResetKpiApiStore());
      dispatch(metadata.SetPrevSelectedEntityId(null as any));
    }
  };

  const getSitesAumount = (communityId) => {
    return (metadata as any)?.entities.sites.filter(
      (site) => site.communityId === communityId
    ).length;
  };

  useEffect(() => {
    if (
      !selectedOption?.communityId &&
      (metadata as any)?.selectedEntity?.communityId
    ) {
      setSelectedOption(metadata?.selectedEntity);
    }
  }, [selectedOption, metadata?.selectedEntity?.communityId, metadata]);
  useEffect(() => {
    Keyboard.setResizeMode({ mode: 'none' as any }); // Prevents shrinking
  }, []);

  console.log('selectedOption community', selectedOption);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1.5 }}>
        <Autocomplete
          disablePortal
          id="autocomplete-sites-mobile"
          clearOnBlur={false}
          className={`${styles.BreadCrumb}`}
          options={filteredSitesList || []}
          sx={{
            width: 'auto',
            '& .MuiAutocomplete-input': {
              minWidth: '300px',
              width: '100%',
            },
          }}
          onBlur={() => setSearchTerm('')}
          // value={selectedOption} // Bind to selected option
          // onChange={(event, newValue) => {
          //   setSelectedOption(newValue ?? selectedOption);
          //   setSearchTerm(newValue?.siteName || selectedOption?.siteName || '');
          // }}
          value={selectedOption}
          onChange={(event, newValue) => {
            setSelectedOption(newValue ?? selectedOption);
            // If you also want to track typed text as soon as an option is chosen:
            setSearchTerm(newValue?.siteName || '');
          }}
          // This is the TEXT the user is typing
          inputValue={searchTerm}
          onInputChange={(event, newInput) => {
            setSearchTerm(newInput);
          }}
          disabled={mobileCommunityList.length <= 1}
          getOptionLabel={(option: any) => option.communityName}
          PopperComponent={({ children, ...props }) => {
            return (
              <Popper
                {...props}
                placement="bottom-start"
                id="breadcrumbs-popper-mobile"
                style={{
                  width: 300,
                  alignSelf: 'flex-start',
                  borderRadius: 16,
                  paddingTop: -50,
                  marginTop: 50,
                  maxHeight: 400, // Increase this to display more options
                  overflowY: 'visible', // Enables scrolling if needed
                  boxShadow: '0px 0px 8px 0px rgba(80, 85, 88, 0.22)',
                }}
              >
                {children}
              </Popper>
            );
          }}
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                variant="standard"
                inputRef={inputRef} // Attach ref to input
                onChange={(ev: any) => setSearchTerm(ev.target.value)}
                placeholder={selectedOption?.communityName || ''}
                style={{
                  width:
                    (searchTerm?.length ||
                      (
                        (metadata?.selectedEntity as any)?.communityName ||
                        (metadata?.selectedEntity as any)?.communityName
                      )?.length) * 10 || 0,
                  minWidth: '250px',
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  style: {
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '20px', // Reserve space for the icon
                    fontWeight: 'bold',
                    color: '#009E62',
                    maxWidth:
                      mobileCommunityList.length <= 1 ? '230px' : '230px',
                  },
                  endAdornment: mobileCommunityList.length > 1 && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 0, // Always keep the icon at the end
                        pointerEvents: 'none',
                        display:
                          mobileCommunityList.length < 1 ? 'none' : 'flex',
                        alignItems: 'center',
                        // transition: 'all 0.2s ease-in-out',
                        left: inputFocused ? 'auto' : textWidth,
                      }}
                    >
                      <button
                        style={{ border: 'none', background: 'none' }}
                        onClick={() => {
                          if (inputFocused) {
                            setSearchTerm('');
                            setInputFocused(false);
                          } else return;
                        }}
                      >
                        <IconWrapper
                          iconName={inputFocused ? '' : 'chevron-down'}
                          style={{
                            fontSize: '16px',
                            color: 'green',
                            marginLeft: inputFocused ? 'auto' : '0', // Prevent shifting
                          }}
                          className={styles.BreadCrumb__Icon__Mobile}
                        />
                      </button>
                    </div>
                  ),
                }}
                sx={{
                  width: 'fit-content', // Dynamically adjusts to content length
                  minWidth: '150px',
                  position: 'relative',
                }}
                // className={`heading1 ${styles.BreadCrumb__Input__Mobile}`}

                className={`heading1 $ ${
                  mobileCommunityList.length <= 1 &&
                  styles.BreadCrumb__Input__SingleValue
                } `}
                // placeholder={
                //   selectedOption?.communityName ||
                //   (metadata?.selectedEntity as any)?.communityName ||
                //   (metadata.entities as any)?.sites?.[0]?.communityName ||
                //   'sites'
                // }
                focused={inputFocused}
                onFocus={() => (setInputFocused(true), setSearchTerm(''))}
                onBlur={() => setInputFocused(false)}
              ></TextField>
              <canvas ref={canvasRef} style={{ display: 'none' }} />
            </>
          )}
          renderOption={(props, option) => (
            <ListItem
              {...props}
              className={`heading2 ${
                option?.communityId ===
                (metadata?.selectedEntity as any)?.communityId
                  ? styles.Autocomplete__List__Item__Active__Mobile
                  : styles.Autocomplete__List__Item__Mobile
              }`}
              style={{
                color:
                  option?.communityId ===
                  (metadata?.selectedEntity as any)?.communityId
                    ? '#009E62'
                    : undefined,
                textTransform: 'capitalize',
              }}
              value={option.communityName}
              onClick={() => handleOptionClick(option)} // Call handleOptionClick
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{option?.communityName}</span>
                <span className="detail" style={{ color: '#687078' }}>
                  {getSitesAumount(option.communityId)}{' '}
                  {/* {t('widgets.dropdown.sites')} */}
                  sites
                </span>
              </div>
            </ListItem>
          )}
        />
      </div>
      {inputFocused && (
        <button
          style={{
            border: 'none',
            background: 'none',
            justifyContent: 'flex-end',
            justifySelf: 'flex-end',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconWrapper
            iconName="cross"
            style={{
              fontSize: '16px',
              color: 'green',
              marginLeft: 'auto',
              marginTop: '8px',
            }}
            className={styles.BreadCrumb__Icon__Mobile}
            onClick={() => {
              setSearchTerm('');
              setInputFocused(false);
            }}
          />
        </button>
      )}
    </div>
  );
}
