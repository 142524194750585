import { useContext, useEffect } from 'react';

import { AlertTypes, AlertContext } from '@flexo/providers';

import { FirebaseService } from '@flexo/general';
import { CALENDAR, KPI, METADATA, store, USER } from '@flexo/reducers';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { _navigate } from './helpers';
import * as luxon from 'luxon';

const ENV = (import.meta as any).env;

const firebaseService = new FirebaseService();

export const SiteOverviewResolver = () => {
  const { setAlertStore } = useContext(AlertContext);
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const pathSegment = location.pathname.split('/')[1];
  const metadata = useSelector((state: any) => state.metadata);

  async function siteOverviewInit() {
    const idToken = await firebaseService.getIdToken();

    if (!idToken) {
      setAlertStore({
        type: AlertTypes.SetAlert,
        payload: {
          value: {
            type: 'ALERT_SESSION_EXPIRED',
          },
        },
      });

      // setUserStore({
      //   type: UserTypes.SetUserLogOut,
      //   payload: {
      //     value: true,
      //   },
      // });

      dispatch(USER.SetLogout());

      return;
    }

    let _site: any = null;

    if (!params.id && (metadata?.entities as any)?.site?.length > 0) {
      _site = (metadata?.entities as any)?.site?.[0];
    } else if (params.id && (metadata?.entities as any)?.site?.length > 0) {
      _site = (metadata?.entities as any)?.site?.find(
        (site: any) => site.siteId === params.id
      );
    }

    if (!_site) {
      return;
    }

    dispatch(
      METADATA.SetSelectedEntity({
        selectedEntity: _site,
        id: _site?.siteId,
        entityType: pathSegment,
      } as any)
    );
  }

  useEffect(() => {
    siteOverviewInit();
  }, [params.id, metadata?.entities]);

  return <></>;
};

export function navigateToSite(context, siteID: string) {
  const dispatch = store.dispatch;
  const queryParams = window?.location?.search || '';
  const location = window.location;
  const pathSegment = location.pathname.split('/')[2];

  dispatch(METADATA.SetPrevSelectedEntityId(pathSegment as any));

  dispatch(KPI.ResetKpiFields());
  

  if (ENV.VITE_APP_NAME === 'admin' && context === 'site') {
    // const D = luxon.DateTime.now().startOf('day').toJSDate();
    // const D2 = luxon.DateTime.now().plus({ days: 1 }).startOf('day').toJSDate();
    // dispatch(
    //   CALENDAR.setHiveDate({
    //     date: [D, D2],
    //     timeSet: 'day',
    //     setQueryParams: false,
    //   } as any)
    // );
  }

  _navigate(`/${context}/${siteID}${queryParams}`);
  // window.location.href = (`/${context}/${memberID}`);
}
